@mixin h {
  @media only screen and (max-width: "1920px") {
    @content; } }
@mixin w {
  @media only screen and (max-width: "1599px") {
    @content; } }
@mixin x {
  @media only screen and (max-width: "1439px") {
    @content; } }
@mixin mac {
  @media only screen and (max-width: "1365px") {
    @content; } }
@mixin d {
  @media only screen and (max-width: "1279px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }
@mixin a {
  @media only screen and (max-width: "639px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "474px") {
    @content; } }

@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-Regular.woff') format('woff'), url('fonts/fonts/SFProDisplay-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-Semibold.woff') format('woff'), url('fonts/fonts/SFProDisplay-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'SF Pro Display';
  src: url('fonts/SFProDisplay-Bold.woff') format('woff'), url('fonts/fonts/SFProDisplay-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal; }

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@mixin f {
  font-family: 'Poppins', sans-serif; }


// $bl: #000000
$bl:  #00113B;
$dark: #232340;
$wh: #ffffff;
$primary: #F50245;
$primraysecond: #FF6D20;
$secondary: #4C40F7;
$text-dark: #8B8B8B;
$text-light: #606060;
$pink: #FFC7DF;
$gray-color: #9F9FA9;
$gray-text: #6b6b6b;
$purple: #4C40F7;

html {
  // height: 100%
  &.no-scroll {
    overflow: hidden; } }

body {
  min-width: 375px;
  font-family: 'SF Pro Display', sans-serif;
  font-size: 18px;
  line-height: 1.35;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark;
  @include d {
    font-size: 16px; }
  &.no-scroll {
    overflow: hidden; } }

button,
input,
textarea,
select {
  font-family: 'SF Pro Display', sans-serif; }

.page {
  overflow: hidden; }

.center {
  max-width: 1384px;
  margin: 0 auto;
  padding: 0 107px;
  @include d {
    padding: 0 100px; }
  @include t {
    padding: 0 50px; }
  @include m {
    padding: 0 32px; } }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600; }

.h1 {
  font-size: 72px;
  line-height: (84/72);
  letter-spacing: -.5px;
  @include d {
    font-size: 56px;
    line-height: (72/56);
    letter-spacing: -.4px; }
  @include m {
    font-size: 42px;
    line-height: (56/42);
    letter-spacing: -.1px; } }

.h2 {
  font-size: 56px;
  line-height: (72/56);
  letter-spacing: -.4px; }

.h3 {
  font-size: 42px;
  line-height: (56/42);
  letter-spacing: -.1px;
  @include d {
    font-size: 32px;
    line-height: (48/32);
    letter-spacing: -.15px; }
  @include m {
    font-size: 20px;
    line-height: (28/20); } }

.h4 {
  font-size: 32px;
  line-height: (48/32);
  letter-spacing: -.15px;
  @include d {
    font-size: 24px;
    line-height: (32/24); } }

.h5 {
  font-size: 24px;
  line-height: (32/24);
  @include d {
    font-size: 20px;
    line-height: (28/20); } }

.h6 {
  font-size: 20px;
  line-height: (28/20); }


.btn {
  position: relative;
  min-width: 180px;
  height: 64px;
  border-radius: 8px;
  font-family: 'SF Pro Display', sans-serif;
  border-width: 2px;
  border-style: solid;
  overflow: hidden;
  font-size: 18px;
  font-weight: 700;
  transition: all .35s;
  @include d {
    height: 56px;
    font-size: 16px; }
  @include m {
    min-width: 160px; }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 300%;
    border-radius: 50% 50% 0 0/75% 75% 0 0;
    transition: height .35s; }
  &:hover:before {
    height: 0; }
  &_dark {
    border-color: $dark;
    color: $wh;
    &:before {
      background: $dark; }
    &:hover {
      color: $dark; } }
  &_primary {
    border-color: $primary;
    color: $wh;
    &:before {
      background: $primary; }
    &:hover {
      color: $primary; } }
  &_white {
    border-color: $wh;
    color: $primary;
    &:before {
      background: $wh; }
    &:hover {
      color: $wh; } }
  [href] {
    display: inline-flex;
    justify-content: center;
    align-items: center; } }

// .header
//   position: absolute
//   top: 0
//   left: 0
//   right: 0
//   z-index: 10px
//   padding: 64px 0
//   +d
//     padding: 40px 0
//   &__center
//     display: flex
//     align-items: center
//     width: 100%
//     max-width: 100%
//     padding: 0 72px
//     +d
//       padding: 0 50px
//     +t
//       padding: 0 30px
//   &__logo
//     font-size: 0
//     +m
//       position: relative
//       z-index: 15
//       margin-right: auto
//       &.toggle
//         .header__pic_black
//           display: none
//         .header__pic_white
//           display: inline-block
//   &__pic
//     width: 159px
//     +d
//       width: 127px
//     &_white
//       display: none
//   &__wrap
//     display: flex
//     flex-grow: 1
//     padding-left: 20px
//     +t
//       padding: 0
//     +m
//       position: absolute
//       top: 0
//       left: 0
//       right: 0
//       z-index: 10
//       flex-direction: column
//       align-items: flex-start
//       min-height: 100vh
//       padding: 150px 32px 40px
//       background: $dark
//       transform: translateX(-100%)
//       transition: transform .25s
//       &.visible
//         transform: translateX(0)
//         .line
//           path
//             animation: dash 1.5s linear forwards
//   &__nav
//     display: flex
//     margin: 0 auto
//     +m
//       flex-direction: column
//       align-items: flex-start
//       margin: 0 0 auto
//   &__item
//     position: relative
//     font-weight: 600
//     color: $text-dark
//     transition: color .25s
//     +m
//       font-size: 32px
//       color: #595976
//     &:hover
//       color: $text-light
//       +m
//         color: #595976
//     &.active
//       color: $primary
//       +m
//         color: $wh
//     +nl
//       margin-right: 68px
//       +d
//         margin-right: 54px
//       +t
//         margin-right: 40px
//       +m
//         margin: 0 0 32px
//       +a
//         margin-bottom: 40px
//   &__counter
//     position: absolute
//     left: calc(100% + 7px)
//     bottom: calc(100% - 11px)
//     min-width: 24px
//     padding: 0 5px
//     border-radius: 50%
//     background: linear-gradient(180deg, #3A8FFF 0%, #0837AE 100%)
//     text-align: center
//     font-size: 14px
//     line-height: 24px
//     font-weight: 600
//     color: $wh
//     +m
//       bottom: calc(100% - 17px)
//   &__btn
//     +t
//       min-width: 130px
//       height: 46px
//     +m
//       display: none
//   &__burger
//     position: relative
//     z-index: 15
//     display: none
//     width: 32px
//     height: 32px
//     text-align: center
//     transition: background .3s
//     -webkit-tap-highlight-color: rgba(0,0,0,0)
//     +m
//       display: inline-block
//     &:before,
//     &:after
//       content: ''
//       display: block
//       width: 32px
//       height: 2px
//       background: $dark
//       transition: transform .3s, width .3s, background .3s
//     &:before
//       margin-bottom: 8px
//     &.active
//       &:before,
//       &:after
//         background: $wh
//       &:before
//         transform: translateY(5px) rotate(45deg)
//       &:after
//         transform: translateY(-5px) rotate(-45deg)
//   &__social
//     display: none
//     +m
//       display: flex
//       margin-top: 60px
//   &__link
//     display: flex
//     justify-content: center
//     align-items: center
//     flex-shrink: 0
//     width: 40px
//     height: 40px
//     border-radius: 50%
//     border: 1px solid rgba(#D8D8D8, .2)
//     font-size: 0
//     .icon
//       font-size: 12px
//       &-youtube
//         font-size: 10px
//         fill: #ADADAD
//     +nl
//       margin-right: 32px
//   &__bg
//     position: fixed
//     top: 0
//     left: 0
//     right: 0
//     bottom: 0
//     z-index: 5
//     background: rgba(#000, .7)
//     visibility: hidden
//     opacity: 0
//     transition: all .25s
//     &.show
//       +m
//         visibility: visible
//         opacity: 1
//   .line
//     display: none
//     +m
//       display: block
//       top: -152px
//       left: 0

.title {
  font-size: 84px;
  font-weight: 700;
  line-height: (100/84);
  letter-spacing: -1px;
  @include d {
    font-size: 64px;
    line-height: (72/64);
    letter-spacing: -.5px; }
  @include m {
    font-size: 48px;
    line-height: (56/48); }
  &__color {
    color: $primary; }
  &_big {
    font-size: 110px;
    line-height: (131/110);
    letter-spacing: -1.2px;
    @include d {
      font-size: 76px;
      line-height: (92/76);
      letter-spacing: -.5px; } } }

.main {
  position: relative;
  padding: 238px 0 120px;
  @include d {
    padding: 169px 0 115px; }
  @include m {
    padding: 118px 0 310px; }
  &__wrap {
    max-width: 470px;
    @include m {
      max-width: 100%; } }
  &__title {
    margin-bottom: 40px;
    @include d {
      margin-bottom: 24px; }
    p {
      display: inline-flex;
      overflow: hidden;
      span {
        display: block;
        @include nl {
          margin-right: 18px; } } }
    &[data-aos] {
      p {
        span {
          transform: translateY(105%);
          transition: transform .8s; } }
      &.aos-animate {
        p {
          span {
            transform: translateY(0); } }
        p:first-child {
          span {
            &:first-child {
              transition-delay: .2s; }
            &:nth-child(2) {
              transition-delay: .4s; } } }
        p:nth-child(2) {
          span {
            &:first-child {
              transition-delay: .6s; }
            &:nth-child(2) {
              transition-delay: .8s; } } }
        p:nth-child(3) {
          span {
            &:first-child {
              transition-delay: 1s; } } } } } }
  &__info {
    max-width: 370px;
    margin-bottom: 48px;
    line-height: (32/18);
    color: $text-light;
    @include d {
      max-width: 265px;
      margin-bottom: 32px; }
    @include m {
      max-width: 100%;
      margin-bottom: 24px; } }
  &__btn {
    margin-bottom: 85px;
    @include d {
      margin-bottom: 56px; }
    @include t {
      margin-bottom: 32px; } }
  &__scroll {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    color: $text-dark;
    @include m {
      display: none; } }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    border-radius: 50%;
    background: $secondary;
    overflow: hidden;
    font-size: 0;
    @include d {
      width: 40px;
      height: 40px; } }
  &__arrows {
    display: flex;
    flex-direction: column;
    transform: translateY(-43px);
    transition: transform .35s; }
  &__arrows &__pic {
    @include nl {
      margin-bottom: 64px; } }
  &__scroll:hover &__arrows {
    animation: arrowsDown .5s forwards; }
  &__bg {
    position: absolute;
    top: 0;
    right: calc(50% - 611px);
    z-index: -1;
    width: 40%;
    font-size: 0;
    @include d {
      top: 210px;
      right: calc(50% - 453px);
      width: 700px; }
    @include t {
      top: 230px;
      width: 600px; }
    @include m {
      top: auto;
      right: calc(50% - 203px);
      bottom: 56px;
      width: 400px; }
    &[data-aos] {
      .main__preview {
        opacity: 0;
        transition: all .6s;
        &:nth-child(1) {
          transform: translateY(60px); }
        &:nth-child(2) {
          transform: translateY(-40px) scaleY(1.3);
          transition-delay: .2s; }
        &:nth-child(3) {
          transform: translateY(60px);
          transition-delay: .4s; }
        &:nth-child(4) {
          transform: translateY(60px);
          transition-delay: .6s; }
        &:nth-child(5) {
          transform: translateX(50px);
          transition-delay: .8s; }
        &:nth-child(6) {
          transform: translateX(50px) skewX(10deg);
          transition-delay: 1s; } }
      &.aos-animate {
        .main__preview {
          opacity: 1;
          &:first-child {
            transform: translateY(0); }
          &:nth-child(2) {
            transform: translateY(0) scaleY(1); }
          &:nth-child(3) {
            transform: translateY(0); }
          &:nth-child(4) {
            transform: translateY(0); }
          &:nth-child(5) {
            transform: translateX(0); }
          &:nth-child(6) {
            transform: translateX(0) skewX(0); } } } } }
  &__bg &__pic {
    width: 100%; }
  &_bg {
    @include m {
      display: flex;
      background: $dark; } }
  &__preview {
    &:first-child {
      position: relative;
      top: 200px;
      z-index: 3;
      @include m {
        left: 8%;
        top: 50px;
        max-width: 80%; }
      @include t {
        top: 50px;
        max-width: 80%; } }
    &:nth-child(2) {
      top: 0%;
      right: -30%;
      z-index: -4;
      width: 150%;
      @include m {
        display: none; }
      @include t {
        top: -50%; }
      @include h {
        right: -50%; } }
    &:nth-child(3) {
      top: 35.7%;
      right: 9.6%;
      z-index: 5;
      width: 23.5%; }
    &:nth-child(4) {
      top: 50.5%;
      left: 12.8%;
      z-index: 5;
      width: 40.7%; }
    &:nth-child(5) {
      top: -9.4%;
      right: 2.8%;
      z-index: 1;
      width: 60.7%;
      @include m {
        right: -2%; } }
    &:nth-child(6) {
      top: 5.5%;
      right: 12%;
      z-index: 2;
      width: 27.5%;
      @include m {
        top: 10%;
        right: 8%; } }
    @include nf {
      position: absolute; } }
  & > .line {
    top: 0;
    left: calc(50% - 395px);
    @include d {
      top: -110px;
      left: calc(50% - 365px); }
    @include t {
      left: 100px; }
    @include m {
      top: -160px;
      left: 75px; }
    path {
      animation: dash 2.5s .8s linear forwards; } } }

.line {
  position: absolute;
  z-index: -5;
  pointer-events: none;
  font-size: 0;
  @include d {
    transform: scale(.75); }
  @include m {
    transform: scale(.63); }
  path {
    stroke-dasharray: 2173;
    stroke-dashoffset: 2173;
    @include d {
      width: 456px; } } }

[data-aos="animation-line"] {
  path {
    animation: none; }
  &.aos-animate {
    path {
      animation: dash 3s linear forwards; } } }

@keyframes dash {
  to {
    stroke-dashoffset: 0; } }

@keyframes arrowsDown {
  100% {
    transform: translateY(43px); } }

.bg {
  @include coverdiv;
  z-index: -5;
  background: $dark;
  &__preview {
    position: absolute;
    top: -40px;
    right: -100px;
    font-size: 0;
    @include m {
      display: none; } }
  &__preview &__pic {
    @include d {
      width: 570px; }
    @include t {
      width: 470px; } } }

.stage {
  font-weight: 600;
  color: $primary;
  @include m {
    font-size: 14px; }
  &__line {
    display: inline-block; }
  &__number {
    display: inline-block;
    width: 45px; }
  &__logo {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0;
    @include t {
      display: none; } }
  &__logo &__pic {
    width: 24px; } }

.stagenew {
  position: relative;
  margin-bottom: 24px;
  padding-left: 68px;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
  color: $primary;
  @include d {
    margin-bottom: 16px;
    font-size: 16px; }
  &:before {
    content: "";
    position: absolute;
    top: 15px;
    left: 0;
    width: 52px;
    height: 2px;
    background: $primary;
    @include d {
      top: 10px; } }
  &_pink {
    color: $pink;
    &:before {
      background: $pink; } } }


.number {
  position: relative;
  display: inline-block;
  font-size: 200px;
  font-weight: 600;
  line-height: 1.2;
  @include d {
    font-size: 140px; }
  @include t {
    font-size: 110px; }
  @include m {
    font-size: 140px; }
  &__plus {
    position: absolute;
    top: 25px;
    left: 100%;
    font-size: 64px;
    line-height: (72/64);
    @include d {
      top: 17px;
      font-size: 46px; } }
  &[data-aos] {
    span {
      display: inline-block;
      opacity: 0;
      transition: all .7s;
      &:first-child {
        transform: translateY(70px); }
      &:nth-child(2) {
        transform: translateY(-70px); } }
    &.aos-animate {
      span {
        opacity: 1;
        transform: translateY(0); } } } }

.arrows {
  display: flex;
  flex-shrink: 0;
  &__prev,
  &__next {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 50%;
    font-size: 0;
    transition: all .25s;
    @include d {
      width: 48px;
      height: 48px; }
    .icon {
      font-size: 16px;
      fill: $dark;
      transition: fill .25s; }
    &:hover {
      background: $primary;
      .icon {
        fill: $wh; } }
    &[href] {
      display: flex;
      justify-content: center;
      align-items: center; }
    @include nl {
      margin-right: 16px; } }
  &_white &__prev,
  &_white &__next {
    .icon {
      fill: $wh; } } }

.head {
  position: relative;
  display: flex;
  padding-left: 150px;
  @include d {
    padding-left: 140px; }
  @include t {
    padding: 0; }
  @include m {
    display: block; }
  &__box {
    flex-grow: 1; }
  &__stage {
    margin-bottom: 24px; }
  &__title {
    @include s {
      max-width: 240px; } }
  &__arrows {
    margin-left: 150px;
    padding-top: 48px;
    @include d {
      margin-left: 95px; }
    @include m {
      margin: 0;
      padding-top: 32px; } }
  &__text {
    max-width: 467px;
    margin-top: 40px;
    line-height: (32/18);
    color: $text-light;
    @include d {
      max-width: 330px; }
    @include m {
      max-width: 100%;
      margin-top: 16px; } }
  &__btns {
    margin-left: 100px;
    padding-top: 50px;
    flex-shrink: 0;
    @include d {
      padding-top: 63px;
      margin-left: 70px; }
    @include m {
      display: none; } }
  &__btn {
    min-width: 166px;
    @include d {
      min-width: 120px;
      height: 45px; } }
  &__select {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 144px;
    height: 48px;
    padding: 0 60px 0 24px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none'%3E%3Cpath fill-rule='evenodd' d='M.293.293a1 1 0 0 1 1.414 0L5 3.586 8.293.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z' fill='%23fff'/%3E%3C/svg%3E") no-repeat calc(100% - 18px) 50% / 10px 6px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: $wh;
    &::-ms-expand {
      display: none; }
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000; }
    @include m {
      display: block; } }

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 10;
    background: $wh;
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
    @include m {
      display: block; }
    @include a {
      visibility: visible;
      opacity: 1;
      transform: translateX(100%);
      background: $wh url('../img/bg-header-mobile.png') no-repeat 100% 150% / 100% auto; }
    &.visible {
      visibility: visible;
      opacity: 1;
      @include a {
        transform: translateX(0); } } } }

.details {
  position: relative;
  padding: 128px 0 129px;
  color: $wh;
  @include t {
    padding: 100px 0; }
  @include m {
    padding: 64px 0; }
  &__head {
    margin-bottom: 93px;
    @include d {
      margin-bottom: 70px; }
    @include m {
      margin-bottom: 56px; } }
  .stage {
    color: $wh; }
  .stage__number {
    color: $text-dark; }
  &__body {
    display: flex;
    margin-bottom: 128px;
    @include mac {
      align-items: center; }
    @include d {
      margin-bottom: 85px; }
    @include m {
      display: block;
      margin-bottom: 56px; } }
  &__years {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    width: 200px;
    padding-top: 35px;
    @include d {
      width: 140px;
      padding: 0; }
    @include t {
      width: 80px; }
    @include m {
      display: none; } }
  &__year {
    position: relative;
    width: 45px;
    font-weight: 600;
    color: $text-dark;
    cursor: pointer;
    transition: color .25s;
    @include d {
      font-size: 14px; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: calc(100% + 62px);
      transform: translateY(-50%);
      width: 0;
      height: 4px;
      background: $wh;
      border-radius: 1px;
      transition: width .25s;
      pointer-events: none;
      @include d {
        right: calc(100% + 45px);
        height: 3px; }
      @include t {
        right: calc(100% + 15px); } }
    &.active {
      color: $wh;
      &:before {
        width: 24px;
        @include d {
          width: 17px; } } }
    @include nl {
      margin-bottom: 102px;
      @include mac {
        margin-bottom: 80px; }
      @include d {
        margin-bottom: 65px; }
      @include t {
        margin-bottom: 50px; } } }
  &__line {
    position: absolute;
    width: 0px;
    height: 1px;
    top: 50%;
    left: calc(100% + 23px);
    transform: translateY(-50%);
    background: $wh;
    opacity: 0;
    transition: all .25s;
    pointer-events: none;
    @include d {
      left: calc(100% + 5px); }
    &:before {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $wh; } }
  &__year.active &__line {
    width: 187px;
    opacity: 1;
    @include d {
      width: 145px; }
    @include t {
      width: 60px; } }
  &__dot {
    position: absolute;
    top: -7px;
    right: -7px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: $wh;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 7px;
      height: 7px;
      background: $wh;
      border-radius: 50%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.30171); } }
  &__container {
    // padding-left: 50px
    // max-width: 200%
    // flex: 0 0 calc(100% - 200px)
    // max-width: calc(100% - 200px)
    // +d
    //   flex: 0 0 calc(100% - 140px)
    //   max-width: calc(100% - 140px)
    // +t
    //   flex: 0 0 calc(100% - 80px)
    //   max-width: calc(100% - 80px)
    // +m
    //   width: 100%
 }    //   max-width: 100%
  &__inner {
    display: flex;
    flex-direction: row;
    margin: 0 -15px;
    @include m {
      margin: 0; } }
  &__slider {
    visibility: hidden;
    &.slick-initialized {
      visibility: visible; } }
  &__preview {
    margin: 10px;
    font-size: 0;
    @include d {
      margin: 0 15px; } }
  &__pic {
    width: 100%; }
  &__foot {
    display: flex;
    align-items: center;
    padding-left: 100px;
    @include d {
      padding-left: 140px; }
    @include t {
      padding-left: 30px; }
    @include m {
      display: block;
      padding: 0; } }
  &__wrap {
    position: relative;
    flex-grow: 1;
    @include m {
      margin-bottom: 56px; }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -50px;
      width: 2px;
      height: 80px;
      border-radius: 1px;
      background: $primary;
      @include d {
        left: -140px;
        height: 56px; }
      @include t {
        left: -30px; }
      @include m {
        display: none; } } }
  &__info {
    margin-bottom: 40px; }
  &__wrap &__text {
    margin-bottom: 40px;
    line-height: (32/18);
    color: $text-dark;
    @include d {
      margin-bottom: 32px; } }
  &__counter {
    flex-shrink: 0;
    margin-left: 100px;
    perspective: 600px;
    @include d {
      margin-left: 80px; }
    @include t {
      margin-left: 60px; }
    @include m {
      margin: 0 auto; }
    &[data-aos] {
      &.aos-animate {
        .details__front {
          transform: rotateX(-180deg);
          z-index: 1; }
        .details__back {
          transform: rotate(0);
          z-index: 2; } } } }
  &__front,
  &__back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 50%;
    overflow: hidden;
    transition: transform .75s;
    transform-style: preserve-3d; }
  &__front {
    position: relative;
    z-index: 2; }
  &__back {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 402px;
    height: 402px;
    transform: rotateX(180deg);
    border-radius: 50%;
    box-shadow: 0px 23px 51px rgba(0, 0, 0, 0.242297);
    @include d {
      width: 283px;
      height: 283px; }
    @include t {
      width: 240px;
      height: 240px; }
    @include m {
      width: 310px;
      height: 310px; } }
  &__number {
    margin-top: -33px; }
  &__category {
    margin-top: -35px;
    @include d {
      margin-top: -20px;
      font-size: 18px;
      line-height: (24/18); }
    @include t {
      margin-top: 0; }
    @include m {
      margin-top: -10px; } }
  .line {
    top: -138px;
    left: 50px;
    transform: rotate(-90deg);
    @include d {
      top: -220px;
      left: -30px;
      transform: rotate(-90deg) scale(.7); }
    @include m {
      display: none; } }
  .slick-slide {
    padding: 0 15px; } }

.wrapperbase {
  position: relative;
  // margin-bottom: 51px
  padding: 152px 0 130px;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.0001) 0%, rgba(237, 229, 240, 0.504289) 100%);
  @include d {
    // margin-bottom: 100px
    padding: 112px 0 71px; }
  @include t {
    // margin-bottom: 40px
    padding-top: 90px; }
  @include m {
    margin: 0;
    padding: 56px 0 56px; }
  .lines {
    @include coverdiv;
    opacity: .3; }
  .line {
    &:first-child {
      top: 0;
      left: calc(50% - 610px);
      @include d {
        top: -103px;
        left: calc(50% - 480px); }
      @include t {
        left: 0; }
      @include m {
        top: -152px; } }
    &:nth-child(2) {
      top: 770px;
      right: 0;
      @include d {
        top: 570px;
        right: -100px; }
      @include t {
        top: 530px; } } } }

.work {
  // margin-bottom: 100px /////////////////////////////////////////
  @include mac {
 }    // margin-bottom: 280px
  @include d {
 }    // margin-bottom: 200px
  @include t {
 }    // margin-bottom: 140px
  @include m {
 }    // margin-bottom: 80px
  &__head {
    margin-bottom: 170px;
    @include d {
      margin-bottom: 92px; }
    @include m {
      margin-bottom: 44px; } }
  &__container {
    position: relative;
    margin-top: -35px;
    background: $wh;
    border-radius: 48px;
    @include d {
      border-radius: 32px; }
    @include m {
      margin-top: 0;
      background: none; }
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      border-radius: 48px;
      @include m {
        display: none; } }
    &:before {
      top: 8px;
      bottom: -8px;
      background: rgba($wh, .5); }
    &:after {
      top: 64px;
      bottom: -64px;
      z-index: -2;
      filter: blur(130.478px);
      background: #EBE6ED;
      @include d {
        top: 46px;
        bottom: -46px;
        border-radius: 32px;
        filter: blur(92px); } } }
  &__slider {
    visibility: hidden;
    &.slick-initialized {
      visibility: visible; } }
  &__slide {
    @include m {
      margin: 12px 0; } }
  &__item {
    position: relative;
    min-height: 480px;
    padding: 140px 30px 60px;
    text-align: center;
    @include d {
      min-height: 340px;
      padding: 100px 20px 40px; }
    @include m {
      display: flex;
      align-items: center;
      min-height: auto;
      padding: 48px;
      background: $wh;
      border-radius: 32px; }
    &:before {
      content: "";
      @include coverdiv;
      background: $wh;
      border-radius: 38px;
      transition: all .25s;
      @include d {
        border-radius: 32px; }
      @include m {
        top: 8px;
        bottom: -8px;
        opacity: .5; } }
    &:hover:before {
      transform: scaleY(1.16);
      background: $secondary;
      @include d {
        transform: scaleY(1.1); } } }
  &__preview {
    position: relative;
    z-index: 2;
    display: inline-block;
    margin-bottom: 35px;
    font-size: 0;
    @include d {
      margin-bottom: 28px; }
    @include m {
      margin: 0 24px 0 0; } }
  &__pic {
    width: 128px;
    @include d {
      width: 92px; } }
  &__category {
    position: relative;
    z-index: 2;
    color: $primary;
    transition: color .25s; }
  &__item:hover &__category {
    color: $wh; }
  .slick-list {
    overflow: visible;
    border-radius: 48px;
    @include d {
      border-radius: 32px; }
    @include m {
      overflow: hidden; } }
  .slick-slide {
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
    &.slick-active {
      visibility: visible;
      opacity: 1; } } }

.achievement {
  &__center {
    display: flex;
    @include m {
      flex-direction: column-reverse; } }
  &__container {
    flex-shrink: 0;
    width: 467px;
    @include d {
      width: 330px; }
    @include m {
      width: 100%;
      max-width: 375px;
      margin: 0 auto; } }
  &__slider {
    visibility: hidden;
    &.slick-initialized {
      visibility: visible; } }
  &__slide {
    text-align: center; }
  &__group {
    position: relative;
    display: inline-block;
    width: 60%;
    font-size: 0; }
  &__preview {
    &:first-child {
      position: relative;
      z-index: 2;
      display: inline-block;
      width: 100%; }
    &:nth-child(2) {
      top: 70px;
      right: -33%;
      left: -33%;
      width: auto; }
    &:nth-child(3) {
      top: -12%;
      right: -30%;
      z-index: 3;
      width: 73%; }
    @include nf {
      position: absolute; } }
  &__pic {
    width: 100%; }
  &__wrap {
    position: relative;
    z-index: 2;
    padding: 35px 0 0 135px;
    @include d {
      padding: 10px 0 0 100px; }
    @include t {
      padding: 0 0 0 40px; }
    @include m {
      margin-bottom: 60px;
      padding: 0; } }
  &__stage {
    position: relative;
    margin-bottom: 40px;
    padding-left: 100px;
    color: $dark;
    @include t {
      margin-bottom: 32px;
      padding: 0; }
    @include m {
      margin-bottom: 24px; }
    .stage__number {
      color: $text-dark; } }
  &__title {
    margin-bottom: 80px;
    @include t {
      margin-bottom: 50px; }
    @include m {
      margin-bottom: 32px; }
    @include s {
      max-width: 230px; } }
  &__number {
    color: $primary; }
  &__info {
    span {
      margin-right: 20px; } }
  .slick-list {
    overflow: visible; }
  .slick-slide {
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
    &.slick-active {
      visibility: visible;
      opacity: 1; } }
  .slick-dots {
    margin-top: 87px;
    @include d {
      margin-top: 55px; }
    @include t {
      margin-top: 24px; } } }

.slick-prev,
.slick-next {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: $primary;
  font-size: 0;
  transition: opacity .25s;
  @include d {
    width: 48px;
    height: 48px; }
  .icon {
    font-size: 16px;
    fill: $wh;
    transition: fill .25s; }
  &:hover {
    opacity: .85; } }

.slick-dots {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  li {
    button {
      position: relative;
      width: 24px;
      height: 24px;
      font-size: 0;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        background: $wh;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.30171);
        transition: all .25s; } }
    &.slick-active {
      button:before {
        box-shadow: none;
        background: linear-gradient(180deg, #3A8FFF 0%, #0837AE 100%); } }
    @include nl {
      margin-right: 24px;
      @include d {
        margin-right: 18px; }
      @include m {
        margin-right: 12px; } } } }

.arrow {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid $dark;
  font-size: 0;
  transition: all .25s;
  @include d {
    width: 48px;
    height: 48px; }
  &__line {
    display: flex;
    transform: translateX(-43px);
    transition: transform .35s;
    .icon {
      font-size: 16px;
      fill: $dark;
      transition: fill .25s;
      @include nl {
        margin-right: 64px; } } }
  &:hover &__line {
    animation: arrowsRight .5s forwards; }
  // &:hover
  //   background: $primary
  //   border-color: $primary
  //   .icon
 }  //     fill: $wh

.category {
  display: inline-block;
  margin-bottom: 24px;
  padding: 0 23px;
  border-radius: 20px;
  background: #908CFF;
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
  color: $wh;
  @include d {
    border-radius: 16px;
    line-height: 32px; }
  @include m {
    padding: 0 16px; } }

.projects {
  position: relative;
  margin-bottom: 128px;
  background: url('../img/bg-projects.png') no-repeat 100% 192px / 670px auto;
  @include d {
    margin-bottom: 91px;
    background-position: 110% 140px;
    background-size: 500px auto; }
  @include t {
    background-position: 120% 80px; }
  @include m {
    margin-bottom: 74px;
    background: none; }
  &__center {
    @include m {
      max-width: 414px; } }
  &__head {
    margin-bottom: 180px;
    @include d {
      margin-bottom: 70px; }
    @include m {
      margin-bottom: 56px; }
    .head__title {
      @include t {
        font-size: 60px; }
      @include m {
        font-size: 40px;
        line-height: 1.2; } } }
  &__body {
    display: flex;
    margin-bottom: 164px;
    @include d {
      margin-bottom: 116px; }
    @include t {
      margin-bottom: 80px; }
    @include m {
      position: relative;
      flex-direction: column-reverse;
      margin-bottom: 32px; } }
  &__desc {
    position: relative;
    z-index: 2;
    flex-shrink: 0;
    width: 300px;
    padding-top: 48px;
    @include d {
      width: 225px;
      padding-right: 10px; }
    @include m {
      position: static;
      width: 100%;
      padding: 0; } }
  &__category {
    margin-bottom: 24px;
    @include m {
      position: absolute;
      top: 295px;
      left: 25px;
      z-index: 2;
      margin: 0; } }
  &__pic {
    width: 100%; }
  &__body &__preview {
    flex-shrink: 0;
    width: 668px;
    height: 520px;
    background: #D1ECFD;
    @include d {
      width: 470px;
      height: 370px; }
    @include t {
      width: 430px; }
    @include m {
      width: 100%;
      height: 356px;
      margin-bottom: 24px;
      background: #FFCE79; } }
  &__body &__img {
    top: 12%;
    left: 10.3%;
    width: 83%;
    @include m {
      left: 0;
      right: 0;
      width: 100%; } }
  &__preview {
    position: relative;
    border-radius: 24px;
    font-size: 0;
    @include d {
      border-radius: 16px; } }
  &__body &__info {
    margin-right: -167px;
    margin-bottom: 32px;
    @include d {
      margin-bottom: 24px; }
    @include m {
      margin: 0 0 16px; } }
  &__text {
    line-height: (32/18);
    color: $text-light; }
  &__body &__text {
    max-width: 205px;
    @include m {
      max-width: 100%; } }
  &__arrow {
    position: absolute;
    right: -32px;
    @include d {
      right: -24px; }
    @include m {
      position: static;
      margin-top: 24px; } }
  &__view {
    position: absolute;
    left: 32px;
    bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: $dark;
    font-size: 18px;
    font-weight: 700;
    color: $wh;
    transition: all .25s;
    @include d {
      display: none; }
    &:hover {
      background: $primary; } }
  &__body &__arrow {
    top: 142px;
    @include d {
      top: 100px; } }
  &__desc &__arrow {
    display: none;
    @include m {
      display: inline-flex; } }
  &__preview &__arrow {
    @include m {
      display: none; } }
  &__item {
    @include m {
      display: flex;
      flex-direction: column; }
    &:first-child {
      max-width: 366px;
      margin-left: auto;
      @include d {
        max-width: 258px; }
      @include m {
        max-width: 100%; }
      .projects__preview {
        height: 382px;
        background: #E8BEFF;
        @include d {
          height: 270px; }
        @include m {
          height: 355px; } }
      .projects__img {
        top: 9%;
        right: 27%;
        width: 128.3%;
        @include t {
          right: 5%; }
        @include m {
          top: 23%;
          right: 5%;
          width: 90%; } }
      .projects__info,
      .projects__text {
        max-width: 366px;
        margin-left: auto;
        @include m {
          max-width: 100%;
          margin: 0; } }
      .projects__info {
        @include m {
          margin-bottom: 16px; } } }
    &:nth-child(2) {
      max-width: 470px;
      margin-top: -310px;
      @include d {
        max-width: 328px;
        margin-top: -290px; }
      @include t {
        max-width: 300px; }
      @include m {
        max-width: 100%;
        margin: 32px 0 0; }
      .projects__preview {
        height: 500px;
        background: #FFCE79;
        @include d {
          height: 356px; } }
      .projects__img {
        top: 15%;
        left: 12%;
        width: 71%;
        @include m {
          top: 18%; } } }
    &:nth-child(3) {
      max-width: 467px;
      margin: 0 100px 0 auto;
      @include mac {
        margin-right: 50px; }
      @include d {
        max-width: 328px; }
      @include t {
        margin-right: 0; }
      @include m {
        margin: 32px 0 0; }
      .projects__preview {
        height: 506px;
        background: #D1ECFD;
        @include d {
          height: 356px; } }
      .projects__img {
        top: 11%;
        left: 13%;
        width: 72.8%; } }
    &:nth-child(4) {
      max-width: 366px;
      margin: -366px auto 0 100px;
      @include mac {
        margin-left: 50px; }
      @include d {
        max-width: 260px;
        margin-top: -310px; }
      @include t {
        margin-left: 0; }
      @include m {
        max-width: 100%;
        margin: 32px 0 0; }
      .projects__preview {
        height: 506px;
        background: #FFADD0;
        @include d {
          height: 356px; }
        @include m {
          background: #FFCE79; } }
      .projects__img {
        top: 19%;
        left: 0;
        right: 0;
        width: 100%;
        @include m {
          top: 11%; } } } }
  &__img {
    position: absolute;
    pointer-events: none; }
  &__item &__info {
    margin-bottom: 40px;
    @include m {
      margin-bottom: 16px; } }
  &__item &__preview {
    margin-bottom: 40px;
    @include m {
      order: -1;
      margin-bottom: 24px; } }
  &__item &__text {
    max-width: 366px; }
  &__item &__arrow {
    bottom: 64px;
    @include d {
      bottom: 60px; } }
  &__item > &__arrow {
    display: none;
    @include m {
      display: inline-flex; } }
  &__btn {
    display: none;
    @include m {
      display: block;
      margin-top: 48px;
      .btn {
        min-width: 100%; } } }
  .lines {
    @include coverdiv;
    z-index: -1;
    opacity: .3;
    @include m {
      display: none; } }
  .line {
    &:first-child {
      top: 600px;
      left: -50px;
      transform: rotate(-90deg);
      @include d {
        top: 410px;
        transform: rotate(-90deg) scale(.7); } }
    &:nth-child(2) {
      top: 1650px;
      right: 120px;
      transform: rotate(-45deg);
      @include d {
        top: 970px;
        right: -100px;
        transform: rotate(-90deg) scale(.7); } } } }

.talent {
  margin-bottom: 158px;
  @include d {
    margin-bottom: 90px; }
  @include m {
    margin-bottom: 70px; }
  &__container {
    position: relative;
    padding: 134px 0;
    border-radius: 40px;
    background: $primary;
    color: $wh;
    @include d {
      padding: 80px 0;
      border-radius: 28px; }
    @include m {
      padding: 64px 40px 450px; }
    &:before {
      content: "";
      position: absolute;
      top: 48px;
      left: -63px;
      bottom: 48px;
      z-index: -1;
      width: 160px;
      border-radius: 40px;
      background: #D1ECFD;
      @include d {
        left: -55px;
        border-radius: 28px; }
      @include t {
        left: -25px; }
      @include m {
        display: none; } } }
  &__figure {
    position: absolute;
    top: 48px;
    right: -64px;
    width: 490px;
    font-size: 0;
    @include d {
      top: 24px;
      width: 358px; }
    @include t {
      top: 60px;
      right: -30px;
      width: 300px; }
    @include m {
      top: auto;
      bottom: -30px;
      right: -32px;
      width: 270px; } }
  &__wrap {
    position: relative;
    z-index: 3;
    max-width: 367px;
    margin-left: 602px;
    @include d {
      max-width: 260px;
      margin-left: 52%; }
    @include m {
      max-width: 100%;
      margin-left: 0; } }
  &__stage {
    margin-bottom: 24px;
    color: $wh; }
  &__title {
    margin-bottom: 52px;
    @include d {
      margin-bottom: 32px; } }
  &__btn {
    min-width: 201px;
    @include d {
      min-width: 143px;
      height: 46px; } }
  &__bg {
    position: absolute;
    top: 16%;
    left: calc(50% - 415px);
    width: 720px;
    pointer-events: none;
    font-size: 0;
    @include d {
      left: calc(50% - 305px);
      width: 500px; }
    @include m {
      top: auto;
      left: 12%;
      bottom: 40px;
      z-index: 2;
      width: 450px; }
    &[data-aos] {
      .talent__preview {
        opacity: 0;
        transition: all .7s;
        &:first-child {
          transform: translateY(60px); }
        &:nth-child(2) {
          transform: scale(.7);
          transition-delay: .2s; }
        &:nth-child(3) {
          transform: translate(40px,-40px) rotate(15deg);
          transition-delay: .4s; }
        &:nth-child(4) {
          transform: translate(-40px,60px);
          transition-delay: .6s; }
        &:nth-child(5) {
          transform: translateX(-50px) scale(1.4);
          transition-delay: .8s; } }
      &.aos-animate {
        .talent__preview {
          opacity: 1;
          &:first-child {
            transform: translateY(0); }
          &:nth-child(2) {
            transform: scale(1); }
          &:nth-child(3) {
            transform: translate(0,0) rotate(0); }
          &:nth-child(4) {
            transform: translate(0,0); }
          &:nth-child(5) {
            transform: translateX(0) scale(1); } } } } }
  &__preview {
    &:nth-child(2) {
      top: 18.3%;
      left: 13%;
      width: 37.5%; }
    &:nth-child(3) {
      top: -14%;
      left: 14.5%;
      width: 53%; }
    &:nth-child(4) {
      left: -16%;
      bottom: -7.5%;
      width: 44%; }
    &:nth-child(5) {
      top: 7%;
      left: -10%;
      width: 40px; }
    @include nf {
      position: absolute; } }
  &__pic {
    width: 100%; }
  &_case {
    margin-bottom: 129px;
    @include m {
      position: relative;
      z-index: 2;
      margin-bottom: 95px; } } }

.jobs {
  position: relative;
  padding: 128px 0;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.0001) 0%, rgba(237, 229, 240, 0.504289) 100%);
  @include d {
    padding: 86px 0 82px; }
  @include m {
    padding: 56px 0 48px; }
  &__center {
    display: flex;
    @include m {
      display: block; } }
  &__wrap {
    position: relative;
    padding: 0 60px 0 100px;
    flex-grow: 1;
    @include t {
      padding: 0 50px 0 0; }
    @include m {
      margin-bottom: 48px;
      padding: 0; } }
  &__stage {
    margin-bottom: 24px; }
  &__title {
    margin-bottom: 41px;
    @include m {
      margin-bottom: 24px; } }
  &__list {
    flex-shrink: 0;
    width: 568px;
    overflow: hidden;
    @include d {
      width: 400px; }
    @include m {
      width: 100%;
      overflow: visible; } }
  &__info {
    max-width: 367px;
    margin-bottom: 72px;
    line-height: (32/18);
    color: $text-light;
    @include m {
      max-width: 100%;
      margin: 0; } }
  &__scroll {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    line-height: (24/18);
    color: $text-dark;
    @include m {
      display: none; } }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    border-radius: 50%;
    background: $secondary;
    overflow: hidden;
    font-size: 0;
    @include d {
      width: 40px;
      height: 40px; } }
  &__arrows {
    display: flex;
    transform: translateX(-43px);
    transition: transform .35s; }
  &__arrows &__pic {
    @include nl {
      margin-right: 64px; } }
  &__scroll:hover &__arrows {
    animation: arrowsRight .4s forwards; }
  &__item {
    display: block;
    margin-bottom: 21px;
    @include d {
      margin-bottom: 16px; } }
  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 247px;
    padding: 3px 250px 0 64px;
    border-radius: 40px;
    box-shadow: 0 5px 20px #EDEDED;
    background: $wh;
    transition: all .25s;
    @include d {
      height: 175px;
      padding: 0 165px 0 32px;
      border-radius: 28px; }
    @include m {
      padding: 0 40px; }
    &:hover {
      background: $dark;
      .icon {
        fill: $wh; } } }
  &__preview {
    position: absolute;
    top: 16px;
    right: 16px;
    bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 215px;
    padding: 20px;
    border-radius: 40px;
    background: $primary;
    font-size: 0;
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
    @include d {
      top: 12px;
      right: 12px;
      bottom: 12px;
      width: 150px; }
    @include t {
      visibility: visible;
      opacity: 1; }
    @include m {
      display: none; } }
  &__item:hover &__preview {
    visibility: visible;
    opacity: 1; }
  &__preview &__pic {
    max-width: 100%;
    max-height: 100%; }
  &__vacancy {
    position: relative;
    z-index: 2;
    display: inline-block;
    margin-bottom: 4px;
    color: $dark;
    transition: color .25s; }
  &__item:hover &__vacancy {
    color: $wh; }
  &__counter {
    position: absolute;
    top: 5px;
    left: calc(100% + 15px);
    min-width: 32px;
    border-radius: 50%;
    background: linear-gradient(180deg, #3A8FFF 0%, #0837AE 100%);
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    font-weight: 600;
    color: $wh;
    @include d {
      min-width: 24px;
      line-height: 24px;
      font-size: 13px; } }
  &__item &__text {
    margin-bottom: 30px;
    line-height: (32/18);
    color: $text-light;
    transition: color .25s;
    @include d {
      margin-bottom: 10px; } }
  &__item:hover &__text {
    color: $text-dark; }
  .icon {
    font-size: 16px;
    fill: $dark;
    transition: fill .25s; }
  .line {
    top: 0;
    left: calc(50% - 395px);
    opacity: .3;
    @include d {
      top: -103px;
      left: calc(50% - 335px); }
    @include m {
      top: -152px;
      left: 100px; } } }

@keyframes arrowsRight {
  100% {
    transform: translateX(43px); } }

.about {
  &__center {
    max-width: 1440px;
    padding: 0 72px;
    @include d {
      padding: 0 49px; } }
  &__container {
    position: relative;
    background: $primary;
    border-radius: 40px;
    color: $wh;
    @include d {
      border-radius: 28px; }
    @include m {
      border-radius: 24px; } }
  &__head {
    position: relative;
    z-index: 1;
    padding: 128px 0 176px;
    border-bottom: 1px solid rgba($wh, .2);
    text-align: center;
    @include mac {
      padding-bottom: 140px; }
    @include d {
      padding: 91px 0 81px; }
    @include t {
      padding: 50px 0; }
    @include m {
      padding: 48px 40px 40px; } }
  &__logo {
    margin-bottom: 40px;
    font-size: 0;
    @include t {
      margin-bottom: 32px; }
    @include m {
      display: none; } }
  &__logo &__pic {
    width: 24px; }
  &__hello {
    margin-bottom: 40px;
    font-weight: 600;
    @include t {
      margin-bottom: 32px; }
    @include m {
      margin-bottom: 40px; }
    span {
      margin-right: 12px; } }
  &__info {
    max-width: 568px;
    margin: 0 auto;
    @include d {
      max-width: 404px; }
    @include m {
      font-size: 18px;
      line-height: (24/18); } }
  &__row {
    position: relative;
    z-index: 2;
    display: flex;
    @include m {
      display: block; } }
  &__col {
    flex: 0 0 50%;
    width: 50%;
    padding: 120px 50px 175px;
    @include d {
      padding: 85px 50px 110px; }
    @include t {
      padding: 50px 30px 60px; }
    @include m {
      width: 100%;
      padding: 40px 24px 32px; }
    &:nth-child(2) {
      border-left: 1px solid rgba($wh, .2);
      @include m {
        display: none; } } }
  &__title {
    max-width: 370px;
    margin: 0 68px 0 auto;
    @include d {
      max-width: 258px;
      margin-right: 27px; }
    @include t {
      margin-right: 0; }
    @include m {
      max-width: 100%;
      margin: 0 0 40px;
      text-align: center;
      font-size: 32px;
      line-height: (40/32); } }
  &__box {
    max-width: 465px;
    margin-left: 68px;
    @include d {
      max-width: 330px;
      margin-left: 38px; }
    @include t {
      margin-left: 0; } }
  &__btn {
    display: none;
    @include m {
      display: block;
      .btn {
        min-width: 100%; } } }
  &__icon {
    margin-bottom: 29px;
    font-size: 0;
    @include d {
      margin-bottom: 20px; } }
  &__head &__icon {
    display: none;
    @include m {
      display: block;
      margin-right: -35px;
      margin-bottom: 6px; } }
  &__icon &__pic {
    width: 103px;
    @include d {
      width: 73px; } }
  &__line {
    display: flex;
    align-items: center; }
  &__text {
    flex-grow: 1; }
  &__arrow {
    position: relative;
    flex-shrink: 0;
    margin: -13px 0 0 34px;
    border-color: $wh;
    .icon {
      fill: $wh; } }
  .line {
    top: 0;
    left: 120px;
    z-index: 0;
    opacity: .1;
    @include d {
      top: -103px;
      left: 0; }
    @include m {
      top: -152px;
      left: -40px; } } }

// .footer
//   position: relative
//   overflow: hidden
//   +m
//     padding: 80px 0 34px
//   &__center
//     position: relative
//     z-index: 2
//   &__body
//     padding: 128px 0 48px
//     border-bottom: 1px solid #D8D8D8
//     text-align: center
//     +d
//       padding: 70px 0 50px
//     +m
//       margin-bottom: 32px
//       padding: 0
//       border: none
//   &__logo
//     margin-bottom: 48px
//     display: inline-block
//     font-size: 0
//     +d
//       margin-bottom: 32px
//   &__logo &__pic
//     width: 191px
//     +d
//       width: 136px
//   &__body &__text
//     max-width: 565px
//     margin: 0 auto 50px
//     line-height: (32/18)
//     color: $text-light
//     +d
//       max-width: 505px
//       margin-bottom: 28px
//       font-size: 14px
//       line-height: (18/14)
//   &__menu
//     display: flex
//     justify-content: center
//   &__menu &__link
//     font-weight: 600
//     color: $text-dark
//     transition: color .25s
//     &:hover
//       color: $primary
//     +nl
//       margin-right: 64px
//       +d
//         margin-right: 54px
//       +m
//         margin-right: 40px
//   &__bottom
//     display: flex
//     align-items: center
//     padding: 32px 0
//     font-size: 14px
//     line-height: (18/14)
//     font-weight: 600
//     +d
//       padding: 25px 0
//       font-size: 12px
//     +m
//       flex-direction: column-reverse
//       padding: 0
//   &__copyright
//     margin-right: auto
//     color: #ADADAD
//     +d
//       font-size: 12px
//     +m
//       margin: 32px 0 0
//       text-align: center
//   &__social
//     display: flex
//     align-items: center
//   &__social &__text
//     margin-right: 40px
//     color: $primary
//     +d
//       margin-right: 24px
//     +m
//       display: none
//   &__list
//     display: flex
//     align-items: center
//   &__list &__link
//     font-size: 0
//     +m
//       display: flex
//       justify-content: center
//       align-items: center
//       flex-shrink: 0
//       width: 40px
//       height: 40px
//       border-radius: 50%
//       border: 1px solid #D8D8D8
//     .icon
//       font-size: 16px
//       fill: #ADADAD
//       transition: fill .25s
//       +m
//         font-size: 12px
//       &-youtube
//         font-size: 12px
//         +m
//           font-size: 10px
//       &:hover
//         fill: $primary
//     +nl
//       margin-right: 40px
//       +m
//         margin-right: 8px
//   .line
//     top: 0
//     right: calc(50% - 690px)
//     opacity: .75
//     +d
//       top: -133px
//       right: -100px
//     +m
//       top: -150px
//       right: auto
//       left: -200px

.case {
  position: relative;
  padding: 222px 0 99px;
  @include d {
    padding: 158px 0 96px; }
  @include m {
    padding: 118px 0 420px; }
  &__wrap {
    position: relative;
    z-index: 3;
    max-width: 400px;
    @include m {
      max-width: 100%; } }
  &__arrows {
    margin-bottom: 108px;
    @include d {
      margin-bottom: 86px; }
    @include t {
      margin-bottom: 40px; }
    @include m {
      display: none; } }
  &__category {
    margin-bottom: 24px;
    background: #FFBA79;
    @include d {
      margin-bottom: 16px; } }
  &__title {
    margin-bottom: 25px;
    @include m {
      margin-bottom: 16px;
      font-size: 64px;
      line-height: (72/64); } }
  &__info {
    max-width: 266px;
    margin-bottom: 148px;
    @include d {
      margin-bottom: 115px; }
    @include t {
      margin-bottom: 56px; }
    @include m {
      max-width: 200px;
      margin-bottom: 32px; } }
  &__list {
    display: flex; }
  &__item {
    font-weight: 600;
    color: $primary;
    @include m {
      max-width: 120px; }
    @include nl {
      margin-right: 58px;
      @include d {
        margin-right: 40px; } } }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-bottom: 16px;
    border-radius: 50%;
    background: #908CFF;
    font-size: 0; }
  &__bg {
    position: absolute;
    top: 320px;
    right: calc(50% - 620px);
    width: 745px;
    font-size: 0;
    @include x {
      width: 700px; }
    @include d {
      top: 250px;
      right: calc(50% - 450px);
      width: 550px; }
    @include t {
      right: calc(50% - 350px);
      width: 400px; }
    @include m {
      top: auto;
      right: calc(50% - 200px);
      bottom: 70px;
      width: 380px; } }
  &__bg &__pic {
    width: 100%; }
  &__preview {
    position: relative;
    z-index: 2; }
  &__figure {
    position: absolute;
    top: -18%;
    right: -3.5%;
    width: 76%; }
  & > .line {
    top: 0;
    left: calc(50% - 425px);
    @include d {
      top: -110px;
      left: calc(50% - 365px); }
    @include t {
      left: 100px; }
    @include m {
      top: -160px;
      left: 75px; }
    path {
      animation: dash 2.7s .8s linear forwards; } } }

.overview {
  position: relative;
  padding: 135px 0 222px;
  @include d {
    padding: 112px 0 135px; }
  @include t {
    padding: 60px 0 110px; }
  @include m {
    padding-bottom: 70px; }
  &__center {
    display: flex;
    @include m {
      display: block; } }
  &__container {
    flex-shrink: 0;
    width: 470px;
    @include d {
      width: 328px; }
    @include t {
      width: 300px; }
    @include m {
      margin: 0 auto 40px; }
    @include s {
      width: 100%; } }
  &__slider {
    visibility: hidden;
    &.slick-initialized {
      visibility: visible; } }
  &__preview {
    font-size: 0; }
  &__preview &__pic {
    width: 100%; }
  &__wrap {
    flex-grow: 1;
    margin-top: -14px;
    padding-left: 133px;
    @include mac {
      padding-left: 80px; }
    @include d {
      padding-left: 95px; }
    @include t {
      padding-left: 50px; }
    @include m {
      padding: 0; } }
  &__stage {
    margin-bottom: 24px;
    .stage__number {
      color: $text-dark; } }
  &__title {
    margin-bottom: 48px;
    @include d {
      margin-bottom: 40px; } }
  &__text {
    margin-bottom: 40px;
    line-height: (32/18);
    color: $text-dark; }
  &__info {
    max-width: 370px;
    margin-bottom: 79px;
    @include d {
      margin-bottom: 40px; }
    @include m {
      margin-bottom: 32px; } }
  &__list {
    display: flex;
    margin: 0 -18px;
    @include d {
      margin: 0 -12px; }
    @include m {
      display: block;
      margin: 0; } }
  &__item {
    flex: 0 0 calc(50% - 36px);
    width: calc(50% - 36px);
    margin: 0 18px;
    @include d {
      flex: 0 0 calc(50% - 24px);
      width: calc(50% - 24px);
      margin: 0 12px; }
    @include m {
      width: 100%;
      margin: 0; }
    @include nl {
      @include m {
        margin-bottom: 22px; } } }
  &__inner {
    position: relative;
    padding: 80px 40px;
    border-radius: 48px;
    border: 1px solid #D8D8D8;
    text-align: center;
    transition: all .25s;
    @include mac {
      padding: 70px 20px; }
    @include d {
      padding: 56px 20px;
      border-radius: 32px; }
    @include t {
      padding: 32px 10px; }
    @include m {
      padding: 56px 24px; }
    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      right: 0;
      bottom: -8px;
      z-index: -1;
      background: #EDEDED;
      filter: blur(130.478px);
      border-radius: 48px;
      opacity: 0;
      transition: opacity .25s;
      @include d {
        filter: blur(92.784px);
        border-radius: 32px; } }
    &:hover {
      background: $wh;
      border-color: $wh;
      &:before {
        opacity: 1; } } }
  &__icon {
    display: inline-block;
    margin-bottom: 40px;
    font-size: 0;
    @include d {
      margin-bottom: 28px; } }
  &__icon &__pic {
    width: 128px;
    @include d {
      width: 92px; } }
  &__category {
    color: $primary;
    @include t {
      font-size: 16px; }
    @include m {
      font-size: 18px; } }
  .slick-dots {
    margin-top: 60px;
    @include m {
      margin-top: 20px; } }
  .slick-slide {
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
    &.slick-active {
      visibility: visible;
      opacity: 1; } } }

.overviewnew {
  // position: relative
  padding: 135px 0 222px;
  @include d {
    padding: 112px 0 135px; }
  @include t {
    padding: 60px 0 110px; }
  @include m {
    padding-bottom: 70px; }
  &__center {
    display: flex;
    @include m {
      display: block; } }
  &__container {
    // flex-shrink: 0
    margin: 180px 0 100px 0;
    padding: 0 30px;

    @include m {
      margin: 150px 0 0 0; } }


  &__slider {
    visibility: hidden;
    &.slick-initialized {
      visibility: visible; } }
  &__preview {
    font-size: 0; }
  &__preview &__pic {
    width: 100%; }
  &__wrap {
    flex-grow: 1;
    margin-top: -14px;
    padding-left: 133px;
    @include mac {
      padding-left: 80px; }
    @include d {
      padding-left: 95px; }
    @include t {
      padding-left: 50px; }
    @include m {
      padding: 0; } }
  &__stage {
    margin-bottom: 24px;
    .stage__number {
      color: $text-dark; } }
  &__title {
    margin-bottom: 48px;
    @include d {
      margin-bottom: 40px; } }
  &__text {
    margin-bottom: 40px;
    line-height: (32/18);
    color: $text-dark; }
  &__info {
    text-decoration: underline;
    max-width: 370px;
    margin-bottom: 79px;
    @include d {
      margin-bottom: 40px; }
    @include m {
      margin-bottom: 32px; } }
  &__list {
    display: flex;
    // margin: 0 -18px
    @include d {
      margin: 0 -12px; }
    @include m {
      display: block;
      margin: 0; } }
  &__item {
    // flex: 0 0 calc(50% - 36px)
    width: calc(50% - 36px);
    margin: 0 18px;
    @include d {
      // flex: 0 0 calc(50% - 24px)
      width: calc(50% - 24px);
      margin: 0 12px; }
    @include m {
      width: 100%;
      margin: 0; }
    @include nl {
      @include m {
        margin-bottom: 22px; } } }
  &__inner {
    position: relative;
    padding: 80px 40px;
    border-radius: 48px;
    border: 1px solid #D8D8D8;
    text-align: center;
    transition: all .25s;
    @include mac {
      padding: 70px 20px; }
    @include d {
      padding: 56px 20px;
      border-radius: 32px; }
    @include t {
      padding: 32px 10px; }
    @include m {
      padding: 56px 24px; }
    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      right: 0;
      bottom: -8px;
      z-index: -1;
      background: #EDEDED;
      filter: blur(130.478px);
      border-radius: 48px;
      opacity: 0;
      transition: opacity .25s;
      @include d {
        filter: blur(92.784px);
        border-radius: 32px; } }
    &:hover {
      background: $wh;
      border-color: $wh;
      &:before {
        opacity: 1; } } }
  &__icon {
    display: inline-block;
    margin-bottom: 40px;
    font-size: 0;
    @include d {
      margin-bottom: 28px; } }
  &__icon &__pic {
    width: 128px;
    @include d {
      width: 92px; } }
  &__category {
    color: $primary;
    @include t {
      font-size: 16px; }
    @include m {
      font-size: 18px; } }
  .slick-dots {
    margin-top: 60px;
    @include m {
      margin-top: 20px; } }
  .slick-slide {
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
    &.slick-active {
      visibility: visible;
      opacity: 1; } } }


.quality {
  position: relative;
  padding-top: 130px;
  color: $wh;
  @include d {
    padding-top: 98px; }
  @include m {
    padding-top: 56px; }
  &__head {
    margin-bottom: 96px;
    @include x {
      margin-bottom: 70px; }
    @include d {
      margin-bottom: 40px; }
    .stage {
      color: $wh; }
    .stage__number {
      color: $text-dark; } }
  &__list {
    display: flex;
    margin-bottom: 70px;
    padding-left: 200px;
    @include d {
      margin-bottom: 56px;
      padding-left: 140px; }
    @include t {
      padding-left: 80px; }
    @include m {
      flex-direction: column-reverse;
      padding: 0; } }
  &__item {
    max-width: 267px;
    @include d {
      max-width: 190px; }
    @include m {
      max-width: 100%; }
    &:first-child {
      margin-top: 150px;
      @include d {
        margin-top: 110px; }
      @include m {
        margin: 0; } }
    @include nl {
      margin-right: 134px;
      @include d {
        margin-right: 93px; }
      @include m {
        margin: 40px 0 0; } } }
  &__number {
    margin-right: -50px;
    margin-bottom: 8px; }
  &__category {
    margin-bottom: 64px;
    @include d {
      margin-bottom: 32px; } }
  &__info {
    margin-bottom: 28px;
    padding-top: 64px;
    border-top: 1px solid $text-light;
    line-height: (32/18);
    color: $text-dark;
    @include d {
      margin-bottom: 12px;
      padding-top: 32px; } }
  &__download {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    line-height: (24/18);
    color: $wh; }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    border-radius: 50%;
    background: $primary;
    overflow: hidden;
    font-size: 0; }
  &__arrows {
    display: flex;
    flex-direction: column;
    transform: translateY(-43px);
    transition: transform .35s; }
  &__arrows &__pic {
    @include nl {
      margin-bottom: 64px; } }
  &__download:hover &__arrows {
    animation: arrowsDown .5s forwards; }
  &__nav {
    position: relative;
    display: flex;
    max-width: 667px;
    margin-left: 200px;
    margin-bottom: 128px;
    @include d {
      max-width: 426px;
      margin-left: 140px;
      margin-bottom: 56px; }
    @include t {
      margin-left: 80px; }
    @include m {
      display: none; }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: $text-light; } }
  &__link {
    position: relative;
    padding-top: 40px;
    font-weight: 600;
    color: $text-dark;
    transition: color .25s;
    @include d {
      padding-top: 24px; }
    &:before {
      content: "";
      position: absolute;
      top: -2px;
      left: 50%;
      transform: translateX(-50%);
      width: 5px;
      height: 5px;
      background: $wh;
      border-radius: 50%;
      transition: all .25s; }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 50%;
      height: 1px;
      background: $wh; }
    &.active {
      color: $wh;
      &:before {
        transform: scale(1.85);
        box-shadow: 0 0 0 2px $primary; } }
    @include nl {
      margin-right: auto; }
    &:first-child {
      &:before {
        left: -2px;
        transform: translateX(0); } }
    &:nth-child(2) {
      &.active:after {
        width: 236px;
        @include d {
          width: 160px; } } }
    &:nth-child(3) {
      &.active:after {
        width: 440px;
        @include d {
          width: 283px; } } }
    &:nth-child(4) {
      &.active:after {
        width: 664px;
        @include d {
          width: 427px; } } }
    &:last-child {
      margin-right: -50px; } }
  &__container {
    position: relative;
    z-index: 3;
    margin-bottom: -168px;
    padding: 0 100px;
    @include d {
      margin-bottom: -115px;
      padding: 0 70px; }
    @include m {
      position: relative;
      margin: 0 -80px -32px;
      padding: 0; } }
  &__select {
    display: none;
    position: absolute;
    top: 0;
    right: 80px;
    z-index: 2;
    min-width: 144px;
    height: 48px;
    padding: 0 60px 0 24px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 8px;
    background: #303055 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none'%3E%3Cpath fill-rule='evenodd' d='M.293.293a1 1 0 0 1 1.414 0L5 3.586 8.293.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z' fill='%23fff'/%3E%3C/svg%3E") no-repeat calc(100% - 18px) 50% / 10px 6px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: $wh;
    &::-ms-expand {
      display: none; }
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000; }
    @include m {
      display: block; } }
  &__slider {
    visibility: hidden;
    @include m {
      padding-top: 124px; }
    &.slick-initialized {
      visibility: visible; } }
  &__preview {
    font-size: 0; }
  &__preview &__pic {
    width: 100%; }
  .line {
    top: -138px;
    left: 50px;
    transform: rotate(-90deg);
    @include d {
      top: -220px;
      left: -30px;
      transform: rotate(-90deg) scale(.7); }
    @include m {
      display: none; } }
  .slick-slide {
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
    &.slick-active {
      visibility: visible;
      opacity: 1; } }
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include m {
      top: 0;
      transform: translateY(0); } }
  .slick-prev {
    left: -100px;
    @include d {
      left: -70px; }
    @include m {
      left: 80px; } }
  .slick-next {
    right: -100px;
    @include d {
      right: -70px; }
    @include m {
      right: auto;
      left: 144px; } } }

.advantages {
  position: relative;
  padding: 357px 0 137px;
  background-color: $wh;
  background-image: linear-gradient(360deg, rgba(255, 255, 255, 0.0001) 0%, rgba(237, 229, 240, 0.504289) 100%);
  @include d {
    padding: 230px 0 120px; }
  @include m {
    padding: 100px 0 64px; }
  &__center {
    position: relative;
    z-index: 1;
    display: flex;
    @include m {
      display: block; } }
  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    padding: 0 135px 0 100px;
    @include mac {
      padding: 0 80px; }
    @include d {
      padding: 0 94px 0 70px; }
    @include t {
      padding: 0 50px 0 0; }
    @include m {
      margin-bottom: 56px;
      padding: 0; } }
  &__stage {
    margin-bottom: 24px;
    .stage__number {
      color: $text-dark; } }
  &__title {
    margin-bottom: 48px;
    @include d {
      margin-bottom: 24px; } }
  &__box {
    position: relative;
    flex-shrink: 0;
    width: 568px;
    @include d {
      width: 401px; }
    @include t {
      width: 350px; }
    @include m {
      width: auto;
      margin: 0 -16px; }
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      border-radius: 40px;
      @include d {
        border-radius: 28px; } }
    &:before {
      top: 8px;
      bottom: -8px;
      z-index: 2;
      background: rgba($wh, .5); }
    &:after {
      top: 80px;
      bottom: -80px;
      z-index: 1;
      background: #EDEDED;
      filter: blur(130.478px);
      @include d {
        top: 56px;
        bottom: -56px;
        filter: blur(92.784px); } } }
  &__inner {
    position: relative;
    z-index: 3;
    padding: 80px;
    border-radius: 40px;
    background: $wh;
    @include d {
      padding: 64px 50px 54px;
      border-radius: 28px; }
    @include t {
      padding: 55px 30px; }
    @include m {
      padding: 36px 24px; } }
  &__info {
    margin-bottom: 40px;
    line-height: (32/18);
    color: $text-dark;
    @include d {
      margin-bottom: 0; } }
  &__number {
    color: $primary; }
  &__category {
    display: inline-block;
    margin-top: 5px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: calc(100% + 80px);
      width: 20px;
      height: 3px;
      background: $primary;
      @include m {
        left: calc(100% + 52px);
        width: 14px;
        height: 2px; } } }
  &__list {
    margin-bottom: 69px;
    @include d {
      margin-bottom: 48px; } }
  &__item {
    display: flex;
    align-items: center;
    @include nl {
      margin-bottom: 80px;
      @include d {
        margin-bottom: 48px; }
      @include m {
        margin-bottom: 40px; } } }
  &__preview {
    margin-right: 40px;
    font-size: 0;
    @include t {
      margin-right: 24px; } }
  &__preview &__pic {
    width: 96px;
    @include d {
      width: 68px; } }
  &__btn .btn {
    min-width: 100%; }
  .line {
    top: 55px;
    left: calc(50% - 600px);
    z-index: 0;
    opacity: .3;
    @include d {
      top: -50px;
      left: 0; }
    @include m {
      left: 100px; } } }

// .cases
//   position: relative
//   margin-bottom: 290px
//   +x
//     margin-bottom: 230px
//   +d
//     margin-bottom: 115px
//   +m
//     margin-bottom: 70px
//   &__head
//     margin-bottom: 96px
//     +mac
//       padding-left: 160px
//     +d
//       margin-bottom: 64px
//     +m
//       margin-bottom: 40px
//     .head__title
//       +m
//         max-width: 100%
//     .head__text
//       +d
//         margin-top: 24px
//     .arrows
//       margin-right: 80px
//       padding-top: 60px
//       +d
//         margin-right: 0
//       +m
//         padding-top: 32px
//   &__container
//     max-width: 1070px
//     padding-left: 200px
//     +mac
//       padding-left: 160px
//     +d
//       padding-left: 140px
//     +t
//       padding: 0
//   &__inner
//     margin: 0 -66px
//     +d
//       margin: 0 -48px
//   &__slider
//     visibility: hidden
//     &.slick-initialized
//       visibility: visible
//   &__item
//     margin: 0 66px
//     +d
//       margin: 0 48px
//   &__preview
//     position: relative
//     margin-bottom: 40px
//     font-size: 0
//     +d
//       margin-bottom: 32px
//     +m
//       margin-right: 24px
//       margin-bottom: 24px
//   &__pic
//     width: 100%
//   &__category
//     position: absolute
//     top: 40px
//     left: -40px
//     +d
//       top: 24px
//       left: -24px
//   &__arrow
//     position: absolute
//     right: -32px
//     bottom: 64px
//     +d
//       right: -24px
//       bottom: 44px
//   &__title
//     margin-bottom: 23px
//     +d
//       margin-right: -32px
//     +m
//       margin-right: 0
//   &__text
//     line-height: (32/18)
//     color: $text-light
//   .line
//     top: 650px
//     left: -50px
//     transform: rotate(-90deg)
//     opacity: .3
//     +d
//       top: 400px
//     +m
//       display: none
//   .slick-list
//     overflow: visible
//   .slick-slide
//     visibility: hidden
//     opacity: 0
//     transition: all .25s
//     &.slick-active
//       visibility: visible
//       opacity: 1

.cases {
  position: relative;
  padding-top: 128px;
  @include d {
    padding-top: 96px; }
  @include m {
    padding-top: 64px; }
  &__center {
    position: relative;
    z-index: 2; }
  &__title {
    margin-bottom: 80px;
    @include d {
      margin-bottom: 32px; } }
  &__nav {
    display: flex;
    margin-bottom: 88px;
    @include d {
      margin-bottom: 48px; }
    @include m {
      display: none; } }
  &__link {
    position: relative;
    padding-bottom: 10px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    color: $gray-color;
    transition: color .25s;
    @include d {
      font-size: 14px; }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 0;
      height: 2px;
      margin: 0 auto;
      background: $purple;
      border-radius: 1px;
      transition: width .25s; }
    &:hover {
      color: $dark; }
    &.active {
      color: $dark;
      &:before {
        width: 100%; } }
    @include nl {
      margin-right: 96px;
      @include d {
        margin-right: 48px; } } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;
    @include d {
      margin: -24px -12px 0; }
    @include m {
      display: block;
      width: calc(100% + 64px);
      margin: 0 -32px; } }
  &__item {
    position: relative;
    display: block;
    background: $wh;
    border-radius: 24px;
    @include d {
      border-radius: 17px; }
    @include m {
      background: none;
      border-radius: 0; }
    &:before {
      content: "";
      position: absolute;
      top: 24px;
      left: 30px;
      right: 30px;
      bottom: -96px;
      z-index: -1;
      border-radius: 24px;
      filter: blur(86.985px);
      background: #DBDEE1;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transition: all .25s;
      @include d {
        top: 30px;
        left: 24px;
        right: 24px;
        bottom: -51px;
        border-radius: 17px;
        filter: blur(77px); }
      @include m {
        display: none; } }
    &:hover {
      &:before {
        visibility: visible;
        opacity: 1; } } }
  &__list &__item {
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 32px 16px 0;
    @include d {
      flex: 0 0 calc(50% - 24px);
      width: calc(50% - 24px);
      margin: 24px 12px 0; }
    @include m {
      width: 100%;
      margin: 0; } }
  &__preview {
    position: relative;
    font-size: 0; }
  &__pic {
    width: 100%; }
  &__blogpic {
    width: 100%;
    border-radius: 24px;
    @include d {
      border-radius: 17px; }
    @include m {
      margin-bottom: 16px;
      border-radius: 0; } }
  &__preview &__pic {
    border-radius: 24px 24px 0 0;
    @include d {
      border-radius: 17px 17px 0 0; }
    @include m {
      border-radius: 0; } }
  &__box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) scale(.6);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    padding: 30px;
    border-radius: 50%;
    background: $bl;
    text-align: center;
    opacity: 0;
    font-size: 18px;
    line-height: (24/18);
    font-weight: 500;
    color: $wh;
    transition: all .25s;
    @include m {
      display: none; } }
  &__item:hover &__box {
    opacity: 1;
    transform: translate(-50%,-50%) scale(1); }
  &__body {
    flex-grow: 1;
    padding: 48px 48px 76px;
    border-radius: 0 0 24px 24px;
    background: $wh;
    @include d {
      padding: 40px;
      border-radius: 0 0 17px 17px; }
    @include t {
      padding: 32px; } }
  &__info {
    margin-bottom: 8px;
    color: $dark;
    @include d {
      margin-bottom: 4px; } }
  &__text {
    color: $gray-text; }
  &__figure {
    position: absolute;
    font-size: 0;
    pointer-events: none;
    @include m {
      display: none; }
    &:first-child {
      top: 128px;
      left: calc(50% - 181px);
      width: 363px;
      @include d {
        top: 80px;
        left: calc(50% - 135px);
        width: 280px; }
      @include t {
        left: 400px; } }
    &:nth-child(2) {
      left: calc(50% - 1406px);
      width: 1200px;
      @include d {
        left: -520px;
        width: 900px; } } }
  &__container {
    margin: 0 -16px;
    @include d {
      margin: 0 -12px; }
    @include m {
      margin: 0 -32px; } }
  .owl-stage-outer {
    overflow: visible; }
  .owl-item {
    padding: 0 16px;
    opacity: 0;
    transition: opacity .25s;
    @include d {
      padding: 0 12px; }
    @include m {
      padding: 0; }
    &.active {
      opacity: 1; } }
  .owl-nav {
    position: absolute;
    top: -290px;
    right: calc(50% - 550px);
    justify-content: stretch;
    @include mac {
      right: 50px; }
    @include d {
      top: -210px; } }
  .owl-dots {
    padding: 0 32px; }
  &_main &__figure {
    &:nth-child(2) {
      top: 335px;
      @include d {
        top: 260px; } } }
  &_other {
    margin-bottom: 160px;
    @include d {
      margin-bottom: 88px; }
    @include m {
      display: none;
      margin-bottom: 56px;
      padding-top: 0; } }
  &_other &__figure {
    &:nth-child(2) {
      top: 160px; } } }


[data-aos="animation-translate-right"] {
  transform: translateX(-30px);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateX(0);
    opacity: 1; } }

[data-aos="animation-translate-up"] {
  transform: translateY(40px);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateY(0);
    opacity: 1; } }

[data-aos="animation-scale-y"] {
  transform: translateY(40px) scaleY(1.4);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateY(0) scaleY(1);
    opacity: 1; } }

.review {
  position: relative;
  // margin-bottom: 130px
  padding-top: 128px;
  // padding-bottom: 130px
  @include d {
    // margin-bottom: 90px
    padding-top: 96px; }
  @include m {
    // margin-bottom: 72px
    padding-top: 72px; }
  &__center {
    position: relative;
    z-index: 2;
    max-width: 933px;
    @include d {
      max-width: 730px; } }
  &__stage.stage {
    padding: 0;
    text-align: center;
    &:before {
      display: none; } }
  &__title {
    margin-bottom: 96px;
    text-align: center;
    @include d {
      margin-bottom: 48px; }
    @include m {
      margin-bottom: 24px; } }
  &__container {
    margin: 0 -16px; }
  &__item {
    position: relative;
    padding: 64px 44px 82px 64px;
    border-radius: 24px;
    background: $wh;
    @include d {
      padding: 48px; }
    @include m {
      padding: 40px 38px 60px;
      text-align: center; }
    &:before,
    &:after {
      content: "";
      position: absolute; }
    &:before {
      top: 8px;
      left: 0;
      right: 0;
      bottom: -8px;
      z-index: -1;
      border-radius: 24px;
      background: rgba($wh, .3);
      @include m {
        display: block;
        background: rgba($wh, .5); } }
    &:after {
      left: 32px;
      right: 32px;
      bottom: -55px;
      z-index: -2;
      height: 180px;
      border-radius: 24px;
      background: #DBDEE1;
      filter: blur(86.985px);
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transition: all .25s;
      @include d {
        left: 23px;
        right: 23px;
        bottom: -48px;
        height: 96px;
        border-radius: 17px;
        filter: blur(108.731px); }
      @include m {
        top: 58px;
        left: 20px;
        right: 20px;
        bottom: -24px;
        height: auto; } } }
  &__item:hover:after {
    visibility: visible;
    opacity: 1; }
  &__text {
    margin-bottom: 50px;
    font-size: 28px;
    line-height: (40/28);
    @include d {
      margin-bottom: 40px;
      font-size: 18px;
      line-height: (32/18); }
    @include m {
      margin-bottom: 35px; } }
  &__author {
    display: inline-flex;
    align-items: center;
    @include m {
      display: block; } }
  &__ava {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    margin-right: 16px;
    font-size: 0;
    @include d {
      width: 46px;
      height: 46px;
      margin-right: 12px; }
    @include m {
      margin: 0 auto 12px; } }
  &__ava &__pic {
    width: 100%;
    min-height: 100%;
    border-radius: 50%; }
  &__man {
    @include d {
      font-size: 18px;
      line-height: (26/18); } }
  &__company {
    color: $gray-text; }
  &__preview {
    position: absolute;
    top: -78px;
    left: 50%;
    width: 730px;
    margin-left: -416px;
    opacity: .5;
    pointer-events: none;
    font-size: 0;
    @include d {
      top: -46px;
      width: 550px;
      margin-left: -308px; }
    @include m {
      top: 463px;
      left: -137px;
      z-index: 3;
      width: 300px;
      margin: 0; }
    &:nth-child(2) {
      display: none;
      @include m {
        display: block;
        top: 328px;
        left: auto;
        right: -22px;
        width: 120px; } } }
  &__preview &__pic {
    width: 100%; }
  .owl-stage-outer {
    position: relative;
    z-index: 2;
    overflow: visible; }
  .owl-item {
    padding: 0 16px;
    opacity: .4;
    transition: all .25s;
    &.active {
      opacity: 1;
      .review__item:after {
        @include d {
          visibility: visible;
          opacity: 1; } } } }
  .owl-nav {
    margin-top: 80px;
    @include d {
      margin-top: 68px; } }
  .owl-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 24px;
    z-index: 3;
    justify-content: center; } }

.contacts {
  position: relative;
  padding-top: 150px;
  margin-bottom: 182px;
  z-index: 3;
  @include d {
    margin-bottom: 112px; }
  @include m {
    margin-bottom: 57px; }
  &__container {
    position: relative;
    padding: 162px 100px 60px;
    background: $wh;
    border-radius: 48px;
    @include d {
      padding: 78px 73px 40px;
      border-radius: 34px; }
    @include m {
      padding: 0;
      border-radius: 0;
      background: none; }
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50px;
      border-radius: 34px;
      @include m {
        display: none; } }
    &:after {
      left: 17px;
      right: 17px;
      bottom: -16px;
      z-index: -1;
      background: $primary; }
    &:before {
      left: 50px;
      right: 50px;
      bottom: -32px;
      z-index: -2;
      background: #1B1B87; } }
  &__wrap {
    position: relative;
    z-index: 3;
    margin-bottom: 85px;
    @include m {
      margin-bottom: 40px; } }
  &__title {
    margin-bottom: 40px;
    @include d {
      margin-bottom: 32px; }
    @include m {
      margin: 0; } }
  &__info {
    max-width: 366px;
    color: $gray-text;
    @include d {
      max-width: 270px; }
    @include m {
      display: none; } }
  &__row {
    position: relative;
    z-index: 3;
    display: flex;
    margin-bottom: 93px;
    @include d {
      margin-bottom: 46px; }
    @include t {
      display: block; }
    @include m {
      margin-bottom: 40px; } }
  &__details {
    flex-shrink: 0;
    width: 340px;
    margin-right: auto;
    @include t {
      width: 100%;
      margin-bottom: 38px; } }
  &__line {
    display: flex;
    color: $dark;
    @include d {
      font-size: 16px;
      line-height: (28/16); }
    @include m {
      font-weight: 600; }
    @include nl {
      margin-bottom: 40px;
      @include d {
        margin-bottom: 20px; } } }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 24px;
    height: 30px;
    margin-right: 45px;
    font-size: 0;
    @include d {
      margin-right: 24px;
      width: 17px;
      height: 28px; }
    .icon {
      fill: $purple;
      &-email {
        font-size: 20px;
        @include d {
          font-size: 14px; } }
      &-place {
        font-size: 23px;
        @include d {
          font-size: 17px; } }
      &-phone {
        font-size: 24px;
        @include d {
          font-size: 18px; } } } }
  &__form {
    flex-shrink: 0;
    width: 468px;
    @include d {
      width: 345px; }
    @include t {
      width: 100%; } }
  &__field {
    @include nl {
      margin-bottom: 24px;
      @include d {
        margin-bottom: 16px; } } }
  &__btn .btn {
    @include m {
      min-width: 100%; } }
  &__category {
    display: none;
    @include m {
      display: block;
      margin-bottom: 32px; } }
  &__social {
    display: flex;
    align-items: center;
    margin: 0 -100px;
    @include d {
      margin: 0 -73px; }
    @include m {
      flex-wrap: wrap;
      margin: -16px -8px 0; } }
  &__box {
    // flex: 0 0 25%
    width: 25%;
    @include m {
      flex: 0 0 calc(50% - 16px);
      width: calc(50% - 16px);
      margin: 16px 8px 0; } }
  &__social &__link {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 500;
    color: $gray-color;
    transition: color .25s;
    @include d {
      height: 100px; }
    @include m {
      background: rgba(#F4F4F4, .8);
      border-radius: 17px;
      font-size: 14px; }
    .icon {
      position: relative;
      z-index: 3;
      margin-right: 14px;
      font-size: 16px;
      fill: $gray-color;
      transition: fill .25s;
      @include d {
        font-size: 14px; }
      @include m {
        margin-right: 10px;
        fill: $dark; } }
    &:after {
      content: "";
      @include coverdiv;
      z-index: 2;
      border-radius: 24px; }
    &:before {
      content: "";
      position: absolute;
      bottom: -50px;
      left: 0;
      right: 0;
      height: 120px;
      border-radius: 32px;
      background: #DBDEE1;
      filter: blur(86.985px);
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transition: all .25s;
      @include mac {
        bottom: -20px; } }
    &:hover {
      background: $wh;
      color: $dark;
      &:after {
        background: $wh; }
      &:before {
        visibility: visible;
        opacity: 1; }
      .icon {
        fill: $dark; } } }
  &__link &__text {
    position: relative;
    z-index: 3; }
  .figures {
    top: 109px;
    right: calc(50% - 720px);
    @include mac {
      right: calc(50% - 670px); }
    @include d {
      top: 80px;
      right: calc(50% - 530px); }
    @include t {
      top: 130px;
      right: calc(50% - 450px); }
    @include m {
      top: 90px;
      right: -90px;
      width: 320px; } } }

.figures {
  position: absolute;
  width: 50%;
  pointer-events: none;
  font-size: 0;
  @include d {
    width: 800px; }
  @include t {
    width: 700px; }
  &__preview {
    &:nth-child(1) {
      position: relative;
      top: -200px;
      width: 90%;
      @include m {
        display: none; }
      @include t {
        left: 40%;
        width: 50%; }
      // right: 16.2%
 }      // width: 80%
    // &:nth-child(3)
    //   top: -17%
    //   right: 16.2%
    //   width: 19%
    // &:nth-child(4)
    //   top: 12%
    //   right: 11.7%
    //   z-index: 2
    //   width: 26.2%
    // &:nth-child(5)
    //   left: 35.2%
    //   bottom: 23%
    //   z-index: 3
    //   width: 27.5%
    // &:nth-child(6)
    //   right: 0
    //   bottom: 10%
    //   z-index: 3
    //   width: 34%
    @include nf {
      position: absolute; } }
  &__preview &__pic {
    width: 100%; } }


.footer {
  position: relative;
  z-index: 2;
  color: $wh;
  @include m {
    position: relative;
    padding: 56px 0 60px;
    background: $bl;
    // background: $bl url('../img/bg-footer.png') no-repeat 100% 100% / 100% auto
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
      border-radius: 50%;
      border: 1px solid rgba($wh, .1);
      pointer-events: none; }
    &:before {
      top: 90px;
      width: 600px;
      height: 600px; }
    &:after {
      top: 201px;
      width: 376px;
      height: 376px; } }
  &__center {
    position: relative;
    z-index: 3; }
  &__body {
    display: flex;
    align-items: flex-start;
    margin-bottom: 113px;
    padding-right: 74px;
    @include mac {
      padding: 0; }
    @include d {
      margin-bottom: 88px; }
    @include m {
      display: block;
      margin-bottom: 52px; } }
  &__logo {
    display: inline-block;
    font-size: 0;
    @include m {
      margin-bottom: 16px; } }
  &__logo &__pic {
    @include d {
      width: 50px; } }
  &__wrap {
    max-width: 670px;
    margin: -22px auto 0;
    @include d {
      max-width: 490px;
      margin-top: -10px; }
    @include t {
      max-width: 430px; }
    @include m {
      max-width: 100%;
      margin: 0 0 42px; } }
  &__title {
    margin-bottom: 48px;
    @include d {
      margin-bottom: 44px;
      font-size: 42px;
      line-height: (56/42); }
    @include t {
      font-size: 36px; }
    @include m {
      margin-bottom: 32px;
      font-size: 42px;
      line-height: (56/42); } }
  &__info {
    color: $pink;
    @include d {
      font-size: 20px;
      line-height: 1.4; }
    @include m {
      font-size: 16px;
      line-height: (26/16); } }
  &__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include m {
      display: block;
      column-count: 2;
      padding-right: 100px; } }
  &__link {
    line-height: (26/18);
    font-weight: 500;
    color: $wh;
    transition: opacity .25s;
    @include d {
      font-size: 14px; }
    @include m {
      display: block; }
    &:hover {
      opacity: .3; }
    @include nl {
      margin-bottom: 23px;
      @include d {
        margin-bottom: 20px; } } }
  &__bottom {
    display: flex;
    align-items: center;
    margin: 0 -67px;
    padding: 45px 67px 58px;
    border-top: 1px solid rgba(#D8D8D8, .2);
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    @include d {
      padding: 32px 0 42px;
      font-size: 14px; }
    @include mac {
      margin: 0;
      padding: 45px 0 58px; }
    @include m {
      padding: 0;
      border: none; } }
  &__copyright {
    margin-right: auto; }
  &__scroll {
    color: $wh;
    transition: opacity .25s;
    @include m {
      display: none; }
    &:hover {
      opacity: .85; } }
  &__preview {
    position: absolute;
    font-size: 0;
    pointer-events: none;
    &:first-child {
      top: -155px;
      left: calc(50% - 536px);
      width: 330px;
      @include d {
        top: -105px;
        left: calc(50% - 355px);
        width: 250px; }
      @include m {
        display: none; } }
    &:nth-child(2) {
      top: 233px;
      right: calc(50% - 237px);
      width: 150px;
      @include d {
        top: 174px;
        right: calc(50% - 163px);
        width: 83px; }
      @include a {
        top: 300px;
        right: 50px;
        width: 150px; } } }
  &__preview &__pic {
    width: 100%; } }

.field {
  &__wrap {
    position: relative; }
  &__input,
  &__textarea,
  &__select {
    width: 100%;
    background: rgba(244, 244, 244, 0.801191);
    border-radius: 12px;
    border: 2px solid rgba(244, 244, 244, 0.801191);
    @include f;
    font-size: 18px;
    color: $dark;
    transition: all .25s;
    @include d {
      font-size: 16px; }
    &:focus {
      border-color: $purple;
      background: $wh; }
    @include placeholder {
      color: $gray-text; } }
  &__input:focus + &__icon .icon,
  &__textarea:focus + &__icon .icon,
  &__select:focus + &__icon .icon {
    fill: $purple; }
  &__input,
  &__select {
    height: 72px;
    padding: 0 72px 0 24px;
    @include d {
      height: 56px;
      padding-right: 56px; } }
  &__textarea {
    height: 200px;
    resize: none;
    padding: 20px 72px 20px 24px;
    @include d {
      height: 110px;
      padding: 12px 56px 14px 24px; } }
  &__select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &::-ms-expand {
      display: none; }
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000; } }
  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    font-size: 0;
    pointer-events: none;
    @include d {
      width: 56px;
      height: 56px; }
    .icon {
      font-size: 17px;
      fill: $dark;
      transition: fill .25s;
      @include d {
        font-size: 14px; } } }
  &_textarea {
    font-size: 0; } }

.wrapper {
  &_gradient {
    background: radial-gradient(75.22% 75.22% at 20.49% 12.79%, #FFFFFF 0%, rgba(228, 231, 233, 0.502295) 100%);
    @include m {
      background: none; } }
  // &_dark
  //   position: relative
  //   // &:before,
  //   // &:after
  //   //   content: ""
  //   //   position: absolute
  //   //   +m
  //   //     display: none
  //   &:before
  //     content: ""
  //     position: absolute
  //     top: 700px
  //     right: 0
  //     left: 0
  //     bottom: 0
  //     clip-path: polygon(0 600px, 100% 0, 100% 100%, 0 100%)
  //     background: $bl
  //     +d
  //       top: 500px
  //       clip-path: polygon(0 440px, 100% 0, 100% 100%, 0 100%)
  //   &:after
  //     content: ""
  //     position: absolute
  //     top: 741px
  //     // left: calc(50% - 720px)
  //     width: 300px
  //     height: 560px
  //     clip-path: polygon(0 58px, 100% 0, 100% calc(100% - 58px), 0 100%)
  //     background: $primary
  //     +x
  //       top: 760px
  //     // +mac
  //     //   display: none
  //     // +rmin(1441)
  //     //   display: none
  &_dark {
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      @include m {
        display: none; } }
    &:before {
      content: "";
      position: absolute;
      top: 500px;
      right: 0;
      left: 0;
      bottom: 0;
      clip-path: polygon(0 600px, 100% 0, 100% 100%, 0 100%);
      background: $bl; }

    &:after {
      content: "";
      position: absolute;
      top: 540px; // Adjusted position to be the same as the first triangle
      left: 0;
      width: 200px;
      height: 560px;
      clip-path: polygon(0 58px, 100% 0, 100% calc(100% - 58px), 0 100%);
      background: $primary; } } }


.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 48px 0;
  @include d {
    padding: 32px 0; }
  @include m {
    padding: 36px 0; }
  &__center {
    display: flex; }
  & > &__center {
    align-items: center;
    max-width: 100%;
    padding: 0 68px;
    @include d {
      padding: 0 48px; }
    @include m {
      padding: 0 32px; } }
  &__center &__logo {
    margin-right: 93px;
    @include d {
      margin-right: 68px; }
    @include t {
      margin-right: 40px; }
    @include m {
      position: relative;
      z-index: 15;
      margin-right: auto; } }
  &__logo {
    font-size: 0; }
  &__logo &__pic {
    width: 174px;
    @include d {
      width: 122px; }
    @include m {
      width: 133px; } }
  &__social {
    display: flex;
    align-items: center;
    &_main {
      margin-right: auto;
      @include m {
        display: none; } }
    &_inner {
      position: absolute;
      left: calc(50% - 184px);
      bottom: 82px;
      @include d {
        left: 380px;
        bottom: 56px; }
      @include m {
        position: relative;
        left: auto;
        bottom: auto;
        margin-top: 20px; }
      @include a {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 32px; } } }
  &__social &__link {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: $gray-color;
    transition: color .25s;
    @include d {
      font-size: 14px; }
    @include t {
      flex-direction: column; }
    .icon {
      margin-right: 15px;
      font-size: 16px;
      fill: $gray-color;
      transition: fill .25s; }
    &:hover {
      color: $dark;
      .icon {
        fill: $dark; } }
    @include nl {
      margin-right: 68px;
      @include t {
        margin-right: 40px; }
      @include a {
        margin: 0 0 16px; } } }
  &__burger {
    position: relative;
    z-index: 12;
    width: 64px;
    height: 64px;
    margin-right: -17px;
    border-radius: 50%;
    padding: 5px;
    background: transparent;
    font-size: 0;
    transition: all .25s;
    @include d {
      width: 48px;
      height: 48px;
      margin-right: -10px; }
    @include m {
      width: 32px;
      height: 32px;
      margin-right: -5px;
      padding: 0; }
    &:before,
    &:after {
      content: "";
      display: inline-block;
      width: 32px;
      height: 2px;
      background: $wh;
      transition: transform .25s;
      @include d {
        width: 24px; }
      @include m {
        background: $dark; } }
    &:before {
      margin-bottom: 4px;
      @include d {
        margin-bottom: 2.5px; } }
    &:after {
      margin-top: 4px;
      @include d {
        margin-top: 2.5px; } }
    &_dark {
      &:before,
      &:after {
        background: $primary; } }
    &:hover {
      opacity: .85; }
    &.active {
      background: $primary;
      @include m {
        background: none; }
      &:before,
      &:after {
        background: $wh;
        @include m {
          background: $bl; } }
      &:before {
        transform: translateY(5px) rotate(45deg);
        @include d {
          transform: translateY(3.5px) rotate(45deg); } }
      &:after {
        transform: translateY(-5px) rotate(-45deg);
        @include d {
          transform: translateY(-3.5px) rotate(-45deg); } } } }
  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 10;
    background: $wh;
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
    @include m {
      display: block; }
    @include a {
      visibility: visible;
      opacity: 1;
      transform: translateX(100%);
      background: $wh;
 }      // background: $wh url('../img/bg-header-mobile.png') no-repeat 100% 150% / 100% auto
    &.visible {
      visibility: visible;
      opacity: 1;
      @include a {
        transform: translateX(0); } } }
  &__preview {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 400px;
    padding: 64px 0 0 68px;
    background: linear-gradient(to bottom,$primraysecond,  $primary);
    overflow: hidden;
    @include d {
      width: 300px;
      padding: 46px 0 0 48px; }
    @include m {
      display: none; } }
  &__img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 100%;
    max-height: 100%;
    font-size: 0;
    @include t {
      height: 80%;
      max-height: 80%;
      transform: translateY(-60%); } }
  &__img &__pic {
    height: 100%;
    width: auto; }
  &__wrapper &__center {
    align-items: stretch;
    width: 100%;
    max-width: 1204px;
    padding-left: 418px;
    @include d {
      max-width: 100%;
      padding-left: 380px; }
    @include t {
      flex-direction: column-reverse; }
    @include m {
      display: block;
      padding: 120px 32px 30px; } }
  &__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    width: 340px;
    margin-right: auto;
    @include d {
      width: 280px; }
    @include t {
      margin: 120px 0 0; }
    @include m {
      display: none; } }
  &__line {
    display: flex;
    color: $dark;
    @include d {
      font-size: 16px;
      line-height: (28/16); }
    @include nl {
      margin-bottom: 24px; } }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 24px;
    height: 30px;
    margin-right: 45px;
    font-size: 0;
    @include d {
      margin-right: 25px;
      width: 17px;
      height: 28px; }
    .icon {
      fill: $primary;
      &-email {
        font-size: 20px;
        @include d {
          font-size: 14px; } }
      &-place {
        font-size: 23px;
        @include d {
          font-size: 17px; } }
      &-phone {
        font-size: 24px;
        @include d {
          font-size: 18px; } } } }
  &__nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -15px; }
  &__item {
    overflow: hidden;
    font-size: 72px;
    line-height: (84/72);
    letter-spacing: -.5px;
    font-weight: 600;
    color: $dark;
    transition: color .25s;
    @include d {
      font-size: 42px;
      line-height: (56/42); }
    @include m {
      font-size: 24px;
      line-height: 1.5; }
    @include a {
      font-size: 32px; }
    span {
      display: block;
      transform: translateY(110%);
      transition: transform .25s;
      transition-delay: .3s; }
    &:hover {
      color: $primary; }
    @include nl {
      margin-bottom: 16px;
      @include d {
        margin-bottom: 8px; }
      @include m {
        margin-bottom: 0; } } }
  &__wrapper.visible &__item {
    span {
      transform: translateX(0); }
    &:first-child span {
      transition-delay: .2s; }
    &:nth-child(2) span {
      transition-delay: .4s; }
    &:nth-child(3) span {
      transition-delay: .6s; }
    &:nth-child(4) span {
      transition-delay: .8s; } } }

.owl-nav {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 67px;
  height: 40px;
  @include d {
    margin-top: 52px; }
  @include m {
    height: 32px;
    margin-top: 36px; } }

.owl-prev,
.owl-next {
  position: relative;
  width: 30px;
  height: 40px;
  font-size: 0;
  @include m {
    height: 32px; }
  span {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 2px;
    background: $gray-color;
    font-size: 0;
    transition: all .25s;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%) scale(0);
      width: 40px;
      height: 40px;
      border: 2px solid $purple;
      border-radius: 50%;
      transition: transform .25s;
      @include m {
        width: 34px;
        height: 34px; } } }
  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    fill: $gray-color;
    transition: fill .25s; }
  &:hover {
    span {
      width: 52px;
      background: $dark;
      &:before {
        transform: translateY(-50%) scale(1); } }
    .icon {
      fill: $dark; } } }

.owl-prev {
  margin-right: 27px;
  @include m {
    margin-right: 20px; }
  span {
    right: 0;
    .icon {
      left: 0; }
    &:before {
      left: -17px;
      @include m {
        left: -14px; } } } }

.owl-next {
  span {
    left: 0;
    .icon {
      right: 0; }
    &:before {
      right: -17px;
      @include m {
        right: -14px; } } } }

.owl-dots {
  display: flex;
  padding: 0 32px; }

.owl-dot {
  position: relative;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #D8D8D8;
    transition: all .25s; }
  &.active:before {
    transform: translate(-50%,-50%) scale(2);
    background: $purple; }
  @include nl {
    margin-right: 12px; } }

[data-aos="animation-scale"] {
  transform: translateY(30px) scale(.5);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateY(0) scale(1);
    opacity: 1; } }

[data-aos="animation-scale-x-left"] {
  transform: translateX(-50px) scaleX(1.1);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateX(0) scaleX(1);
    opacity: 1; } }

[data-aos="animation-scale-x-right"] {
  transform: translateX(50px) scaleX(1.1);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateX(0) scaleX(1);
    opacity: 1; } }

[data-aos="animation-scale-y"] {
  transform: translateY(40px) scaleY(1.4);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateY(0) scaleY(1);
    opacity: 1; } }

[data-aos="animation-translate-y"] {
  transform: translateY(40px);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translateY(0);
    opacity: 1; } }

[data-aos="transform"] {
  transform: rotateX(90deg) skewY(-5deg);
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    transform: translate(0) rotateX(0deg) skewY(0deg);
    opacity: 1; } }

.linknew {
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  color: $wh;
  &_dark {
    color: $primary;
    @include m {
      display: none; } } }
