.icon-arrow-left {
  width: 1.38em;
  height: 1em;
  fill: #FFF;
}
.icon-arrow-next {
  width: 1.38em;
  height: 1em;
  fill: #FFF;
}
.icon-arrow-prev {
  width: 1.38em;
  height: 1em;
  fill: #FFF;
}
.icon-arrow-right {
  width: 1.38em;
  height: 1em;
  fill: #fff;
}
.icon-arrows {
  width: 0.67em;
  height: 1em;
  fill: #111029;
}
.icon-behance {
  width: 1.13em;
  height: 1em;
  fill: initial;
}
.icon-dribbble {
  width: 1em;
  height: 1em;
  fill: #111029;
}
.icon-email {
  width: 1.2em;
  height: 1em;
  fill: #4C40F7;
}
.icon-facebook-f {
  width: 0.63em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 1em;
  height: 1em;
  fill: #ADADAD;
}
.icon-instagram {
  width: 0.88em;
  height: 1em;
  fill: initial;
}
.icon-linkedin-in {
  width: 0.88em;
  height: 1em;
  fill: initial;
}
.icon-message {
  width: 1em;
  height: 1em;
  fill: #111029;
}
.icon-phone {
  width: 0.94em;
  height: 1em;
  fill: #4C40F7;
}
.icon-place {
  width: 1em;
  height: 1em;
  fill: #4C40F7;
}
.icon-profile {
  width: 0.94em;
  height: 1em;
  fill: #111029;
}
.icon-send {
  width: 1em;
  height: 1em;
  fill: #4C40F7;
}
.icon-youtube {
  width: 1.33em;
  height: 1em;
  fill: #ADADAD;
}
