@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; background: none; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input { appearance: none; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

select:-moz-focusring { color: transparent; text-shadow: 0 0 0 #000; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

a { text-decoration: none; }

a, button { -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.icon-arrow-left { width: 1.38em; height: 1em; fill: #FFF; }

.icon-arrow-next { width: 1.38em; height: 1em; fill: #FFF; }

.icon-arrow-prev { width: 1.38em; height: 1em; fill: #FFF; }

.icon-arrow-right { width: 1.38em; height: 1em; fill: #fff; }

.icon-arrows { width: 0.67em; height: 1em; fill: #111029; }

.icon-behance { width: 1.13em; height: 1em; fill: initial; }

.icon-dribbble { width: 1em; height: 1em; fill: #111029; }

.icon-email { width: 1.2em; height: 1em; fill: #4C40F7; }

.icon-facebook-f { width: 0.63em; height: 1em; fill: initial; }

.icon-facebook { width: 1em; height: 1em; fill: #ADADAD; }

.icon-instagram { width: 0.88em; height: 1em; fill: initial; }

.icon-linkedin-in { width: 0.88em; height: 1em; fill: initial; }

.icon-message { width: 1em; height: 1em; fill: #111029; }

.icon-phone { width: 0.94em; height: 1em; fill: #4C40F7; }

.icon-place { width: 1em; height: 1em; fill: #4C40F7; }

.icon-profile { width: 0.94em; height: 1em; fill: #111029; }

.icon-send { width: 1em; height: 1em; fill: #4C40F7; }

.icon-youtube { width: 1.33em; height: 1em; fill: #ADADAD; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; margin-left: auto; margin-right: auto; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow.slick-hidden { display: none; }

/** Owl Carousel v2.3.4 Copyright 2013-2018 David Deutsch Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE */
/* Owl Carousel - Core */
.owl-carousel { display: none; width: 100%; -webkit-tap-highlight-color: transparent; /* position relative and z-index fix webkit rendering fonts issue */ position: relative; z-index: 1; }

.owl-carousel .owl-stage { position: relative; -ms-touch-action: pan-Y; touch-action: manipulation; -moz-backface-visibility: hidden; /* fix firefox animation glitch */ }

.owl-carousel .owl-stage:after { content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0; }

.owl-carousel .owl-stage-outer { position: relative; overflow: hidden; /* fix for flashing background */ -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper, .owl-carousel .owl-item { -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; -ms-backface-visibility: hidden; -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item { position: relative; min-height: 1px; float: left; -webkit-backface-visibility: hidden; -webkit-tap-highlight-color: transparent; -webkit-touch-callout: none; }

.owl-carousel .owl-item img { display: block; width: 100%; }

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled { display: none; }

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-dot { cursor: pointer; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot { background: none; color: inherit; border: none; padding: 0 !important; font: inherit; }

.owl-carousel.owl-loaded { display: block; }

.owl-carousel.owl-loading { opacity: 0; display: block; }

.owl-carousel.owl-hidden { opacity: 0; }

.owl-carousel.owl-refresh .owl-item { visibility: hidden; }

.owl-carousel.owl-drag .owl-item { -ms-touch-action: pan-y; touch-action: pan-y; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.owl-carousel.owl-grab { cursor: move; cursor: grab; }

.owl-carousel.owl-rtl { direction: rtl; }

.owl-carousel.owl-rtl .owl-item { float: right; }

/* No Js */
.no-js .owl-carousel { display: block; }

/* Owl Carousel - Animate Plugin */
.owl-carousel .animated { animation-duration: 1000ms; animation-fill-mode: both; }

.owl-carousel .owl-animated-in { z-index: 0; }

.owl-carousel .owl-animated-out { z-index: 1; }

.owl-carousel .fadeOut { animation-name: fadeOut; }

@keyframes fadeOut { 0% { opacity: 1; }
  100% { opacity: 0; } }

/* Owl Carousel - Auto Height Plugin */
.owl-height { transition: height 500ms ease-in-out; }

/* Owl Carousel - Lazy Load Plugin */
.owl-carousel .owl-item { /** This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong calculation of the height of the owl-item that breaks page layouts */ }

.owl-carousel .owl-item .owl-lazy { opacity: 0; transition: opacity 400ms ease; }

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) { max-height: 0; }

.owl-carousel .owl-item img.owl-lazy { transform-style: preserve-3d; }

/* Owl Carousel - Video Plugin */
.owl-carousel .owl-video-wrapper { position: relative; height: 100%; background: #000; }

.owl-carousel .owl-video-play-icon { position: absolute; height: 80px; width: 80px; left: 50%; top: 50%; margin-left: -40px; margin-top: -40px; background: url("owl.video.play.png") no-repeat; cursor: pointer; z-index: 1; -webkit-backface-visibility: hidden; transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover { -ms-transform: scale(1.3, 1.3); transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon { display: none; }

.owl-carousel .owl-video-tn { opacity: 0; height: 100%; background-position: center center; background-repeat: no-repeat; background-size: contain; transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame { position: relative; z-index: 1; height: 100%; width: 100%; }

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] { transition-duration: 50ms; }

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate { transition-delay: 50ms; }

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] { transition-duration: .1s; }

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate { transition-delay: .1s; }

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] { transition-duration: .15s; }

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate { transition-delay: .15s; }

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] { transition-duration: .2s; }

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate { transition-delay: .2s; }

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] { transition-duration: .25s; }

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate { transition-delay: .25s; }

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] { transition-duration: .3s; }

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate { transition-delay: .3s; }

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] { transition-duration: .35s; }

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate { transition-delay: .35s; }

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] { transition-duration: .4s; }

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate { transition-delay: .4s; }

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] { transition-duration: .45s; }

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate { transition-delay: .45s; }

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] { transition-duration: .5s; }

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate { transition-delay: .5s; }

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] { transition-duration: .55s; }

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate { transition-delay: .55s; }

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] { transition-duration: .6s; }

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate { transition-delay: .6s; }

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] { transition-duration: .65s; }

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate { transition-delay: .65s; }

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] { transition-duration: .7s; }

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate { transition-delay: .7s; }

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] { transition-duration: .75s; }

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate { transition-delay: .75s; }

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] { transition-duration: .8s; }

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate { transition-delay: .8s; }

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] { transition-duration: .85s; }

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate { transition-delay: .85s; }

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] { transition-duration: .9s; }

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate { transition-delay: .9s; }

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] { transition-duration: .95s; }

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate { transition-delay: .95s; }

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] { transition-duration: 1s; }

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate { transition-delay: 1s; }

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] { transition-duration: 1.05s; }

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate { transition-delay: 1.05s; }

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] { transition-duration: 1.1s; }

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate { transition-delay: 1.1s; }

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] { transition-duration: 1.15s; }

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate { transition-delay: 1.15s; }

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] { transition-duration: 1.2s; }

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate { transition-delay: 1.2s; }

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] { transition-duration: 1.25s; }

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate { transition-delay: 1.25s; }

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] { transition-duration: 1.3s; }

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate { transition-delay: 1.3s; }

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] { transition-duration: 1.35s; }

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate { transition-delay: 1.35s; }

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] { transition-duration: 1.4s; }

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate { transition-delay: 1.4s; }

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] { transition-duration: 1.45s; }

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate { transition-delay: 1.45s; }

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] { transition-duration: 1.5s; }

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate { transition-delay: 1.5s; }

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] { transition-duration: 1.55s; }

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate { transition-delay: 1.55s; }

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] { transition-duration: 1.6s; }

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate { transition-delay: 1.6s; }

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] { transition-duration: 1.65s; }

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate { transition-delay: 1.65s; }

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] { transition-duration: 1.7s; }

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate { transition-delay: 1.7s; }

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] { transition-duration: 1.75s; }

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate { transition-delay: 1.75s; }

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] { transition-duration: 1.8s; }

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate { transition-delay: 1.8s; }

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] { transition-duration: 1.85s; }

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate { transition-delay: 1.85s; }

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] { transition-duration: 1.9s; }

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate { transition-delay: 1.9s; }

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] { transition-duration: 1.95s; }

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate { transition-delay: 1.95s; }

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] { transition-duration: 2s; }

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate { transition-delay: 2s; }

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] { transition-duration: 2.05s; }

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate { transition-delay: 2.05s; }

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] { transition-duration: 2.1s; }

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate { transition-delay: 2.1s; }

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] { transition-duration: 2.15s; }

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate { transition-delay: 2.15s; }

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] { transition-duration: 2.2s; }

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate { transition-delay: 2.2s; }

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] { transition-duration: 2.25s; }

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate { transition-delay: 2.25s; }

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] { transition-duration: 2.3s; }

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate { transition-delay: 2.3s; }

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] { transition-duration: 2.35s; }

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate { transition-delay: 2.35s; }

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] { transition-duration: 2.4s; }

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate { transition-delay: 2.4s; }

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] { transition-duration: 2.45s; }

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate { transition-delay: 2.45s; }

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] { transition-duration: 2.5s; }

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate { transition-delay: 2.5s; }

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] { transition-duration: 2.55s; }

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate { transition-delay: 2.55s; }

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] { transition-duration: 2.6s; }

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate { transition-delay: 2.6s; }

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] { transition-duration: 2.65s; }

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate { transition-delay: 2.65s; }

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] { transition-duration: 2.7s; }

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate { transition-delay: 2.7s; }

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] { transition-duration: 2.75s; }

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate { transition-delay: 2.75s; }

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] { transition-duration: 2.8s; }

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate { transition-delay: 2.8s; }

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] { transition-duration: 2.85s; }

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate { transition-delay: 2.85s; }

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] { transition-duration: 2.9s; }

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate { transition-delay: 2.9s; }

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] { transition-duration: 2.95s; }

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate { transition-delay: 2.95s; }

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] { transition-duration: 3s; }

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate { transition-delay: 3s; }

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] { transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] { transition-timing-function: ease; }

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] { transition-timing-function: ease-in; }

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] { transition-timing-function: ease-out; }

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] { transition-timing-function: ease-in-out; }

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] { transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] { transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] { transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] { transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] { transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] { transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] { transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] { transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] { transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] { transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] { transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] { transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] { transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] { transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] { transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos^=fade][data-aos^=fade] { opacity: 0; transition-property: opacity,transform; }

[data-aos^=fade][data-aos^=fade].aos-animate { opacity: 1; transform: translateZ(0); }

[data-aos=fade-up] { transform: translate3d(0, 100px, 0); }

[data-aos=fade-down] { transform: translate3d(0, -100px, 0); }

[data-aos=fade-right] { transform: translate3d(-100px, 0, 0); }

[data-aos=fade-left] { transform: translate3d(100px, 0, 0); }

[data-aos=fade-up-right] { transform: translate3d(-100px, 100px, 0); }

[data-aos=fade-up-left] { transform: translate3d(100px, 100px, 0); }

[data-aos=fade-down-right] { transform: translate3d(-100px, -100px, 0); }

[data-aos=fade-down-left] { transform: translate3d(100px, -100px, 0); }

[data-aos^=zoom][data-aos^=zoom] { opacity: 0; transition-property: opacity,transform; }

[data-aos^=zoom][data-aos^=zoom].aos-animate { opacity: 1; transform: translateZ(0) scale(1); }

[data-aos=zoom-in] { transform: scale(0.6); }

[data-aos=zoom-in-up] { transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos=zoom-in-down] { transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos=zoom-in-right] { transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos=zoom-in-left] { transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos=zoom-out] { transform: scale(1.2); }

[data-aos=zoom-out-up] { transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos=zoom-out-down] { transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos=zoom-out-right] { transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos=zoom-out-left] { transform: translate3d(100px, 0, 0) scale(1.2); }

[data-aos^=slide][data-aos^=slide] { transition-property: transform; }

[data-aos^=slide][data-aos^=slide].aos-animate { transform: translateZ(0); }

[data-aos=slide-up] { transform: translate3d(0, 100%, 0); }

[data-aos=slide-down] { transform: translate3d(0, -100%, 0); }

[data-aos=slide-right] { transform: translate3d(-100%, 0, 0); }

[data-aos=slide-left] { transform: translate3d(100%, 0, 0); }

[data-aos^=flip][data-aos^=flip] { backface-visibility: hidden; transition-property: transform; }

[data-aos=flip-left] { transform: perspective(2500px) rotateY(-100deg); }

[data-aos=flip-left].aos-animate { transform: perspective(2500px) rotateY(0); }

[data-aos=flip-right] { transform: perspective(2500px) rotateY(100deg); }

[data-aos=flip-right].aos-animate { transform: perspective(2500px) rotateY(0); }

[data-aos=flip-up] { transform: perspective(2500px) rotateX(-100deg); }

[data-aos=flip-up].aos-animate { transform: perspective(2500px) rotateX(0); }

[data-aos=flip-down] { transform: perspective(2500px) rotateX(100deg); }

[data-aos=flip-down].aos-animate { transform: perspective(2500px) rotateX(0); }

@font-face { font-family: 'SF Pro Display'; src: url("fonts/SFProDisplay-Regular.woff") format("woff"), url("fonts/fonts/SFProDisplay-Regular.woff2") format("woff2"); font-weight: 400; font-style: normal; }

@font-face { font-family: 'SF Pro Display'; src: url("fonts/SFProDisplay-Semibold.woff") format("woff"), url("fonts/fonts/SFProDisplay-Semibold.woff2") format("woff2"); font-weight: 600; font-style: normal; }

@font-face { font-family: 'SF Pro Display'; src: url("fonts/SFProDisplay-Bold.woff") format("woff"), url("fonts/fonts/SFProDisplay-Bold.woff2") format("woff2"); font-weight: 700; font-style: normal; }

html.no-scroll { overflow: hidden; }

body { min-width: 375px; font-family: 'SF Pro Display', sans-serif; font-size: 18px; line-height: 1.35; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; color: #232340; }

@media only screen and (max-width: 1279px) { body { font-size: 16px; } }

body.no-scroll { overflow: hidden; }

button, input, textarea, select { font-family: 'SF Pro Display', sans-serif; }

.page { overflow: hidden; }

.center { max-width: 1384px; margin: 0 auto; padding: 0 107px; }

@media only screen and (max-width: 1279px) { .center { padding: 0 100px; } }

@media only screen and (max-width: 1023px) { .center { padding: 0 50px; } }

@media only screen and (max-width: 767px) { .center { padding: 0 32px; } }

.h1, .h2, .h3, .h4, .h5, .h6 { font-weight: 600; }

.h1 { font-size: 72px; line-height: 1.16667; letter-spacing: -.5px; }

@media only screen and (max-width: 1279px) { .h1 { font-size: 56px; line-height: 1.28571; letter-spacing: -.4px; } }

@media only screen and (max-width: 767px) { .h1 { font-size: 42px; line-height: 1.33333; letter-spacing: -.1px; } }

.h2 { font-size: 56px; line-height: 1.28571; letter-spacing: -.4px; }

.h3 { font-size: 42px; line-height: 1.33333; letter-spacing: -.1px; }

@media only screen and (max-width: 1279px) { .h3 { font-size: 32px; line-height: 1.5; letter-spacing: -.15px; } }

@media only screen and (max-width: 767px) { .h3 { font-size: 20px; line-height: 1.4; } }

.h4 { font-size: 32px; line-height: 1.5; letter-spacing: -.15px; }

@media only screen and (max-width: 1279px) { .h4 { font-size: 24px; line-height: 1.33333; } }

.h5 { font-size: 24px; line-height: 1.33333; }

@media only screen and (max-width: 1279px) { .h5 { font-size: 20px; line-height: 1.4; } }

.h6 { font-size: 20px; line-height: 1.4; }

.btn { position: relative; min-width: 180px; height: 64px; border-radius: 8px; font-family: 'SF Pro Display', sans-serif; border-width: 2px; border-style: solid; overflow: hidden; font-size: 18px; font-weight: 700; transition: all .35s; }

@media only screen and (max-width: 1279px) { .btn { height: 56px; font-size: 16px; } }

@media only screen and (max-width: 767px) { .btn { min-width: 160px; } }

.btn:before { content: ""; position: absolute; left: 0; right: 0; bottom: 0; z-index: -1; height: 300%; border-radius: 50% 50% 0 0/75% 75% 0 0; transition: height .35s; }

.btn:hover:before { height: 0; }

.btn_dark { border-color: #232340; color: #ffffff; }

.btn_dark:before { background: #232340; }

.btn_dark:hover { color: #232340; }

.btn_primary { border-color: #F50245; color: #ffffff; }

.btn_primary:before { background: #F50245; }

.btn_primary:hover { color: #F50245; }

.btn_white { border-color: #ffffff; color: #F50245; }

.btn_white:before { background: #ffffff; }

.btn_white:hover { color: #ffffff; }

.btn [href] { display: inline-flex; justify-content: center; align-items: center; }

.title { font-size: 84px; font-weight: 700; line-height: 1.19048; letter-spacing: -1px; }

@media only screen and (max-width: 1279px) { .title { font-size: 64px; line-height: 1.125; letter-spacing: -.5px; } }

@media only screen and (max-width: 767px) { .title { font-size: 48px; line-height: 1.16667; } }

.title__color { color: #F50245; }

.title_big { font-size: 110px; line-height: 1.19091; letter-spacing: -1.2px; }

@media only screen and (max-width: 1279px) { .title_big { font-size: 76px; line-height: 1.21053; letter-spacing: -.5px; } }

.main { position: relative; padding: 238px 0 120px; }

@media only screen and (max-width: 1279px) { .main { padding: 169px 0 115px; } }

@media only screen and (max-width: 767px) { .main { padding: 118px 0 310px; } }

.main__wrap { max-width: 470px; }

@media only screen and (max-width: 767px) { .main__wrap { max-width: 100%; } }

.main__title { margin-bottom: 40px; }

@media only screen and (max-width: 1279px) { .main__title { margin-bottom: 24px; } }

.main__title p { display: inline-flex; overflow: hidden; }

.main__title p span { display: block; }

.main__title p span:not(:last-child) { margin-right: 18px; }

.main__title[data-aos] p span { transform: translateY(105%); transition: transform .8s; }

.main__title[data-aos].aos-animate p span { transform: translateY(0); }

.main__title[data-aos].aos-animate p:first-child span:first-child { transition-delay: .2s; }

.main__title[data-aos].aos-animate p:first-child span:nth-child(2) { transition-delay: .4s; }

.main__title[data-aos].aos-animate p:nth-child(2) span:first-child { transition-delay: .6s; }

.main__title[data-aos].aos-animate p:nth-child(2) span:nth-child(2) { transition-delay: .8s; }

.main__title[data-aos].aos-animate p:nth-child(3) span:first-child { transition-delay: 1s; }

.main__info { max-width: 370px; margin-bottom: 48px; line-height: 1.77778; color: #606060; }

@media only screen and (max-width: 1279px) { .main__info { max-width: 265px; margin-bottom: 32px; } }

@media only screen and (max-width: 767px) { .main__info { max-width: 100%; margin-bottom: 24px; } }

.main__btn { margin-bottom: 85px; }

@media only screen and (max-width: 1279px) { .main__btn { margin-bottom: 56px; } }

@media only screen and (max-width: 1023px) { .main__btn { margin-bottom: 32px; } }

.main__scroll { display: inline-flex; align-items: center; font-weight: 600; color: #8B8B8B; }

@media only screen and (max-width: 767px) { .main__scroll { display: none; } }

.main__icon { display: flex; justify-content: center; align-items: center; flex-shrink: 0; width: 48px; height: 48px; margin-right: 16px; border-radius: 50%; background: #4C40F7; overflow: hidden; font-size: 0; }

@media only screen and (max-width: 1279px) { .main__icon { width: 40px; height: 40px; } }

.main__arrows { display: flex; flex-direction: column; transform: translateY(-43px); transition: transform .35s; }

.main__arrows .main__pic:not(:last-child) { margin-bottom: 64px; }

.main__scroll:hover .main__arrows { animation: arrowsDown .5s forwards; }

.main__bg { position: absolute; top: 0; right: calc(50% - 611px); z-index: -1; width: 40%; font-size: 0; }

@media only screen and (max-width: 1279px) { .main__bg { top: 210px; right: calc(50% - 453px); width: 700px; } }

@media only screen and (max-width: 1023px) { .main__bg { top: 230px; width: 600px; } }

@media only screen and (max-width: 767px) { .main__bg { top: auto; right: calc(50% - 203px); bottom: 56px; width: 400px; } }

.main__bg[data-aos] .main__preview { opacity: 0; transition: all .6s; }

.main__bg[data-aos] .main__preview:nth-child(1) { transform: translateY(60px); }

.main__bg[data-aos] .main__preview:nth-child(2) { transform: translateY(-40px) scaleY(1.3); transition-delay: .2s; }

.main__bg[data-aos] .main__preview:nth-child(3) { transform: translateY(60px); transition-delay: .4s; }

.main__bg[data-aos] .main__preview:nth-child(4) { transform: translateY(60px); transition-delay: .6s; }

.main__bg[data-aos] .main__preview:nth-child(5) { transform: translateX(50px); transition-delay: .8s; }

.main__bg[data-aos] .main__preview:nth-child(6) { transform: translateX(50px) skewX(10deg); transition-delay: 1s; }

.main__bg[data-aos].aos-animate .main__preview { opacity: 1; }

.main__bg[data-aos].aos-animate .main__preview:first-child { transform: translateY(0); }

.main__bg[data-aos].aos-animate .main__preview:nth-child(2) { transform: translateY(0) scaleY(1); }

.main__bg[data-aos].aos-animate .main__preview:nth-child(3) { transform: translateY(0); }

.main__bg[data-aos].aos-animate .main__preview:nth-child(4) { transform: translateY(0); }

.main__bg[data-aos].aos-animate .main__preview:nth-child(5) { transform: translateX(0); }

.main__bg[data-aos].aos-animate .main__preview:nth-child(6) { transform: translateX(0) skewX(0); }

.main__bg .main__pic { width: 100%; }

@media only screen and (max-width: 767px) { .main_bg { display: flex; background: #232340; } }

.main__preview:first-child { position: relative; top: 200px; z-index: 3; }

@media only screen and (max-width: 767px) { .main__preview:first-child { left: 8%; top: 50px; max-width: 80%; } }

@media only screen and (max-width: 1023px) { .main__preview:first-child { top: 50px; max-width: 80%; } }

.main__preview:nth-child(2) { top: 0%; right: -30%; z-index: -4; width: 150%; }

@media only screen and (max-width: 767px) { .main__preview:nth-child(2) { display: none; } }

@media only screen and (max-width: 1023px) { .main__preview:nth-child(2) { top: -50%; } }

@media only screen and (max-width: 1920px) { .main__preview:nth-child(2) { right: -50%; } }

.main__preview:nth-child(3) { top: 35.7%; right: 9.6%; z-index: 5; width: 23.5%; }

.main__preview:nth-child(4) { top: 50.5%; left: 12.8%; z-index: 5; width: 40.7%; }

.main__preview:nth-child(5) { top: -9.4%; right: 2.8%; z-index: 1; width: 60.7%; }

@media only screen and (max-width: 767px) { .main__preview:nth-child(5) { right: -2%; } }

.main__preview:nth-child(6) { top: 5.5%; right: 12%; z-index: 2; width: 27.5%; }

@media only screen and (max-width: 767px) { .main__preview:nth-child(6) { top: 10%; right: 8%; } }

.main__preview:not(:first-child) { position: absolute; }

.main > .line { top: 0; left: calc(50% - 395px); }

@media only screen and (max-width: 1279px) { .main > .line { top: -110px; left: calc(50% - 365px); } }

@media only screen and (max-width: 1023px) { .main > .line { left: 100px; } }

@media only screen and (max-width: 767px) { .main > .line { top: -160px; left: 75px; } }

.main > .line path { animation: dash 2.5s .8s linear forwards; }

.line { position: absolute; z-index: -5; pointer-events: none; font-size: 0; }

@media only screen and (max-width: 1279px) { .line { transform: scale(0.75); } }

@media only screen and (max-width: 767px) { .line { transform: scale(0.63); } }

.line path { stroke-dasharray: 2173; stroke-dashoffset: 2173; }

@media only screen and (max-width: 1279px) { .line path { width: 456px; } }

[data-aos="animation-line"] path { animation: none; }

[data-aos="animation-line"].aos-animate path { animation: dash 3s linear forwards; }

@keyframes dash { to { stroke-dashoffset: 0; } }

@keyframes arrowsDown { 100% { transform: translateY(43px); } }

.bg { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -5; background: #232340; }

.bg__preview { position: absolute; top: -40px; right: -100px; font-size: 0; }

@media only screen and (max-width: 767px) { .bg__preview { display: none; } }

@media only screen and (max-width: 1279px) { .bg__preview .bg__pic { width: 570px; } }

@media only screen and (max-width: 1023px) { .bg__preview .bg__pic { width: 470px; } }

.stage { font-weight: 600; color: #F50245; }

@media only screen and (max-width: 767px) { .stage { font-size: 14px; } }

.stage__line { display: inline-block; }

.stage__number { display: inline-block; width: 45px; }

.stage__logo { position: absolute; top: 0; left: 0; font-size: 0; }

@media only screen and (max-width: 1023px) { .stage__logo { display: none; } }

.stage__logo .stage__pic { width: 24px; }

.stagenew { position: relative; margin-bottom: 24px; padding-left: 68px; font-size: 20px; line-height: 1.4; font-weight: 600; color: #F50245; }

@media only screen and (max-width: 1279px) { .stagenew { margin-bottom: 16px; font-size: 16px; } }

.stagenew:before { content: ""; position: absolute; top: 15px; left: 0; width: 52px; height: 2px; background: #F50245; }

@media only screen and (max-width: 1279px) { .stagenew:before { top: 10px; } }

.stagenew_pink { color: #FFC7DF; }

.stagenew_pink:before { background: #FFC7DF; }

.number { position: relative; display: inline-block; font-size: 200px; font-weight: 600; line-height: 1.2; }

@media only screen and (max-width: 1279px) { .number { font-size: 140px; } }

@media only screen and (max-width: 1023px) { .number { font-size: 110px; } }

@media only screen and (max-width: 767px) { .number { font-size: 140px; } }

.number__plus { position: absolute; top: 25px; left: 100%; font-size: 64px; line-height: 1.125; }

@media only screen and (max-width: 1279px) { .number__plus { top: 17px; font-size: 46px; } }

.number[data-aos] span { display: inline-block; opacity: 0; transition: all .7s; }

.number[data-aos] span:first-child { transform: translateY(70px); }

.number[data-aos] span:nth-child(2) { transform: translateY(-70px); }

.number[data-aos].aos-animate span { opacity: 1; transform: translateY(0); }

.arrows { display: flex; flex-shrink: 0; }

.arrows__prev, .arrows__next { width: 64px; height: 64px; flex-shrink: 0; border-radius: 50%; font-size: 0; transition: all .25s; }

@media only screen and (max-width: 1279px) { .arrows__prev, .arrows__next { width: 48px; height: 48px; } }

.arrows__prev .icon, .arrows__next .icon { font-size: 16px; fill: #232340; transition: fill .25s; }

.arrows__prev:hover, .arrows__next:hover { background: #F50245; }

.arrows__prev:hover .icon, .arrows__next:hover .icon { fill: #ffffff; }

.arrows__prev[href], .arrows__next[href] { display: flex; justify-content: center; align-items: center; }

.arrows__prev:not(:last-child), .arrows__next:not(:last-child) { margin-right: 16px; }

.arrows_white .arrows__prev .icon, .arrows_white .arrows__next .icon { fill: #ffffff; }

.head { position: relative; display: flex; padding-left: 150px; }

@media only screen and (max-width: 1279px) { .head { padding-left: 140px; } }

@media only screen and (max-width: 1023px) { .head { padding: 0; } }

@media only screen and (max-width: 767px) { .head { display: block; } }

.head__box { flex-grow: 1; }

.head__stage { margin-bottom: 24px; }

@media only screen and (max-width: 474px) { .head__title { max-width: 240px; } }

.head__arrows { margin-left: 150px; padding-top: 48px; }

@media only screen and (max-width: 1279px) { .head__arrows { margin-left: 95px; } }

@media only screen and (max-width: 767px) { .head__arrows { margin: 0; padding-top: 32px; } }

.head__text { max-width: 467px; margin-top: 40px; line-height: 1.77778; color: #606060; }

@media only screen and (max-width: 1279px) { .head__text { max-width: 330px; } }

@media only screen and (max-width: 767px) { .head__text { max-width: 100%; margin-top: 16px; } }

.head__btns { margin-left: 100px; padding-top: 50px; flex-shrink: 0; }

@media only screen and (max-width: 1279px) { .head__btns { padding-top: 63px; margin-left: 70px; } }

@media only screen and (max-width: 767px) { .head__btns { display: none; } }

.head__btn { min-width: 166px; }

@media only screen and (max-width: 1279px) { .head__btn { min-width: 120px; height: 45px; } }

.head__select { display: none; position: absolute; right: 0; bottom: 0; width: 144px; height: 48px; padding: 0 60px 0 24px; -webkit-appearance: none; -moz-appearance: none; appearance: none; border: none; border-radius: 0; background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none'%3E%3Cpath fill-rule='evenodd' d='M.293.293a1 1 0 0 1 1.414 0L5 3.586 8.293.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z' fill='%23fff'/%3E%3C/svg%3E") no-repeat calc(100% - 18px) 50%/10px 6px; font-family: 'SF Pro Display', sans-serif; font-size: 14px; font-weight: 600; color: #ffffff; }

.head__select::-ms-expand { display: none; }

.head__select:-moz-focusring { color: transparent; text-shadow: 0 0 0 #000; }

@media only screen and (max-width: 767px) { .head__select { display: block; } }

.head__wrapper { position: fixed; top: 0; left: 0; right: 0; bottom: 0; display: flex; align-items: center; z-index: 10; background: #ffffff; visibility: hidden; opacity: 0; transition: all .25s; }

@media only screen and (max-width: 767px) { .head__wrapper { display: block; } }

@media only screen and (max-width: 639px) { .head__wrapper { visibility: visible; opacity: 1; transform: translateX(100%); background: #ffffff url("../img/bg-header-mobile.png") no-repeat 100% 150%/100% auto; } }

.head__wrapper.visible { visibility: visible; opacity: 1; }

@media only screen and (max-width: 639px) { .head__wrapper.visible { transform: translateX(0); } }

.details { position: relative; padding: 128px 0 129px; color: #ffffff; }

@media only screen and (max-width: 1023px) { .details { padding: 100px 0; } }

@media only screen and (max-width: 767px) { .details { padding: 64px 0; } }

.details__head { margin-bottom: 93px; }

@media only screen and (max-width: 1279px) { .details__head { margin-bottom: 70px; } }

@media only screen and (max-width: 767px) { .details__head { margin-bottom: 56px; } }

.details .stage { color: #ffffff; }

.details .stage__number { color: #8B8B8B; }

.details__body { display: flex; margin-bottom: 128px; }

@media only screen and (max-width: 1365px) { .details__body { align-items: center; } }

@media only screen and (max-width: 1279px) { .details__body { margin-bottom: 85px; } }

@media only screen and (max-width: 767px) { .details__body { display: block; margin-bottom: 56px; } }

.details__years { position: relative; z-index: 2; display: flex; flex-direction: column; align-items: flex-start; flex-shrink: 0; width: 200px; padding-top: 35px; }

@media only screen and (max-width: 1279px) { .details__years { width: 140px; padding: 0; } }

@media only screen and (max-width: 1023px) { .details__years { width: 80px; } }

@media only screen and (max-width: 767px) { .details__years { display: none; } }

.details__year { position: relative; width: 45px; font-weight: 600; color: #8B8B8B; cursor: pointer; transition: color .25s; }

@media only screen and (max-width: 1279px) { .details__year { font-size: 14px; } }

.details__year:before { content: ""; position: absolute; top: 50%; right: calc(100% + 62px); transform: translateY(-50%); width: 0; height: 4px; background: #ffffff; border-radius: 1px; transition: width .25s; pointer-events: none; }

@media only screen and (max-width: 1279px) { .details__year:before { right: calc(100% + 45px); height: 3px; } }

@media only screen and (max-width: 1023px) { .details__year:before { right: calc(100% + 15px); } }

.details__year.active { color: #ffffff; }

.details__year.active:before { width: 24px; }

@media only screen and (max-width: 1279px) { .details__year.active:before { width: 17px; } }

.details__year:not(:last-child) { margin-bottom: 102px; }

@media only screen and (max-width: 1365px) { .details__year:not(:last-child) { margin-bottom: 80px; } }

@media only screen and (max-width: 1279px) { .details__year:not(:last-child) { margin-bottom: 65px; } }

@media only screen and (max-width: 1023px) { .details__year:not(:last-child) { margin-bottom: 50px; } }

.details__line { position: absolute; width: 0px; height: 1px; top: 50%; left: calc(100% + 23px); transform: translateY(-50%); background: #ffffff; opacity: 0; transition: all .25s; pointer-events: none; }

@media only screen and (max-width: 1279px) { .details__line { left: calc(100% + 5px); } }

.details__line:before { content: ""; position: absolute; top: -2px; left: -2px; width: 5px; height: 5px; border-radius: 50%; background: #ffffff; }

.details__year.active .details__line { width: 187px; opacity: 1; }

@media only screen and (max-width: 1279px) { .details__year.active .details__line { width: 145px; } }

@media only screen and (max-width: 1023px) { .details__year.active .details__line { width: 60px; } }

.details__dot { position: absolute; top: -7px; right: -7px; width: 15px; height: 15px; border-radius: 50%; background: #ffffff; }

.details__dot:before { content: ""; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 7px; height: 7px; background: #ffffff; border-radius: 50%; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.30171); }

.details__inner { display: flex; flex-direction: row; margin: 0 -15px; }

@media only screen and (max-width: 767px) { .details__inner { margin: 0; } }

.details__slider { visibility: hidden; }

.details__slider.slick-initialized { visibility: visible; }

.details__preview { margin: 10px; font-size: 0; }

@media only screen and (max-width: 1279px) { .details__preview { margin: 0 15px; } }

.details__pic { width: 100%; }

.details__foot { display: flex; align-items: center; padding-left: 100px; }

@media only screen and (max-width: 1279px) { .details__foot { padding-left: 140px; } }

@media only screen and (max-width: 1023px) { .details__foot { padding-left: 30px; } }

@media only screen and (max-width: 767px) { .details__foot { display: block; padding: 0; } }

.details__wrap { position: relative; flex-grow: 1; }

@media only screen and (max-width: 767px) { .details__wrap { margin-bottom: 56px; } }

.details__wrap:before { content: ""; position: absolute; top: 0; left: -50px; width: 2px; height: 80px; border-radius: 1px; background: #F50245; }

@media only screen and (max-width: 1279px) { .details__wrap:before { left: -140px; height: 56px; } }

@media only screen and (max-width: 1023px) { .details__wrap:before { left: -30px; } }

@media only screen and (max-width: 767px) { .details__wrap:before { display: none; } }

.details__info { margin-bottom: 40px; }

.details__wrap .details__text { margin-bottom: 40px; line-height: 1.77778; color: #8B8B8B; }

@media only screen and (max-width: 1279px) { .details__wrap .details__text { margin-bottom: 32px; } }

.details__counter { flex-shrink: 0; margin-left: 100px; perspective: 600px; }

@media only screen and (max-width: 1279px) { .details__counter { margin-left: 80px; } }

@media only screen and (max-width: 1023px) { .details__counter { margin-left: 60px; } }

@media only screen and (max-width: 767px) { .details__counter { margin: 0 auto; } }

.details__counter[data-aos].aos-animate .details__front { transform: rotateX(-180deg); z-index: 1; }

.details__counter[data-aos].aos-animate .details__back { transform: rotate(0); z-index: 2; }

.details__front, .details__back { -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; backface-visibility: hidden; border-radius: 50%; overflow: hidden; transition: transform .75s; transform-style: preserve-3d; }

.details__front { position: relative; z-index: 2; }

.details__back { position: relative; z-index: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; width: 402px; height: 402px; transform: rotateX(180deg); border-radius: 50%; box-shadow: 0px 23px 51px rgba(0, 0, 0, 0.242297); }

@media only screen and (max-width: 1279px) { .details__back { width: 283px; height: 283px; } }

@media only screen and (max-width: 1023px) { .details__back { width: 240px; height: 240px; } }

@media only screen and (max-width: 767px) { .details__back { width: 310px; height: 310px; } }

.details__number { margin-top: -33px; }

.details__category { margin-top: -35px; }

@media only screen and (max-width: 1279px) { .details__category { margin-top: -20px; font-size: 18px; line-height: 1.33333; } }

@media only screen and (max-width: 1023px) { .details__category { margin-top: 0; } }

@media only screen and (max-width: 767px) { .details__category { margin-top: -10px; } }

.details .line { top: -138px; left: 50px; transform: rotate(-90deg); }

@media only screen and (max-width: 1279px) { .details .line { top: -220px; left: -30px; transform: rotate(-90deg) scale(0.7); } }

@media only screen and (max-width: 767px) { .details .line { display: none; } }

.details .slick-slide { padding: 0 15px; }

.wrapperbase { position: relative; padding: 152px 0 130px; background: linear-gradient(360deg, rgba(255, 255, 255, 0.0001) 0%, rgba(237, 229, 240, 0.504289) 100%); }

@media only screen and (max-width: 1279px) { .wrapperbase { padding: 112px 0 71px; } }

@media only screen and (max-width: 1023px) { .wrapperbase { padding-top: 90px; } }

@media only screen and (max-width: 767px) { .wrapperbase { margin: 0; padding: 56px 0 56px; } }

.wrapperbase .lines { position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: .3; }

.wrapperbase .line:first-child { top: 0; left: calc(50% - 610px); }

@media only screen and (max-width: 1279px) { .wrapperbase .line:first-child { top: -103px; left: calc(50% - 480px); } }

@media only screen and (max-width: 1023px) { .wrapperbase .line:first-child { left: 0; } }

@media only screen and (max-width: 767px) { .wrapperbase .line:first-child { top: -152px; } }

.wrapperbase .line:nth-child(2) { top: 770px; right: 0; }

@media only screen and (max-width: 1279px) { .wrapperbase .line:nth-child(2) { top: 570px; right: -100px; } }

@media only screen and (max-width: 1023px) { .wrapperbase .line:nth-child(2) { top: 530px; } }

.work__head { margin-bottom: 170px; }

@media only screen and (max-width: 1279px) { .work__head { margin-bottom: 92px; } }

@media only screen and (max-width: 767px) { .work__head { margin-bottom: 44px; } }

.work__container { position: relative; margin-top: -35px; background: #ffffff; border-radius: 48px; }

@media only screen and (max-width: 1279px) { .work__container { border-radius: 32px; } }

@media only screen and (max-width: 767px) { .work__container { margin-top: 0; background: none; } }

.work__container:before, .work__container:after { content: ""; position: absolute; left: 0; right: 0; border-radius: 48px; }

@media only screen and (max-width: 767px) { .work__container:before, .work__container:after { display: none; } }

.work__container:before { top: 8px; bottom: -8px; background: rgba(255, 255, 255, 0.5); }

.work__container:after { top: 64px; bottom: -64px; z-index: -2; filter: blur(130.478px); background: #EBE6ED; }

@media only screen and (max-width: 1279px) { .work__container:after { top: 46px; bottom: -46px; border-radius: 32px; filter: blur(92px); } }

.work__slider { visibility: hidden; }

.work__slider.slick-initialized { visibility: visible; }

@media only screen and (max-width: 767px) { .work__slide { margin: 12px 0; } }

.work__item { position: relative; min-height: 480px; padding: 140px 30px 60px; text-align: center; }

@media only screen and (max-width: 1279px) { .work__item { min-height: 340px; padding: 100px 20px 40px; } }

@media only screen and (max-width: 767px) { .work__item { display: flex; align-items: center; min-height: auto; padding: 48px; background: #ffffff; border-radius: 32px; } }

.work__item:before { content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: #ffffff; border-radius: 38px; transition: all .25s; }

@media only screen and (max-width: 1279px) { .work__item:before { border-radius: 32px; } }

@media only screen and (max-width: 767px) { .work__item:before { top: 8px; bottom: -8px; opacity: .5; } }

.work__item:hover:before { transform: scaleY(1.16); background: #4C40F7; }

@media only screen and (max-width: 1279px) { .work__item:hover:before { transform: scaleY(1.1); } }

.work__preview { position: relative; z-index: 2; display: inline-block; margin-bottom: 35px; font-size: 0; }

@media only screen and (max-width: 1279px) { .work__preview { margin-bottom: 28px; } }

@media only screen and (max-width: 767px) { .work__preview { margin: 0 24px 0 0; } }

.work__pic { width: 128px; }

@media only screen and (max-width: 1279px) { .work__pic { width: 92px; } }

.work__category { position: relative; z-index: 2; color: #F50245; transition: color .25s; }

.work__item:hover .work__category { color: #ffffff; }

.work .slick-list { overflow: visible; border-radius: 48px; }

@media only screen and (max-width: 1279px) { .work .slick-list { border-radius: 32px; } }

@media only screen and (max-width: 767px) { .work .slick-list { overflow: hidden; } }

.work .slick-slide { visibility: hidden; opacity: 0; transition: all .25s; }

.work .slick-slide.slick-active { visibility: visible; opacity: 1; }

.achievement__center { display: flex; }

@media only screen and (max-width: 767px) { .achievement__center { flex-direction: column-reverse; } }

.achievement__container { flex-shrink: 0; width: 467px; }

@media only screen and (max-width: 1279px) { .achievement__container { width: 330px; } }

@media only screen and (max-width: 767px) { .achievement__container { width: 100%; max-width: 375px; margin: 0 auto; } }

.achievement__slider { visibility: hidden; }

.achievement__slider.slick-initialized { visibility: visible; }

.achievement__slide { text-align: center; }

.achievement__group { position: relative; display: inline-block; width: 60%; font-size: 0; }

.achievement__preview:first-child { position: relative; z-index: 2; display: inline-block; width: 100%; }

.achievement__preview:nth-child(2) { top: 70px; right: -33%; left: -33%; width: auto; }

.achievement__preview:nth-child(3) { top: -12%; right: -30%; z-index: 3; width: 73%; }

.achievement__preview:not(:first-child) { position: absolute; }

.achievement__pic { width: 100%; }

.achievement__wrap { position: relative; z-index: 2; padding: 35px 0 0 135px; }

@media only screen and (max-width: 1279px) { .achievement__wrap { padding: 10px 0 0 100px; } }

@media only screen and (max-width: 1023px) { .achievement__wrap { padding: 0 0 0 40px; } }

@media only screen and (max-width: 767px) { .achievement__wrap { margin-bottom: 60px; padding: 0; } }

.achievement__stage { position: relative; margin-bottom: 40px; padding-left: 100px; color: #232340; }

@media only screen and (max-width: 1023px) { .achievement__stage { margin-bottom: 32px; padding: 0; } }

@media only screen and (max-width: 767px) { .achievement__stage { margin-bottom: 24px; } }

.achievement__stage .stage__number { color: #8B8B8B; }

.achievement__title { margin-bottom: 80px; }

@media only screen and (max-width: 1023px) { .achievement__title { margin-bottom: 50px; } }

@media only screen and (max-width: 767px) { .achievement__title { margin-bottom: 32px; } }

@media only screen and (max-width: 474px) { .achievement__title { max-width: 230px; } }

.achievement__number { color: #F50245; }

.achievement__info span { margin-right: 20px; }

.achievement .slick-list { overflow: visible; }

.achievement .slick-slide { visibility: hidden; opacity: 0; transition: all .25s; }

.achievement .slick-slide.slick-active { visibility: visible; opacity: 1; }

.achievement .slick-dots { margin-top: 87px; }

@media only screen and (max-width: 1279px) { .achievement .slick-dots { margin-top: 55px; } }

@media only screen and (max-width: 1023px) { .achievement .slick-dots { margin-top: 24px; } }

.slick-prev, .slick-next { width: 64px; height: 64px; border-radius: 50%; background: #F50245; font-size: 0; transition: opacity .25s; }

@media only screen and (max-width: 1279px) { .slick-prev, .slick-next { width: 48px; height: 48px; } }

.slick-prev .icon, .slick-next .icon { font-size: 16px; fill: #ffffff; transition: fill .25s; }

.slick-prev:hover, .slick-next:hover { opacity: .85; }

.slick-dots { position: relative; z-index: 3; display: flex; justify-content: center; }

.slick-dots li button { position: relative; width: 24px; height: 24px; font-size: 0; }

.slick-dots li button:before { content: ""; position: absolute; top: 50%; left: 50%; width: 8px; height: 8px; transform: translate(-50%, -50%); border-radius: 50%; background: #ffffff; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.30171); transition: all .25s; }

.slick-dots li.slick-active button:before { box-shadow: none; background: linear-gradient(180deg, #3A8FFF 0%, #0837AE 100%); }

.slick-dots li:not(:last-child) { margin-right: 24px; }

@media only screen and (max-width: 1279px) { .slick-dots li:not(:last-child) { margin-right: 18px; } }

@media only screen and (max-width: 767px) { .slick-dots li:not(:last-child) { margin-right: 12px; } }

.arrow { display: inline-flex; justify-content: center; align-items: center; width: 64px; height: 64px; border-radius: 50%; overflow: hidden; border: 2px solid #232340; font-size: 0; transition: all .25s; }

@media only screen and (max-width: 1279px) { .arrow { width: 48px; height: 48px; } }

.arrow__line { display: flex; transform: translateX(-43px); transition: transform .35s; }

.arrow__line .icon { font-size: 16px; fill: #232340; transition: fill .25s; }

.arrow__line .icon:not(:last-child) { margin-right: 64px; }

.arrow:hover .arrow__line { animation: arrowsRight .5s forwards; }

.category { display: inline-block; margin-bottom: 24px; padding: 0 23px; border-radius: 20px; background: #908CFF; font-size: 14px; font-weight: 600; line-height: 40px; color: #ffffff; }

@media only screen and (max-width: 1279px) { .category { border-radius: 16px; line-height: 32px; } }

@media only screen and (max-width: 767px) { .category { padding: 0 16px; } }

.projects { position: relative; margin-bottom: 128px; background: url("../img/bg-projects.png") no-repeat 100% 192px/670px auto; }

@media only screen and (max-width: 1279px) { .projects { margin-bottom: 91px; background-position: 110% 140px; background-size: 500px auto; } }

@media only screen and (max-width: 1023px) { .projects { background-position: 120% 80px; } }

@media only screen and (max-width: 767px) { .projects { margin-bottom: 74px; background: none; } }

@media only screen and (max-width: 767px) { .projects__center { max-width: 414px; } }

.projects__head { margin-bottom: 180px; }

@media only screen and (max-width: 1279px) { .projects__head { margin-bottom: 70px; } }

@media only screen and (max-width: 767px) { .projects__head { margin-bottom: 56px; } }

@media only screen and (max-width: 1023px) { .projects__head .head__title { font-size: 60px; } }

@media only screen and (max-width: 767px) { .projects__head .head__title { font-size: 40px; line-height: 1.2; } }

.projects__body { display: flex; margin-bottom: 164px; }

@media only screen and (max-width: 1279px) { .projects__body { margin-bottom: 116px; } }

@media only screen and (max-width: 1023px) { .projects__body { margin-bottom: 80px; } }

@media only screen and (max-width: 767px) { .projects__body { position: relative; flex-direction: column-reverse; margin-bottom: 32px; } }

.projects__desc { position: relative; z-index: 2; flex-shrink: 0; width: 300px; padding-top: 48px; }

@media only screen and (max-width: 1279px) { .projects__desc { width: 225px; padding-right: 10px; } }

@media only screen and (max-width: 767px) { .projects__desc { position: static; width: 100%; padding: 0; } }

.projects__category { margin-bottom: 24px; }

@media only screen and (max-width: 767px) { .projects__category { position: absolute; top: 295px; left: 25px; z-index: 2; margin: 0; } }

.projects__pic { width: 100%; }

.projects__body .projects__preview { flex-shrink: 0; width: 668px; height: 520px; background: #D1ECFD; }

@media only screen and (max-width: 1279px) { .projects__body .projects__preview { width: 470px; height: 370px; } }

@media only screen and (max-width: 1023px) { .projects__body .projects__preview { width: 430px; } }

@media only screen and (max-width: 767px) { .projects__body .projects__preview { width: 100%; height: 356px; margin-bottom: 24px; background: #FFCE79; } }

.projects__body .projects__img { top: 12%; left: 10.3%; width: 83%; }

@media only screen and (max-width: 767px) { .projects__body .projects__img { left: 0; right: 0; width: 100%; } }

.projects__preview { position: relative; border-radius: 24px; font-size: 0; }

@media only screen and (max-width: 1279px) { .projects__preview { border-radius: 16px; } }

.projects__body .projects__info { margin-right: -167px; margin-bottom: 32px; }

@media only screen and (max-width: 1279px) { .projects__body .projects__info { margin-bottom: 24px; } }

@media only screen and (max-width: 767px) { .projects__body .projects__info { margin: 0 0 16px; } }

.projects__text { line-height: 1.77778; color: #606060; }

.projects__body .projects__text { max-width: 205px; }

@media only screen and (max-width: 767px) { .projects__body .projects__text { max-width: 100%; } }

.projects__arrow { position: absolute; right: -32px; }

@media only screen and (max-width: 1279px) { .projects__arrow { right: -24px; } }

@media only screen and (max-width: 767px) { .projects__arrow { position: static; margin-top: 24px; } }

.projects__view { position: absolute; left: 32px; bottom: 32px; display: flex; justify-content: center; align-items: center; width: 72px; height: 72px; border-radius: 50%; background: #232340; font-size: 18px; font-weight: 700; color: #ffffff; transition: all .25s; }

@media only screen and (max-width: 1279px) { .projects__view { display: none; } }

.projects__view:hover { background: #F50245; }

.projects__body .projects__arrow { top: 142px; }

@media only screen and (max-width: 1279px) { .projects__body .projects__arrow { top: 100px; } }

.projects__desc .projects__arrow { display: none; }

@media only screen and (max-width: 767px) { .projects__desc .projects__arrow { display: inline-flex; } }

@media only screen and (max-width: 767px) { .projects__preview .projects__arrow { display: none; } }

@media only screen and (max-width: 767px) { .projects__item { display: flex; flex-direction: column; } }

.projects__item:first-child { max-width: 366px; margin-left: auto; }

@media only screen and (max-width: 1279px) { .projects__item:first-child { max-width: 258px; } }

@media only screen and (max-width: 767px) { .projects__item:first-child { max-width: 100%; } }

.projects__item:first-child .projects__preview { height: 382px; background: #E8BEFF; }

@media only screen and (max-width: 1279px) { .projects__item:first-child .projects__preview { height: 270px; } }

@media only screen and (max-width: 767px) { .projects__item:first-child .projects__preview { height: 355px; } }

.projects__item:first-child .projects__img { top: 9%; right: 27%; width: 128.3%; }

@media only screen and (max-width: 1023px) { .projects__item:first-child .projects__img { right: 5%; } }

@media only screen and (max-width: 767px) { .projects__item:first-child .projects__img { top: 23%; right: 5%; width: 90%; } }

.projects__item:first-child .projects__info, .projects__item:first-child .projects__text { max-width: 366px; margin-left: auto; }

@media only screen and (max-width: 767px) { .projects__item:first-child .projects__info, .projects__item:first-child .projects__text { max-width: 100%; margin: 0; } }

@media only screen and (max-width: 767px) { .projects__item:first-child .projects__info { margin-bottom: 16px; } }

.projects__item:nth-child(2) { max-width: 470px; margin-top: -310px; }

@media only screen and (max-width: 1279px) { .projects__item:nth-child(2) { max-width: 328px; margin-top: -290px; } }

@media only screen and (max-width: 1023px) { .projects__item:nth-child(2) { max-width: 300px; } }

@media only screen and (max-width: 767px) { .projects__item:nth-child(2) { max-width: 100%; margin: 32px 0 0; } }

.projects__item:nth-child(2) .projects__preview { height: 500px; background: #FFCE79; }

@media only screen and (max-width: 1279px) { .projects__item:nth-child(2) .projects__preview { height: 356px; } }

.projects__item:nth-child(2) .projects__img { top: 15%; left: 12%; width: 71%; }

@media only screen and (max-width: 767px) { .projects__item:nth-child(2) .projects__img { top: 18%; } }

.projects__item:nth-child(3) { max-width: 467px; margin: 0 100px 0 auto; }

@media only screen and (max-width: 1365px) { .projects__item:nth-child(3) { margin-right: 50px; } }

@media only screen and (max-width: 1279px) { .projects__item:nth-child(3) { max-width: 328px; } }

@media only screen and (max-width: 1023px) { .projects__item:nth-child(3) { margin-right: 0; } }

@media only screen and (max-width: 767px) { .projects__item:nth-child(3) { margin: 32px 0 0; } }

.projects__item:nth-child(3) .projects__preview { height: 506px; background: #D1ECFD; }

@media only screen and (max-width: 1279px) { .projects__item:nth-child(3) .projects__preview { height: 356px; } }

.projects__item:nth-child(3) .projects__img { top: 11%; left: 13%; width: 72.8%; }

.projects__item:nth-child(4) { max-width: 366px; margin: -366px auto 0 100px; }

@media only screen and (max-width: 1365px) { .projects__item:nth-child(4) { margin-left: 50px; } }

@media only screen and (max-width: 1279px) { .projects__item:nth-child(4) { max-width: 260px; margin-top: -310px; } }

@media only screen and (max-width: 1023px) { .projects__item:nth-child(4) { margin-left: 0; } }

@media only screen and (max-width: 767px) { .projects__item:nth-child(4) { max-width: 100%; margin: 32px 0 0; } }

.projects__item:nth-child(4) .projects__preview { height: 506px; background: #FFADD0; }

@media only screen and (max-width: 1279px) { .projects__item:nth-child(4) .projects__preview { height: 356px; } }

@media only screen and (max-width: 767px) { .projects__item:nth-child(4) .projects__preview { background: #FFCE79; } }

.projects__item:nth-child(4) .projects__img { top: 19%; left: 0; right: 0; width: 100%; }

@media only screen and (max-width: 767px) { .projects__item:nth-child(4) .projects__img { top: 11%; } }

.projects__img { position: absolute; pointer-events: none; }

.projects__item .projects__info { margin-bottom: 40px; }

@media only screen and (max-width: 767px) { .projects__item .projects__info { margin-bottom: 16px; } }

.projects__item .projects__preview { margin-bottom: 40px; }

@media only screen and (max-width: 767px) { .projects__item .projects__preview { order: -1; margin-bottom: 24px; } }

.projects__item .projects__text { max-width: 366px; }

.projects__item .projects__arrow { bottom: 64px; }

@media only screen and (max-width: 1279px) { .projects__item .projects__arrow { bottom: 60px; } }

.projects__item > .projects__arrow { display: none; }

@media only screen and (max-width: 767px) { .projects__item > .projects__arrow { display: inline-flex; } }

.projects__btn { display: none; }

@media only screen and (max-width: 767px) { .projects__btn { display: block; margin-top: 48px; }
  .projects__btn .btn { min-width: 100%; } }

.projects .lines { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; opacity: .3; }

@media only screen and (max-width: 767px) { .projects .lines { display: none; } }

.projects .line:first-child { top: 600px; left: -50px; transform: rotate(-90deg); }

@media only screen and (max-width: 1279px) { .projects .line:first-child { top: 410px; transform: rotate(-90deg) scale(0.7); } }

.projects .line:nth-child(2) { top: 1650px; right: 120px; transform: rotate(-45deg); }

@media only screen and (max-width: 1279px) { .projects .line:nth-child(2) { top: 970px; right: -100px; transform: rotate(-90deg) scale(0.7); } }

.talent { margin-bottom: 158px; }

@media only screen and (max-width: 1279px) { .talent { margin-bottom: 90px; } }

@media only screen and (max-width: 767px) { .talent { margin-bottom: 70px; } }

.talent__container { position: relative; padding: 134px 0; border-radius: 40px; background: #F50245; color: #ffffff; }

@media only screen and (max-width: 1279px) { .talent__container { padding: 80px 0; border-radius: 28px; } }

@media only screen and (max-width: 767px) { .talent__container { padding: 64px 40px 450px; } }

.talent__container:before { content: ""; position: absolute; top: 48px; left: -63px; bottom: 48px; z-index: -1; width: 160px; border-radius: 40px; background: #D1ECFD; }

@media only screen and (max-width: 1279px) { .talent__container:before { left: -55px; border-radius: 28px; } }

@media only screen and (max-width: 1023px) { .talent__container:before { left: -25px; } }

@media only screen and (max-width: 767px) { .talent__container:before { display: none; } }

.talent__figure { position: absolute; top: 48px; right: -64px; width: 490px; font-size: 0; }

@media only screen and (max-width: 1279px) { .talent__figure { top: 24px; width: 358px; } }

@media only screen and (max-width: 1023px) { .talent__figure { top: 60px; right: -30px; width: 300px; } }

@media only screen and (max-width: 767px) { .talent__figure { top: auto; bottom: -30px; right: -32px; width: 270px; } }

.talent__wrap { position: relative; z-index: 3; max-width: 367px; margin-left: 602px; }

@media only screen and (max-width: 1279px) { .talent__wrap { max-width: 260px; margin-left: 52%; } }

@media only screen and (max-width: 767px) { .talent__wrap { max-width: 100%; margin-left: 0; } }

.talent__stage { margin-bottom: 24px; color: #ffffff; }

.talent__title { margin-bottom: 52px; }

@media only screen and (max-width: 1279px) { .talent__title { margin-bottom: 32px; } }

.talent__btn { min-width: 201px; }

@media only screen and (max-width: 1279px) { .talent__btn { min-width: 143px; height: 46px; } }

.talent__bg { position: absolute; top: 16%; left: calc(50% - 415px); width: 720px; pointer-events: none; font-size: 0; }

@media only screen and (max-width: 1279px) { .talent__bg { left: calc(50% - 305px); width: 500px; } }

@media only screen and (max-width: 767px) { .talent__bg { top: auto; left: 12%; bottom: 40px; z-index: 2; width: 450px; } }

.talent__bg[data-aos] .talent__preview { opacity: 0; transition: all .7s; }

.talent__bg[data-aos] .talent__preview:first-child { transform: translateY(60px); }

.talent__bg[data-aos] .talent__preview:nth-child(2) { transform: scale(0.7); transition-delay: .2s; }

.talent__bg[data-aos] .talent__preview:nth-child(3) { transform: translate(40px, -40px) rotate(15deg); transition-delay: .4s; }

.talent__bg[data-aos] .talent__preview:nth-child(4) { transform: translate(-40px, 60px); transition-delay: .6s; }

.talent__bg[data-aos] .talent__preview:nth-child(5) { transform: translateX(-50px) scale(1.4); transition-delay: .8s; }

.talent__bg[data-aos].aos-animate .talent__preview { opacity: 1; }

.talent__bg[data-aos].aos-animate .talent__preview:first-child { transform: translateY(0); }

.talent__bg[data-aos].aos-animate .talent__preview:nth-child(2) { transform: scale(1); }

.talent__bg[data-aos].aos-animate .talent__preview:nth-child(3) { transform: translate(0, 0) rotate(0); }

.talent__bg[data-aos].aos-animate .talent__preview:nth-child(4) { transform: translate(0, 0); }

.talent__bg[data-aos].aos-animate .talent__preview:nth-child(5) { transform: translateX(0) scale(1); }

.talent__preview:nth-child(2) { top: 18.3%; left: 13%; width: 37.5%; }

.talent__preview:nth-child(3) { top: -14%; left: 14.5%; width: 53%; }

.talent__preview:nth-child(4) { left: -16%; bottom: -7.5%; width: 44%; }

.talent__preview:nth-child(5) { top: 7%; left: -10%; width: 40px; }

.talent__preview:not(:first-child) { position: absolute; }

.talent__pic { width: 100%; }

.talent_case { margin-bottom: 129px; }

@media only screen and (max-width: 767px) { .talent_case { position: relative; z-index: 2; margin-bottom: 95px; } }

.jobs { position: relative; padding: 128px 0; background: linear-gradient(360deg, rgba(255, 255, 255, 0.0001) 0%, rgba(237, 229, 240, 0.504289) 100%); }

@media only screen and (max-width: 1279px) { .jobs { padding: 86px 0 82px; } }

@media only screen and (max-width: 767px) { .jobs { padding: 56px 0 48px; } }

.jobs__center { display: flex; }

@media only screen and (max-width: 767px) { .jobs__center { display: block; } }

.jobs__wrap { position: relative; padding: 0 60px 0 100px; flex-grow: 1; }

@media only screen and (max-width: 1023px) { .jobs__wrap { padding: 0 50px 0 0; } }

@media only screen and (max-width: 767px) { .jobs__wrap { margin-bottom: 48px; padding: 0; } }

.jobs__stage { margin-bottom: 24px; }

.jobs__title { margin-bottom: 41px; }

@media only screen and (max-width: 767px) { .jobs__title { margin-bottom: 24px; } }

.jobs__list { flex-shrink: 0; width: 568px; overflow: hidden; }

@media only screen and (max-width: 1279px) { .jobs__list { width: 400px; } }

@media only screen and (max-width: 767px) { .jobs__list { width: 100%; overflow: visible; } }

.jobs__info { max-width: 367px; margin-bottom: 72px; line-height: 1.77778; color: #606060; }

@media only screen and (max-width: 767px) { .jobs__info { max-width: 100%; margin: 0; } }

.jobs__scroll { display: inline-flex; align-items: center; font-weight: 600; line-height: 1.33333; color: #8B8B8B; }

@media only screen and (max-width: 767px) { .jobs__scroll { display: none; } }

.jobs__icon { display: flex; justify-content: center; align-items: center; flex-shrink: 0; width: 48px; height: 48px; margin-right: 16px; border-radius: 50%; background: #4C40F7; overflow: hidden; font-size: 0; }

@media only screen and (max-width: 1279px) { .jobs__icon { width: 40px; height: 40px; } }

.jobs__arrows { display: flex; transform: translateX(-43px); transition: transform .35s; }

.jobs__arrows .jobs__pic:not(:last-child) { margin-right: 64px; }

.jobs__scroll:hover .jobs__arrows { animation: arrowsRight .4s forwards; }

.jobs__item { display: block; margin-bottom: 21px; }

@media only screen and (max-width: 1279px) { .jobs__item { margin-bottom: 16px; } }

.jobs__inner { position: relative; display: flex; flex-direction: column; justify-content: center; align-items: flex-start; height: 247px; padding: 3px 250px 0 64px; border-radius: 40px; box-shadow: 0 5px 20px #EDEDED; background: #ffffff; transition: all .25s; }

@media only screen and (max-width: 1279px) { .jobs__inner { height: 175px; padding: 0 165px 0 32px; border-radius: 28px; } }

@media only screen and (max-width: 767px) { .jobs__inner { padding: 0 40px; } }

.jobs__inner:hover { background: #232340; }

.jobs__inner:hover .icon { fill: #ffffff; }

.jobs__preview { position: absolute; top: 16px; right: 16px; bottom: 16px; display: flex; justify-content: center; align-items: center; width: 215px; padding: 20px; border-radius: 40px; background: #F50245; font-size: 0; visibility: hidden; opacity: 0; transition: all .25s; }

@media only screen and (max-width: 1279px) { .jobs__preview { top: 12px; right: 12px; bottom: 12px; width: 150px; } }

@media only screen and (max-width: 1023px) { .jobs__preview { visibility: visible; opacity: 1; } }

@media only screen and (max-width: 767px) { .jobs__preview { display: none; } }

.jobs__item:hover .jobs__preview { visibility: visible; opacity: 1; }

.jobs__preview .jobs__pic { max-width: 100%; max-height: 100%; }

.jobs__vacancy { position: relative; z-index: 2; display: inline-block; margin-bottom: 4px; color: #232340; transition: color .25s; }

.jobs__item:hover .jobs__vacancy { color: #ffffff; }

.jobs__counter { position: absolute; top: 5px; left: calc(100% + 15px); min-width: 32px; border-radius: 50%; background: linear-gradient(180deg, #3A8FFF 0%, #0837AE 100%); text-align: center; line-height: 32px; font-size: 14px; font-weight: 600; color: #ffffff; }

@media only screen and (max-width: 1279px) { .jobs__counter { min-width: 24px; line-height: 24px; font-size: 13px; } }

.jobs__item .jobs__text { margin-bottom: 30px; line-height: 1.77778; color: #606060; transition: color .25s; }

@media only screen and (max-width: 1279px) { .jobs__item .jobs__text { margin-bottom: 10px; } }

.jobs__item:hover .jobs__text { color: #8B8B8B; }

.jobs .icon { font-size: 16px; fill: #232340; transition: fill .25s; }

.jobs .line { top: 0; left: calc(50% - 395px); opacity: .3; }

@media only screen and (max-width: 1279px) { .jobs .line { top: -103px; left: calc(50% - 335px); } }

@media only screen and (max-width: 767px) { .jobs .line { top: -152px; left: 100px; } }

@keyframes arrowsRight { 100% { transform: translateX(43px); } }

.about__center { max-width: 1440px; padding: 0 72px; }

@media only screen and (max-width: 1279px) { .about__center { padding: 0 49px; } }

.about__container { position: relative; background: #F50245; border-radius: 40px; color: #ffffff; }

@media only screen and (max-width: 1279px) { .about__container { border-radius: 28px; } }

@media only screen and (max-width: 767px) { .about__container { border-radius: 24px; } }

.about__head { position: relative; z-index: 1; padding: 128px 0 176px; border-bottom: 1px solid rgba(255, 255, 255, 0.2); text-align: center; }

@media only screen and (max-width: 1365px) { .about__head { padding-bottom: 140px; } }

@media only screen and (max-width: 1279px) { .about__head { padding: 91px 0 81px; } }

@media only screen and (max-width: 1023px) { .about__head { padding: 50px 0; } }

@media only screen and (max-width: 767px) { .about__head { padding: 48px 40px 40px; } }

.about__logo { margin-bottom: 40px; font-size: 0; }

@media only screen and (max-width: 1023px) { .about__logo { margin-bottom: 32px; } }

@media only screen and (max-width: 767px) { .about__logo { display: none; } }

.about__logo .about__pic { width: 24px; }

.about__hello { margin-bottom: 40px; font-weight: 600; }

@media only screen and (max-width: 1023px) { .about__hello { margin-bottom: 32px; } }

@media only screen and (max-width: 767px) { .about__hello { margin-bottom: 40px; } }

.about__hello span { margin-right: 12px; }

.about__info { max-width: 568px; margin: 0 auto; }

@media only screen and (max-width: 1279px) { .about__info { max-width: 404px; } }

@media only screen and (max-width: 767px) { .about__info { font-size: 18px; line-height: 1.33333; } }

.about__row { position: relative; z-index: 2; display: flex; }

@media only screen and (max-width: 767px) { .about__row { display: block; } }

.about__col { flex: 0 0 50%; width: 50%; padding: 120px 50px 175px; }

@media only screen and (max-width: 1279px) { .about__col { padding: 85px 50px 110px; } }

@media only screen and (max-width: 1023px) { .about__col { padding: 50px 30px 60px; } }

@media only screen and (max-width: 767px) { .about__col { width: 100%; padding: 40px 24px 32px; } }

.about__col:nth-child(2) { border-left: 1px solid rgba(255, 255, 255, 0.2); }

@media only screen and (max-width: 767px) { .about__col:nth-child(2) { display: none; } }

.about__title { max-width: 370px; margin: 0 68px 0 auto; }

@media only screen and (max-width: 1279px) { .about__title { max-width: 258px; margin-right: 27px; } }

@media only screen and (max-width: 1023px) { .about__title { margin-right: 0; } }

@media only screen and (max-width: 767px) { .about__title { max-width: 100%; margin: 0 0 40px; text-align: center; font-size: 32px; line-height: 1.25; } }

.about__box { max-width: 465px; margin-left: 68px; }

@media only screen and (max-width: 1279px) { .about__box { max-width: 330px; margin-left: 38px; } }

@media only screen and (max-width: 1023px) { .about__box { margin-left: 0; } }

.about__btn { display: none; }

@media only screen and (max-width: 767px) { .about__btn { display: block; }
  .about__btn .btn { min-width: 100%; } }

.about__icon { margin-bottom: 29px; font-size: 0; }

@media only screen and (max-width: 1279px) { .about__icon { margin-bottom: 20px; } }

.about__head .about__icon { display: none; }

@media only screen and (max-width: 767px) { .about__head .about__icon { display: block; margin-right: -35px; margin-bottom: 6px; } }

.about__icon .about__pic { width: 103px; }

@media only screen and (max-width: 1279px) { .about__icon .about__pic { width: 73px; } }

.about__line { display: flex; align-items: center; }

.about__text { flex-grow: 1; }

.about__arrow { position: relative; flex-shrink: 0; margin: -13px 0 0 34px; border-color: #ffffff; }

.about__arrow .icon { fill: #ffffff; }

.about .line { top: 0; left: 120px; z-index: 0; opacity: .1; }

@media only screen and (max-width: 1279px) { .about .line { top: -103px; left: 0; } }

@media only screen and (max-width: 767px) { .about .line { top: -152px; left: -40px; } }

.case { position: relative; padding: 222px 0 99px; }

@media only screen and (max-width: 1279px) { .case { padding: 158px 0 96px; } }

@media only screen and (max-width: 767px) { .case { padding: 118px 0 420px; } }

.case__wrap { position: relative; z-index: 3; max-width: 400px; }

@media only screen and (max-width: 767px) { .case__wrap { max-width: 100%; } }

.case__arrows { margin-bottom: 108px; }

@media only screen and (max-width: 1279px) { .case__arrows { margin-bottom: 86px; } }

@media only screen and (max-width: 1023px) { .case__arrows { margin-bottom: 40px; } }

@media only screen and (max-width: 767px) { .case__arrows { display: none; } }

.case__category { margin-bottom: 24px; background: #FFBA79; }

@media only screen and (max-width: 1279px) { .case__category { margin-bottom: 16px; } }

.case__title { margin-bottom: 25px; }

@media only screen and (max-width: 767px) { .case__title { margin-bottom: 16px; font-size: 64px; line-height: 1.125; } }

.case__info { max-width: 266px; margin-bottom: 148px; }

@media only screen and (max-width: 1279px) { .case__info { margin-bottom: 115px; } }

@media only screen and (max-width: 1023px) { .case__info { margin-bottom: 56px; } }

@media only screen and (max-width: 767px) { .case__info { max-width: 200px; margin-bottom: 32px; } }

.case__list { display: flex; }

.case__item { font-weight: 600; color: #F50245; }

@media only screen and (max-width: 767px) { .case__item { max-width: 120px; } }

.case__item:not(:last-child) { margin-right: 58px; }

@media only screen and (max-width: 1279px) { .case__item:not(:last-child) { margin-right: 40px; } }

.case__icon { display: flex; justify-content: center; align-items: center; width: 40px; height: 40px; margin-bottom: 16px; border-radius: 50%; background: #908CFF; font-size: 0; }

.case__bg { position: absolute; top: 320px; right: calc(50% - 620px); width: 745px; font-size: 0; }

@media only screen and (max-width: 1439px) { .case__bg { width: 700px; } }

@media only screen and (max-width: 1279px) { .case__bg { top: 250px; right: calc(50% - 450px); width: 550px; } }

@media only screen and (max-width: 1023px) { .case__bg { right: calc(50% - 350px); width: 400px; } }

@media only screen and (max-width: 767px) { .case__bg { top: auto; right: calc(50% - 200px); bottom: 70px; width: 380px; } }

.case__bg .case__pic { width: 100%; }

.case__preview { position: relative; z-index: 2; }

.case__figure { position: absolute; top: -18%; right: -3.5%; width: 76%; }

.case > .line { top: 0; left: calc(50% - 425px); }

@media only screen and (max-width: 1279px) { .case > .line { top: -110px; left: calc(50% - 365px); } }

@media only screen and (max-width: 1023px) { .case > .line { left: 100px; } }

@media only screen and (max-width: 767px) { .case > .line { top: -160px; left: 75px; } }

.case > .line path { animation: dash 2.7s .8s linear forwards; }

.overview { position: relative; padding: 135px 0 222px; }

@media only screen and (max-width: 1279px) { .overview { padding: 112px 0 135px; } }

@media only screen and (max-width: 1023px) { .overview { padding: 60px 0 110px; } }

@media only screen and (max-width: 767px) { .overview { padding-bottom: 70px; } }

.overview__center { display: flex; }

@media only screen and (max-width: 767px) { .overview__center { display: block; } }

.overview__container { flex-shrink: 0; width: 470px; }

@media only screen and (max-width: 1279px) { .overview__container { width: 328px; } }

@media only screen and (max-width: 1023px) { .overview__container { width: 300px; } }

@media only screen and (max-width: 767px) { .overview__container { margin: 0 auto 40px; } }

@media only screen and (max-width: 474px) { .overview__container { width: 100%; } }

.overview__slider { visibility: hidden; }

.overview__slider.slick-initialized { visibility: visible; }

.overview__preview { font-size: 0; }

.overview__preview .overview__pic { width: 100%; }

.overview__wrap { flex-grow: 1; margin-top: -14px; padding-left: 133px; }

@media only screen and (max-width: 1365px) { .overview__wrap { padding-left: 80px; } }

@media only screen and (max-width: 1279px) { .overview__wrap { padding-left: 95px; } }

@media only screen and (max-width: 1023px) { .overview__wrap { padding-left: 50px; } }

@media only screen and (max-width: 767px) { .overview__wrap { padding: 0; } }

.overview__stage { margin-bottom: 24px; }

.overview__stage .stage__number { color: #8B8B8B; }

.overview__title { margin-bottom: 48px; }

@media only screen and (max-width: 1279px) { .overview__title { margin-bottom: 40px; } }

.overview__text { margin-bottom: 40px; line-height: 1.77778; color: #8B8B8B; }

.overview__info { max-width: 370px; margin-bottom: 79px; }

@media only screen and (max-width: 1279px) { .overview__info { margin-bottom: 40px; } }

@media only screen and (max-width: 767px) { .overview__info { margin-bottom: 32px; } }

.overview__list { display: flex; margin: 0 -18px; }

@media only screen and (max-width: 1279px) { .overview__list { margin: 0 -12px; } }

@media only screen and (max-width: 767px) { .overview__list { display: block; margin: 0; } }

.overview__item { flex: 0 0 calc(50% - 36px); width: calc(50% - 36px); margin: 0 18px; }

@media only screen and (max-width: 1279px) { .overview__item { flex: 0 0 calc(50% - 24px); width: calc(50% - 24px); margin: 0 12px; } }

@media only screen and (max-width: 767px) { .overview__item { width: 100%; margin: 0; } }

@media only screen and (max-width: 767px) { .overview__item:not(:last-child) { margin-bottom: 22px; } }

.overview__inner { position: relative; padding: 80px 40px; border-radius: 48px; border: 1px solid #D8D8D8; text-align: center; transition: all .25s; }

@media only screen and (max-width: 1365px) { .overview__inner { padding: 70px 20px; } }

@media only screen and (max-width: 1279px) { .overview__inner { padding: 56px 20px; border-radius: 32px; } }

@media only screen and (max-width: 1023px) { .overview__inner { padding: 32px 10px; } }

@media only screen and (max-width: 767px) { .overview__inner { padding: 56px 24px; } }

.overview__inner:before { content: ""; position: absolute; top: 8px; left: 0; right: 0; bottom: -8px; z-index: -1; background: #EDEDED; filter: blur(130.478px); border-radius: 48px; opacity: 0; transition: opacity .25s; }

@media only screen and (max-width: 1279px) { .overview__inner:before { filter: blur(92.784px); border-radius: 32px; } }

.overview__inner:hover { background: #ffffff; border-color: #ffffff; }

.overview__inner:hover:before { opacity: 1; }

.overview__icon { display: inline-block; margin-bottom: 40px; font-size: 0; }

@media only screen and (max-width: 1279px) { .overview__icon { margin-bottom: 28px; } }

.overview__icon .overview__pic { width: 128px; }

@media only screen and (max-width: 1279px) { .overview__icon .overview__pic { width: 92px; } }

.overview__category { color: #F50245; }

@media only screen and (max-width: 1023px) { .overview__category { font-size: 16px; } }

@media only screen and (max-width: 767px) { .overview__category { font-size: 18px; } }

.overview .slick-dots { margin-top: 60px; }

@media only screen and (max-width: 767px) { .overview .slick-dots { margin-top: 20px; } }

.overview .slick-slide { visibility: hidden; opacity: 0; transition: all .25s; }

.overview .slick-slide.slick-active { visibility: visible; opacity: 1; }

.overviewnew { padding: 135px 0 222px; }

@media only screen and (max-width: 1279px) { .overviewnew { padding: 112px 0 135px; } }

@media only screen and (max-width: 1023px) { .overviewnew { padding: 60px 0 110px; } }

@media only screen and (max-width: 767px) { .overviewnew { padding-bottom: 70px; } }

.overviewnew__center { display: flex; }

@media only screen and (max-width: 767px) { .overviewnew__center { display: block; } }

.overviewnew__container { margin: 180px 0 100px 0; padding: 0 30px; }

@media only screen and (max-width: 767px) { .overviewnew__container { margin: 150px 0 0 0; } }

.overviewnew__slider { visibility: hidden; }

.overviewnew__slider.slick-initialized { visibility: visible; }

.overviewnew__preview { font-size: 0; }

.overviewnew__preview .overviewnew__pic { width: 100%; }

.overviewnew__wrap { flex-grow: 1; margin-top: -14px; padding-left: 133px; }

@media only screen and (max-width: 1365px) { .overviewnew__wrap { padding-left: 80px; } }

@media only screen and (max-width: 1279px) { .overviewnew__wrap { padding-left: 95px; } }

@media only screen and (max-width: 1023px) { .overviewnew__wrap { padding-left: 50px; } }

@media only screen and (max-width: 767px) { .overviewnew__wrap { padding: 0; } }

.overviewnew__stage { margin-bottom: 24px; }

.overviewnew__stage .stage__number { color: #8B8B8B; }

.overviewnew__title { margin-bottom: 48px; }

@media only screen and (max-width: 1279px) { .overviewnew__title { margin-bottom: 40px; } }

.overviewnew__text { margin-bottom: 40px; line-height: 1.77778; color: #8B8B8B; }

.overviewnew__info { text-decoration: underline; max-width: 370px; margin-bottom: 79px; }

@media only screen and (max-width: 1279px) { .overviewnew__info { margin-bottom: 40px; } }

@media only screen and (max-width: 767px) { .overviewnew__info { margin-bottom: 32px; } }

.overviewnew__list { display: flex; }

@media only screen and (max-width: 1279px) { .overviewnew__list { margin: 0 -12px; } }

@media only screen and (max-width: 767px) { .overviewnew__list { display: block; margin: 0; } }

.overviewnew__item { width: calc(50% - 36px); margin: 0 18px; }

@media only screen and (max-width: 1279px) { .overviewnew__item { width: calc(50% - 24px); margin: 0 12px; } }

@media only screen and (max-width: 767px) { .overviewnew__item { width: 100%; margin: 0; } }

@media only screen and (max-width: 767px) { .overviewnew__item:not(:last-child) { margin-bottom: 22px; } }

.overviewnew__inner { position: relative; padding: 80px 40px; border-radius: 48px; border: 1px solid #D8D8D8; text-align: center; transition: all .25s; }

@media only screen and (max-width: 1365px) { .overviewnew__inner { padding: 70px 20px; } }

@media only screen and (max-width: 1279px) { .overviewnew__inner { padding: 56px 20px; border-radius: 32px; } }

@media only screen and (max-width: 1023px) { .overviewnew__inner { padding: 32px 10px; } }

@media only screen and (max-width: 767px) { .overviewnew__inner { padding: 56px 24px; } }

.overviewnew__inner:before { content: ""; position: absolute; top: 8px; left: 0; right: 0; bottom: -8px; z-index: -1; background: #EDEDED; filter: blur(130.478px); border-radius: 48px; opacity: 0; transition: opacity .25s; }

@media only screen and (max-width: 1279px) { .overviewnew__inner:before { filter: blur(92.784px); border-radius: 32px; } }

.overviewnew__inner:hover { background: #ffffff; border-color: #ffffff; }

.overviewnew__inner:hover:before { opacity: 1; }

.overviewnew__icon { display: inline-block; margin-bottom: 40px; font-size: 0; }

@media only screen and (max-width: 1279px) { .overviewnew__icon { margin-bottom: 28px; } }

.overviewnew__icon .overviewnew__pic { width: 128px; }

@media only screen and (max-width: 1279px) { .overviewnew__icon .overviewnew__pic { width: 92px; } }

.overviewnew__category { color: #F50245; }

@media only screen and (max-width: 1023px) { .overviewnew__category { font-size: 16px; } }

@media only screen and (max-width: 767px) { .overviewnew__category { font-size: 18px; } }

.overviewnew .slick-dots { margin-top: 60px; }

@media only screen and (max-width: 767px) { .overviewnew .slick-dots { margin-top: 20px; } }

.overviewnew .slick-slide { visibility: hidden; opacity: 0; transition: all .25s; }

.overviewnew .slick-slide.slick-active { visibility: visible; opacity: 1; }

.quality { position: relative; padding-top: 130px; color: #ffffff; }

@media only screen and (max-width: 1279px) { .quality { padding-top: 98px; } }

@media only screen and (max-width: 767px) { .quality { padding-top: 56px; } }

.quality__head { margin-bottom: 96px; }

@media only screen and (max-width: 1439px) { .quality__head { margin-bottom: 70px; } }

@media only screen and (max-width: 1279px) { .quality__head { margin-bottom: 40px; } }

.quality__head .stage { color: #ffffff; }

.quality__head .stage__number { color: #8B8B8B; }

.quality__list { display: flex; margin-bottom: 70px; padding-left: 200px; }

@media only screen and (max-width: 1279px) { .quality__list { margin-bottom: 56px; padding-left: 140px; } }

@media only screen and (max-width: 1023px) { .quality__list { padding-left: 80px; } }

@media only screen and (max-width: 767px) { .quality__list { flex-direction: column-reverse; padding: 0; } }

.quality__item { max-width: 267px; }

@media only screen and (max-width: 1279px) { .quality__item { max-width: 190px; } }

@media only screen and (max-width: 767px) { .quality__item { max-width: 100%; } }

.quality__item:first-child { margin-top: 150px; }

@media only screen and (max-width: 1279px) { .quality__item:first-child { margin-top: 110px; } }

@media only screen and (max-width: 767px) { .quality__item:first-child { margin: 0; } }

.quality__item:not(:last-child) { margin-right: 134px; }

@media only screen and (max-width: 1279px) { .quality__item:not(:last-child) { margin-right: 93px; } }

@media only screen and (max-width: 767px) { .quality__item:not(:last-child) { margin: 40px 0 0; } }

.quality__number { margin-right: -50px; margin-bottom: 8px; }

.quality__category { margin-bottom: 64px; }

@media only screen and (max-width: 1279px) { .quality__category { margin-bottom: 32px; } }

.quality__info { margin-bottom: 28px; padding-top: 64px; border-top: 1px solid #606060; line-height: 1.77778; color: #8B8B8B; }

@media only screen and (max-width: 1279px) { .quality__info { margin-bottom: 12px; padding-top: 32px; } }

.quality__download { display: inline-flex; align-items: center; font-weight: 600; line-height: 1.33333; color: #ffffff; }

.quality__icon { display: flex; justify-content: center; align-items: center; flex-shrink: 0; width: 48px; height: 48px; margin-right: 16px; border-radius: 50%; background: #F50245; overflow: hidden; font-size: 0; }

.quality__arrows { display: flex; flex-direction: column; transform: translateY(-43px); transition: transform .35s; }

.quality__arrows .quality__pic:not(:last-child) { margin-bottom: 64px; }

.quality__download:hover .quality__arrows { animation: arrowsDown .5s forwards; }

.quality__nav { position: relative; display: flex; max-width: 667px; margin-left: 200px; margin-bottom: 128px; }

@media only screen and (max-width: 1279px) { .quality__nav { max-width: 426px; margin-left: 140px; margin-bottom: 56px; } }

@media only screen and (max-width: 1023px) { .quality__nav { margin-left: 80px; } }

@media only screen and (max-width: 767px) { .quality__nav { display: none; } }

.quality__nav:before { content: ""; position: absolute; top: 0; left: 0; right: 0; height: 1px; background: #606060; }

.quality__link { position: relative; padding-top: 40px; font-weight: 600; color: #8B8B8B; transition: color .25s; }

@media only screen and (max-width: 1279px) { .quality__link { padding-top: 24px; } }

.quality__link:before { content: ""; position: absolute; top: -2px; left: 50%; transform: translateX(-50%); width: 5px; height: 5px; background: #ffffff; border-radius: 50%; transition: all .25s; }

.quality__link:after { content: ""; position: absolute; top: 0; right: 50%; height: 1px; background: #ffffff; }

.quality__link.active { color: #ffffff; }

.quality__link.active:before { transform: scale(1.85); box-shadow: 0 0 0 2px #F50245; }

.quality__link:not(:last-child) { margin-right: auto; }

.quality__link:first-child:before { left: -2px; transform: translateX(0); }

.quality__link:nth-child(2).active:after { width: 236px; }

@media only screen and (max-width: 1279px) { .quality__link:nth-child(2).active:after { width: 160px; } }

.quality__link:nth-child(3).active:after { width: 440px; }

@media only screen and (max-width: 1279px) { .quality__link:nth-child(3).active:after { width: 283px; } }

.quality__link:nth-child(4).active:after { width: 664px; }

@media only screen and (max-width: 1279px) { .quality__link:nth-child(4).active:after { width: 427px; } }

.quality__link:last-child { margin-right: -50px; }

.quality__container { position: relative; z-index: 3; margin-bottom: -168px; padding: 0 100px; }

@media only screen and (max-width: 1279px) { .quality__container { margin-bottom: -115px; padding: 0 70px; } }

@media only screen and (max-width: 767px) { .quality__container { position: relative; margin: 0 -80px -32px; padding: 0; } }

.quality__select { display: none; position: absolute; top: 0; right: 80px; z-index: 2; min-width: 144px; height: 48px; padding: 0 60px 0 24px; -webkit-appearance: none; -moz-appearance: none; appearance: none; border: none; border-radius: 8px; background: #303055 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none'%3E%3Cpath fill-rule='evenodd' d='M.293.293a1 1 0 0 1 1.414 0L5 3.586 8.293.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z' fill='%23fff'/%3E%3C/svg%3E") no-repeat calc(100% - 18px) 50%/10px 6px; font-family: 'SF Pro Display', sans-serif; font-size: 14px; font-weight: 600; color: #ffffff; }

.quality__select::-ms-expand { display: none; }

.quality__select:-moz-focusring { color: transparent; text-shadow: 0 0 0 #000; }

@media only screen and (max-width: 767px) { .quality__select { display: block; } }

.quality__slider { visibility: hidden; }

@media only screen and (max-width: 767px) { .quality__slider { padding-top: 124px; } }

.quality__slider.slick-initialized { visibility: visible; }

.quality__preview { font-size: 0; }

.quality__preview .quality__pic { width: 100%; }

.quality .line { top: -138px; left: 50px; transform: rotate(-90deg); }

@media only screen and (max-width: 1279px) { .quality .line { top: -220px; left: -30px; transform: rotate(-90deg) scale(0.7); } }

@media only screen and (max-width: 767px) { .quality .line { display: none; } }

.quality .slick-slide { visibility: hidden; opacity: 0; transition: all .25s; }

.quality .slick-slide.slick-active { visibility: visible; opacity: 1; }

.quality .slick-prev, .quality .slick-next { position: absolute; top: 50%; transform: translateY(-50%); }

@media only screen and (max-width: 767px) { .quality .slick-prev, .quality .slick-next { top: 0; transform: translateY(0); } }

.quality .slick-prev { left: -100px; }

@media only screen and (max-width: 1279px) { .quality .slick-prev { left: -70px; } }

@media only screen and (max-width: 767px) { .quality .slick-prev { left: 80px; } }

.quality .slick-next { right: -100px; }

@media only screen and (max-width: 1279px) { .quality .slick-next { right: -70px; } }

@media only screen and (max-width: 767px) { .quality .slick-next { right: auto; left: 144px; } }

.advantages { position: relative; padding: 357px 0 137px; background-color: #ffffff; background-image: linear-gradient(360deg, rgba(255, 255, 255, 0.0001) 0%, rgba(237, 229, 240, 0.504289) 100%); }

@media only screen and (max-width: 1279px) { .advantages { padding: 230px 0 120px; } }

@media only screen and (max-width: 767px) { .advantages { padding: 100px 0 64px; } }

.advantages__center { position: relative; z-index: 1; display: flex; }

@media only screen and (max-width: 767px) { .advantages__center { display: block; } }

.advantages__wrap { position: relative; display: flex; flex-direction: column; align-items: flex-start; flex-grow: 1; padding: 0 135px 0 100px; }

@media only screen and (max-width: 1365px) { .advantages__wrap { padding: 0 80px; } }

@media only screen and (max-width: 1279px) { .advantages__wrap { padding: 0 94px 0 70px; } }

@media only screen and (max-width: 1023px) { .advantages__wrap { padding: 0 50px 0 0; } }

@media only screen and (max-width: 767px) { .advantages__wrap { margin-bottom: 56px; padding: 0; } }

.advantages__stage { margin-bottom: 24px; }

.advantages__stage .stage__number { color: #8B8B8B; }

.advantages__title { margin-bottom: 48px; }

@media only screen and (max-width: 1279px) { .advantages__title { margin-bottom: 24px; } }

.advantages__box { position: relative; flex-shrink: 0; width: 568px; }

@media only screen and (max-width: 1279px) { .advantages__box { width: 401px; } }

@media only screen and (max-width: 1023px) { .advantages__box { width: 350px; } }

@media only screen and (max-width: 767px) { .advantages__box { width: auto; margin: 0 -16px; } }

.advantages__box:before, .advantages__box:after { content: ""; position: absolute; left: 0; right: 0; border-radius: 40px; }

@media only screen and (max-width: 1279px) { .advantages__box:before, .advantages__box:after { border-radius: 28px; } }

.advantages__box:before { top: 8px; bottom: -8px; z-index: 2; background: rgba(255, 255, 255, 0.5); }

.advantages__box:after { top: 80px; bottom: -80px; z-index: 1; background: #EDEDED; filter: blur(130.478px); }

@media only screen and (max-width: 1279px) { .advantages__box:after { top: 56px; bottom: -56px; filter: blur(92.784px); } }

.advantages__inner { position: relative; z-index: 3; padding: 80px; border-radius: 40px; background: #ffffff; }

@media only screen and (max-width: 1279px) { .advantages__inner { padding: 64px 50px 54px; border-radius: 28px; } }

@media only screen and (max-width: 1023px) { .advantages__inner { padding: 55px 30px; } }

@media only screen and (max-width: 767px) { .advantages__inner { padding: 36px 24px; } }

.advantages__info { margin-bottom: 40px; line-height: 1.77778; color: #8B8B8B; }

@media only screen and (max-width: 1279px) { .advantages__info { margin-bottom: 0; } }

.advantages__number { color: #F50245; }

.advantages__category { display: inline-block; margin-top: 5px; position: relative; }

.advantages__category:before { content: ""; position: absolute; top: 50%; left: calc(100% + 80px); width: 20px; height: 3px; background: #F50245; }

@media only screen and (max-width: 767px) { .advantages__category:before { left: calc(100% + 52px); width: 14px; height: 2px; } }

.advantages__list { margin-bottom: 69px; }

@media only screen and (max-width: 1279px) { .advantages__list { margin-bottom: 48px; } }

.advantages__item { display: flex; align-items: center; }

.advantages__item:not(:last-child) { margin-bottom: 80px; }

@media only screen and (max-width: 1279px) { .advantages__item:not(:last-child) { margin-bottom: 48px; } }

@media only screen and (max-width: 767px) { .advantages__item:not(:last-child) { margin-bottom: 40px; } }

.advantages__preview { margin-right: 40px; font-size: 0; }

@media only screen and (max-width: 1023px) { .advantages__preview { margin-right: 24px; } }

.advantages__preview .advantages__pic { width: 96px; }

@media only screen and (max-width: 1279px) { .advantages__preview .advantages__pic { width: 68px; } }

.advantages__btn .btn { min-width: 100%; }

.advantages .line { top: 55px; left: calc(50% - 600px); z-index: 0; opacity: .3; }

@media only screen and (max-width: 1279px) { .advantages .line { top: -50px; left: 0; } }

@media only screen and (max-width: 767px) { .advantages .line { left: 100px; } }

.cases { position: relative; padding-top: 128px; }

@media only screen and (max-width: 1279px) { .cases { padding-top: 96px; } }

@media only screen and (max-width: 767px) { .cases { padding-top: 64px; } }

.cases__center { position: relative; z-index: 2; }

.cases__title { margin-bottom: 80px; }

@media only screen and (max-width: 1279px) { .cases__title { margin-bottom: 32px; } }

.cases__nav { display: flex; margin-bottom: 88px; }

@media only screen and (max-width: 1279px) { .cases__nav { margin-bottom: 48px; } }

@media only screen and (max-width: 767px) { .cases__nav { display: none; } }

.cases__link { position: relative; padding-bottom: 10px; font-size: 16px; line-height: 1.5; font-weight: 500; color: #9F9FA9; transition: color .25s; }

@media only screen and (max-width: 1279px) { .cases__link { font-size: 14px; } }

.cases__link:before { content: ""; position: absolute; left: 0; right: 0; bottom: 0; width: 0; height: 2px; margin: 0 auto; background: #4C40F7; border-radius: 1px; transition: width .25s; }

.cases__link:hover { color: #232340; }

.cases__link.active { color: #232340; }

.cases__link.active:before { width: 100%; }

.cases__link:not(:last-child) { margin-right: 96px; }

@media only screen and (max-width: 1279px) { .cases__link:not(:last-child) { margin-right: 48px; } }

.cases__list { display: flex; flex-wrap: wrap; margin: -32px -16px 0; }

@media only screen and (max-width: 1279px) { .cases__list { margin: -24px -12px 0; } }

@media only screen and (max-width: 767px) { .cases__list { display: block; width: calc(100% + 64px); margin: 0 -32px; } }

.cases__item { position: relative; display: block; background: #ffffff; border-radius: 24px; }

@media only screen and (max-width: 1279px) { .cases__item { border-radius: 17px; } }

@media only screen and (max-width: 767px) { .cases__item { background: none; border-radius: 0; } }

.cases__item:before { content: ""; position: absolute; top: 24px; left: 30px; right: 30px; bottom: -96px; z-index: -1; border-radius: 24px; filter: blur(86.985px); background: #DBDEE1; visibility: hidden; opacity: 0; pointer-events: none; transition: all .25s; }

@media only screen and (max-width: 1279px) { .cases__item:before { top: 30px; left: 24px; right: 24px; bottom: -51px; border-radius: 17px; filter: blur(77px); } }

@media only screen and (max-width: 767px) { .cases__item:before { display: none; } }

.cases__item:hover:before { visibility: visible; opacity: 1; }

.cases__list .cases__item { display: flex; flex-direction: column; flex: 0 0 calc(50% - 32px); width: calc(50% - 32px); margin: 32px 16px 0; }

@media only screen and (max-width: 1279px) { .cases__list .cases__item { flex: 0 0 calc(50% - 24px); width: calc(50% - 24px); margin: 24px 12px 0; } }

@media only screen and (max-width: 767px) { .cases__list .cases__item { width: 100%; margin: 0; } }

.cases__preview { position: relative; font-size: 0; }

.cases__pic { width: 100%; }

.cases__blogpic { width: 100%; border-radius: 24px; }

@media only screen and (max-width: 1279px) { .cases__blogpic { border-radius: 17px; } }

@media only screen and (max-width: 767px) { .cases__blogpic { margin-bottom: 16px; border-radius: 0; } }

.cases__preview .cases__pic { border-radius: 24px 24px 0 0; }

@media only screen and (max-width: 1279px) { .cases__preview .cases__pic { border-radius: 17px 17px 0 0; } }

@media only screen and (max-width: 767px) { .cases__preview .cases__pic { border-radius: 0; } }

.cases__box { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) scale(0.6); display: flex; justify-content: center; align-items: center; width: 150px; height: 150px; padding: 30px; border-radius: 50%; background: #00113B; text-align: center; opacity: 0; font-size: 18px; line-height: 1.33333; font-weight: 500; color: #ffffff; transition: all .25s; }

@media only screen and (max-width: 767px) { .cases__box { display: none; } }

.cases__item:hover .cases__box { opacity: 1; transform: translate(-50%, -50%) scale(1); }

.cases__body { flex-grow: 1; padding: 48px 48px 76px; border-radius: 0 0 24px 24px; background: #ffffff; }

@media only screen and (max-width: 1279px) { .cases__body { padding: 40px; border-radius: 0 0 17px 17px; } }

@media only screen and (max-width: 1023px) { .cases__body { padding: 32px; } }

.cases__info { margin-bottom: 8px; color: #232340; }

@media only screen and (max-width: 1279px) { .cases__info { margin-bottom: 4px; } }

.cases__text { color: #6b6b6b; }

.cases__figure { position: absolute; font-size: 0; pointer-events: none; }

@media only screen and (max-width: 767px) { .cases__figure { display: none; } }

.cases__figure:first-child { top: 128px; left: calc(50% - 181px); width: 363px; }

@media only screen and (max-width: 1279px) { .cases__figure:first-child { top: 80px; left: calc(50% - 135px); width: 280px; } }

@media only screen and (max-width: 1023px) { .cases__figure:first-child { left: 400px; } }

.cases__figure:nth-child(2) { left: calc(50% - 1406px); width: 1200px; }

@media only screen and (max-width: 1279px) { .cases__figure:nth-child(2) { left: -520px; width: 900px; } }

.cases__container { margin: 0 -16px; }

@media only screen and (max-width: 1279px) { .cases__container { margin: 0 -12px; } }

@media only screen and (max-width: 767px) { .cases__container { margin: 0 -32px; } }

.cases .owl-stage-outer { overflow: visible; }

.cases .owl-item { padding: 0 16px; opacity: 0; transition: opacity .25s; }

@media only screen and (max-width: 1279px) { .cases .owl-item { padding: 0 12px; } }

@media only screen and (max-width: 767px) { .cases .owl-item { padding: 0; } }

.cases .owl-item.active { opacity: 1; }

.cases .owl-nav { position: absolute; top: -290px; right: calc(50% - 550px); justify-content: stretch; }

@media only screen and (max-width: 1365px) { .cases .owl-nav { right: 50px; } }

@media only screen and (max-width: 1279px) { .cases .owl-nav { top: -210px; } }

.cases .owl-dots { padding: 0 32px; }

.cases_main .cases__figure:nth-child(2) { top: 335px; }

@media only screen and (max-width: 1279px) { .cases_main .cases__figure:nth-child(2) { top: 260px; } }

.cases_other { margin-bottom: 160px; }

@media only screen and (max-width: 1279px) { .cases_other { margin-bottom: 88px; } }

@media only screen and (max-width: 767px) { .cases_other { display: none; margin-bottom: 56px; padding-top: 0; } }

.cases_other .cases__figure:nth-child(2) { top: 160px; }

[data-aos="animation-translate-right"] { transform: translateX(-30px); opacity: 0; transition-property: transform, opacity; }

[data-aos="animation-translate-right"].aos-animate { transform: translateX(0); opacity: 1; }

[data-aos="animation-translate-up"] { transform: translateY(40px); opacity: 0; transition-property: transform, opacity; }

[data-aos="animation-translate-up"].aos-animate { transform: translateY(0); opacity: 1; }

[data-aos="animation-scale-y"] { transform: translateY(40px) scaleY(1.4); opacity: 0; transition-property: transform, opacity; }

[data-aos="animation-scale-y"].aos-animate { transform: translateY(0) scaleY(1); opacity: 1; }

.review { position: relative; padding-top: 128px; }

@media only screen and (max-width: 1279px) { .review { padding-top: 96px; } }

@media only screen and (max-width: 767px) { .review { padding-top: 72px; } }

.review__center { position: relative; z-index: 2; max-width: 933px; }

@media only screen and (max-width: 1279px) { .review__center { max-width: 730px; } }

.review__stage.stage { padding: 0; text-align: center; }

.review__stage.stage:before { display: none; }

.review__title { margin-bottom: 96px; text-align: center; }

@media only screen and (max-width: 1279px) { .review__title { margin-bottom: 48px; } }

@media only screen and (max-width: 767px) { .review__title { margin-bottom: 24px; } }

.review__container { margin: 0 -16px; }

.review__item { position: relative; padding: 64px 44px 82px 64px; border-radius: 24px; background: #ffffff; }

@media only screen and (max-width: 1279px) { .review__item { padding: 48px; } }

@media only screen and (max-width: 767px) { .review__item { padding: 40px 38px 60px; text-align: center; } }

.review__item:before, .review__item:after { content: ""; position: absolute; }

.review__item:before { top: 8px; left: 0; right: 0; bottom: -8px; z-index: -1; border-radius: 24px; background: rgba(255, 255, 255, 0.3); }

@media only screen and (max-width: 767px) { .review__item:before { display: block; background: rgba(255, 255, 255, 0.5); } }

.review__item:after { left: 32px; right: 32px; bottom: -55px; z-index: -2; height: 180px; border-radius: 24px; background: #DBDEE1; filter: blur(86.985px); visibility: hidden; opacity: 0; pointer-events: none; transition: all .25s; }

@media only screen and (max-width: 1279px) { .review__item:after { left: 23px; right: 23px; bottom: -48px; height: 96px; border-radius: 17px; filter: blur(108.731px); } }

@media only screen and (max-width: 767px) { .review__item:after { top: 58px; left: 20px; right: 20px; bottom: -24px; height: auto; } }

.review__item:hover:after { visibility: visible; opacity: 1; }

.review__text { margin-bottom: 50px; font-size: 28px; line-height: 1.42857; }

@media only screen and (max-width: 1279px) { .review__text { margin-bottom: 40px; font-size: 18px; line-height: 1.77778; } }

@media only screen and (max-width: 767px) { .review__text { margin-bottom: 35px; } }

.review__author { display: inline-flex; align-items: center; }

@media only screen and (max-width: 767px) { .review__author { display: block; } }

.review__ava { flex-shrink: 0; width: 64px; height: 64px; margin-right: 16px; font-size: 0; }

@media only screen and (max-width: 1279px) { .review__ava { width: 46px; height: 46px; margin-right: 12px; } }

@media only screen and (max-width: 767px) { .review__ava { margin: 0 auto 12px; } }

.review__ava .review__pic { width: 100%; min-height: 100%; border-radius: 50%; }

@media only screen and (max-width: 1279px) { .review__man { font-size: 18px; line-height: 1.44444; } }

.review__company { color: #6b6b6b; }

.review__preview { position: absolute; top: -78px; left: 50%; width: 730px; margin-left: -416px; opacity: .5; pointer-events: none; font-size: 0; }

@media only screen and (max-width: 1279px) { .review__preview { top: -46px; width: 550px; margin-left: -308px; } }

@media only screen and (max-width: 767px) { .review__preview { top: 463px; left: -137px; z-index: 3; width: 300px; margin: 0; } }

.review__preview:nth-child(2) { display: none; }

@media only screen and (max-width: 767px) { .review__preview:nth-child(2) { display: block; top: 328px; left: auto; right: -22px; width: 120px; } }

.review__preview .review__pic { width: 100%; }

.review .owl-stage-outer { position: relative; z-index: 2; overflow: visible; }

.review .owl-item { padding: 0 16px; opacity: .4; transition: all .25s; }

.review .owl-item.active { opacity: 1; }

@media only screen and (max-width: 1279px) { .review .owl-item.active .review__item:after { visibility: visible; opacity: 1; } }

.review .owl-nav { margin-top: 80px; }

@media only screen and (max-width: 1279px) { .review .owl-nav { margin-top: 68px; } }

.review .owl-dots { position: absolute; left: 0; right: 0; bottom: 24px; z-index: 3; justify-content: center; }

.contacts { position: relative; padding-top: 150px; margin-bottom: 182px; z-index: 3; }

@media only screen and (max-width: 1279px) { .contacts { margin-bottom: 112px; } }

@media only screen and (max-width: 767px) { .contacts { margin-bottom: 57px; } }

.contacts__container { position: relative; padding: 162px 100px 60px; background: #ffffff; border-radius: 48px; }

@media only screen and (max-width: 1279px) { .contacts__container { padding: 78px 73px 40px; border-radius: 34px; } }

@media only screen and (max-width: 767px) { .contacts__container { padding: 0; border-radius: 0; background: none; } }

.contacts__container:before, .contacts__container:after { content: ""; position: absolute; top: 50px; border-radius: 34px; }

@media only screen and (max-width: 767px) { .contacts__container:before, .contacts__container:after { display: none; } }

.contacts__container:after { left: 17px; right: 17px; bottom: -16px; z-index: -1; background: #F50245; }

.contacts__container:before { left: 50px; right: 50px; bottom: -32px; z-index: -2; background: #1B1B87; }

.contacts__wrap { position: relative; z-index: 3; margin-bottom: 85px; }

@media only screen and (max-width: 767px) { .contacts__wrap { margin-bottom: 40px; } }

.contacts__title { margin-bottom: 40px; }

@media only screen and (max-width: 1279px) { .contacts__title { margin-bottom: 32px; } }

@media only screen and (max-width: 767px) { .contacts__title { margin: 0; } }

.contacts__info { max-width: 366px; color: #6b6b6b; }

@media only screen and (max-width: 1279px) { .contacts__info { max-width: 270px; } }

@media only screen and (max-width: 767px) { .contacts__info { display: none; } }

.contacts__row { position: relative; z-index: 3; display: flex; margin-bottom: 93px; }

@media only screen and (max-width: 1279px) { .contacts__row { margin-bottom: 46px; } }

@media only screen and (max-width: 1023px) { .contacts__row { display: block; } }

@media only screen and (max-width: 767px) { .contacts__row { margin-bottom: 40px; } }

.contacts__details { flex-shrink: 0; width: 340px; margin-right: auto; }

@media only screen and (max-width: 1023px) { .contacts__details { width: 100%; margin-bottom: 38px; } }

.contacts__line { display: flex; color: #232340; }

@media only screen and (max-width: 1279px) { .contacts__line { font-size: 16px; line-height: 1.75; } }

@media only screen and (max-width: 767px) { .contacts__line { font-weight: 600; } }

.contacts__line:not(:last-child) { margin-bottom: 40px; }

@media only screen and (max-width: 1279px) { .contacts__line:not(:last-child) { margin-bottom: 20px; } }

.contacts__icon { display: flex; justify-content: center; align-items: center; flex-shrink: 0; width: 24px; height: 30px; margin-right: 45px; font-size: 0; }

@media only screen and (max-width: 1279px) { .contacts__icon { margin-right: 24px; width: 17px; height: 28px; } }

.contacts__icon .icon { fill: #4C40F7; }

.contacts__icon .icon-email { font-size: 20px; }

@media only screen and (max-width: 1279px) { .contacts__icon .icon-email { font-size: 14px; } }

.contacts__icon .icon-place { font-size: 23px; }

@media only screen and (max-width: 1279px) { .contacts__icon .icon-place { font-size: 17px; } }

.contacts__icon .icon-phone { font-size: 24px; }

@media only screen and (max-width: 1279px) { .contacts__icon .icon-phone { font-size: 18px; } }

.contacts__form { flex-shrink: 0; width: 468px; }

@media only screen and (max-width: 1279px) { .contacts__form { width: 345px; } }

@media only screen and (max-width: 1023px) { .contacts__form { width: 100%; } }

.contacts__field:not(:last-child) { margin-bottom: 24px; }

@media only screen and (max-width: 1279px) { .contacts__field:not(:last-child) { margin-bottom: 16px; } }

@media only screen and (max-width: 767px) { .contacts__btn .btn { min-width: 100%; } }

.contacts__category { display: none; }

@media only screen and (max-width: 767px) { .contacts__category { display: block; margin-bottom: 32px; } }

.contacts__social { display: flex; align-items: center; margin: 0 -100px; }

@media only screen and (max-width: 1279px) { .contacts__social { margin: 0 -73px; } }

@media only screen and (max-width: 767px) { .contacts__social { flex-wrap: wrap; margin: -16px -8px 0; } }

.contacts__box { width: 25%; }

@media only screen and (max-width: 767px) { .contacts__box { flex: 0 0 calc(50% - 16px); width: calc(50% - 16px); margin: 16px 8px 0; } }

.contacts__social .contacts__link { position: relative; display: flex; justify-content: center; align-items: center; height: 140px; padding: 0 10px; font-size: 16px; font-weight: 500; color: #9F9FA9; transition: color .25s; }

@media only screen and (max-width: 1279px) { .contacts__social .contacts__link { height: 100px; } }

@media only screen and (max-width: 767px) { .contacts__social .contacts__link { background: rgba(244, 244, 244, 0.8); border-radius: 17px; font-size: 14px; } }

.contacts__social .contacts__link .icon { position: relative; z-index: 3; margin-right: 14px; font-size: 16px; fill: #9F9FA9; transition: fill .25s; }

@media only screen and (max-width: 1279px) { .contacts__social .contacts__link .icon { font-size: 14px; } }

@media only screen and (max-width: 767px) { .contacts__social .contacts__link .icon { margin-right: 10px; fill: #232340; } }

.contacts__social .contacts__link:after { content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 2; border-radius: 24px; }

.contacts__social .contacts__link:before { content: ""; position: absolute; bottom: -50px; left: 0; right: 0; height: 120px; border-radius: 32px; background: #DBDEE1; filter: blur(86.985px); visibility: hidden; opacity: 0; pointer-events: none; transition: all .25s; }

@media only screen and (max-width: 1365px) { .contacts__social .contacts__link:before { bottom: -20px; } }

.contacts__social .contacts__link:hover { background: #ffffff; color: #232340; }

.contacts__social .contacts__link:hover:after { background: #ffffff; }

.contacts__social .contacts__link:hover:before { visibility: visible; opacity: 1; }

.contacts__social .contacts__link:hover .icon { fill: #232340; }

.contacts__link .contacts__text { position: relative; z-index: 3; }

.contacts .figures { top: 109px; right: calc(50% - 720px); }

@media only screen and (max-width: 1365px) { .contacts .figures { right: calc(50% - 670px); } }

@media only screen and (max-width: 1279px) { .contacts .figures { top: 80px; right: calc(50% - 530px); } }

@media only screen and (max-width: 1023px) { .contacts .figures { top: 130px; right: calc(50% - 450px); } }

@media only screen and (max-width: 767px) { .contacts .figures { top: 90px; right: -90px; width: 320px; } }

.figures { position: absolute; width: 50%; pointer-events: none; font-size: 0; }

@media only screen and (max-width: 1279px) { .figures { width: 800px; } }

@media only screen and (max-width: 1023px) { .figures { width: 700px; } }

.figures__preview:nth-child(1) { position: relative; top: -200px; width: 90%; }

@media only screen and (max-width: 767px) { .figures__preview:nth-child(1) { display: none; } }

@media only screen and (max-width: 1023px) { .figures__preview:nth-child(1) { left: 40%; width: 50%; } }

.figures__preview:not(:first-child) { position: absolute; }

.figures__preview .figures__pic { width: 100%; }

.footer { position: relative; z-index: 2; color: #ffffff; }

@media only screen and (max-width: 767px) { .footer { position: relative; padding: 56px 0 60px; background: #00113B; }
  .footer:before, .footer:after { content: ""; position: absolute; left: 50%; z-index: -1; transform: translateX(-50%); border-radius: 50%; border: 1px solid rgba(255, 255, 255, 0.1); pointer-events: none; }
  .footer:before { top: 90px; width: 600px; height: 600px; }
  .footer:after { top: 201px; width: 376px; height: 376px; } }

.footer__center { position: relative; z-index: 3; }

.footer__body { display: flex; align-items: flex-start; margin-bottom: 113px; padding-right: 74px; }

@media only screen and (max-width: 1365px) { .footer__body { padding: 0; } }

@media only screen and (max-width: 1279px) { .footer__body { margin-bottom: 88px; } }

@media only screen and (max-width: 767px) { .footer__body { display: block; margin-bottom: 52px; } }

.footer__logo { display: inline-block; font-size: 0; }

@media only screen and (max-width: 767px) { .footer__logo { margin-bottom: 16px; } }

@media only screen and (max-width: 1279px) { .footer__logo .footer__pic { width: 50px; } }

.footer__wrap { max-width: 670px; margin: -22px auto 0; }

@media only screen and (max-width: 1279px) { .footer__wrap { max-width: 490px; margin-top: -10px; } }

@media only screen and (max-width: 1023px) { .footer__wrap { max-width: 430px; } }

@media only screen and (max-width: 767px) { .footer__wrap { max-width: 100%; margin: 0 0 42px; } }

.footer__title { margin-bottom: 48px; }

@media only screen and (max-width: 1279px) { .footer__title { margin-bottom: 44px; font-size: 42px; line-height: 1.33333; } }

@media only screen and (max-width: 1023px) { .footer__title { font-size: 36px; } }

@media only screen and (max-width: 767px) { .footer__title { margin-bottom: 32px; font-size: 42px; line-height: 1.33333; } }

.footer__info { color: #FFC7DF; }

@media only screen and (max-width: 1279px) { .footer__info { font-size: 20px; line-height: 1.4; } }

@media only screen and (max-width: 767px) { .footer__info { font-size: 16px; line-height: 1.625; } }

.footer__menu { display: flex; flex-direction: column; align-items: flex-start; }

@media only screen and (max-width: 767px) { .footer__menu { display: block; column-count: 2; padding-right: 100px; } }

.footer__link { line-height: 1.44444; font-weight: 500; color: #ffffff; transition: opacity .25s; }

@media only screen and (max-width: 1279px) { .footer__link { font-size: 14px; } }

@media only screen and (max-width: 767px) { .footer__link { display: block; } }

.footer__link:hover { opacity: .3; }

.footer__link:not(:last-child) { margin-bottom: 23px; }

@media only screen and (max-width: 1279px) { .footer__link:not(:last-child) { margin-bottom: 20px; } }

.footer__bottom { display: flex; align-items: center; margin: 0 -67px; padding: 45px 67px 58px; border-top: 1px solid rgba(216, 216, 216, 0.2); font-size: 16px; line-height: 1.5; font-weight: 500; }

@media only screen and (max-width: 1279px) { .footer__bottom { padding: 32px 0 42px; font-size: 14px; } }

@media only screen and (max-width: 1365px) { .footer__bottom { margin: 0; padding: 45px 0 58px; } }

@media only screen and (max-width: 767px) { .footer__bottom { padding: 0; border: none; } }

.footer__copyright { margin-right: auto; }

.footer__scroll { color: #ffffff; transition: opacity .25s; }

@media only screen and (max-width: 767px) { .footer__scroll { display: none; } }

.footer__scroll:hover { opacity: .85; }

.footer__preview { position: absolute; font-size: 0; pointer-events: none; }

.footer__preview:first-child { top: -155px; left: calc(50% - 536px); width: 330px; }

@media only screen and (max-width: 1279px) { .footer__preview:first-child { top: -105px; left: calc(50% - 355px); width: 250px; } }

@media only screen and (max-width: 767px) { .footer__preview:first-child { display: none; } }

.footer__preview:nth-child(2) { top: 233px; right: calc(50% - 237px); width: 150px; }

@media only screen and (max-width: 1279px) { .footer__preview:nth-child(2) { top: 174px; right: calc(50% - 163px); width: 83px; } }

@media only screen and (max-width: 639px) { .footer__preview:nth-child(2) { top: 300px; right: 50px; width: 150px; } }

.footer__preview .footer__pic { width: 100%; }

.field__wrap { position: relative; }

.field__input, .field__textarea, .field__select { width: 100%; background: rgba(244, 244, 244, 0.801191); border-radius: 12px; border: 2px solid rgba(244, 244, 244, 0.801191); font-family: 'Poppins', sans-serif; font-size: 18px; color: #232340; transition: all .25s; }

@media only screen and (max-width: 1279px) { .field__input, .field__textarea, .field__select { font-size: 16px; } }

.field__input:focus, .field__textarea:focus, .field__select:focus { border-color: #4C40F7; background: #ffffff; }

.field__input::placeholder, .field__textarea::placeholder, .field__select::placeholder { color: #6b6b6b; }

.field__input:focus + .field__icon .icon, .field__textarea:focus + .field__icon .icon, .field__select:focus + .field__icon .icon { fill: #4C40F7; }

.field__input, .field__select { height: 72px; padding: 0 72px 0 24px; }

@media only screen and (max-width: 1279px) { .field__input, .field__select { height: 56px; padding-right: 56px; } }

.field__textarea { height: 200px; resize: none; padding: 20px 72px 20px 24px; }

@media only screen and (max-width: 1279px) { .field__textarea { height: 110px; padding: 12px 56px 14px 24px; } }

.field__select { -webkit-appearance: none; -moz-appearance: none; appearance: none; }

.field__select::-ms-expand { display: none; }

.field__select:-moz-focusring { color: transparent; text-shadow: 0 0 0 #000; }

.field__icon { position: absolute; top: 0; right: 0; display: flex; justify-content: center; align-items: center; width: 72px; height: 72px; font-size: 0; pointer-events: none; }

@media only screen and (max-width: 1279px) { .field__icon { width: 56px; height: 56px; } }

.field__icon .icon { font-size: 17px; fill: #232340; transition: fill .25s; }

@media only screen and (max-width: 1279px) { .field__icon .icon { font-size: 14px; } }

.field_textarea { font-size: 0; }

.wrapper_gradient { background: radial-gradient(75.22% 75.22% at 20.49% 12.79%, #FFFFFF 0%, rgba(228, 231, 233, 0.502295) 100%); }

@media only screen and (max-width: 767px) { .wrapper_gradient { background: none; } }

.wrapper_dark { position: relative; }

.wrapper_dark:before, .wrapper_dark:after { content: ""; position: absolute; }

@media only screen and (max-width: 767px) { .wrapper_dark:before, .wrapper_dark:after { display: none; } }

.wrapper_dark:before { content: ""; position: absolute; top: 500px; right: 0; left: 0; bottom: 0; clip-path: polygon(0 600px, 100% 0, 100% 100%, 0 100%); background: #00113B; }

.wrapper_dark:after { content: ""; position: absolute; top: 540px; left: 0; width: 200px; height: 560px; clip-path: polygon(0 58px, 100% 0, 100% calc(100% - 58px), 0 100%); background: #F50245; }

.header { position: absolute; top: 0; left: 0; right: 0; z-index: 10; padding: 48px 0; }

@media only screen and (max-width: 1279px) { .header { padding: 32px 0; } }

@media only screen and (max-width: 767px) { .header { padding: 36px 0; } }

.header__center { display: flex; }

.header > .header__center { align-items: center; max-width: 100%; padding: 0 68px; }

@media only screen and (max-width: 1279px) { .header > .header__center { padding: 0 48px; } }

@media only screen and (max-width: 767px) { .header > .header__center { padding: 0 32px; } }

.header__center .header__logo { margin-right: 93px; }

@media only screen and (max-width: 1279px) { .header__center .header__logo { margin-right: 68px; } }

@media only screen and (max-width: 1023px) { .header__center .header__logo { margin-right: 40px; } }

@media only screen and (max-width: 767px) { .header__center .header__logo { position: relative; z-index: 15; margin-right: auto; } }

.header__logo { font-size: 0; }

.header__logo .header__pic { width: 174px; }

@media only screen and (max-width: 1279px) { .header__logo .header__pic { width: 122px; } }

@media only screen and (max-width: 767px) { .header__logo .header__pic { width: 133px; } }

.header__social { display: flex; align-items: center; }

.header__social_main { margin-right: auto; }

@media only screen and (max-width: 767px) { .header__social_main { display: none; } }

.header__social_inner { position: absolute; left: calc(50% - 184px); bottom: 82px; }

@media only screen and (max-width: 1279px) { .header__social_inner { left: 380px; bottom: 56px; } }

@media only screen and (max-width: 767px) { .header__social_inner { position: relative; left: auto; bottom: auto; margin-top: 20px; } }

@media only screen and (max-width: 639px) { .header__social_inner { flex-direction: column; align-items: flex-start; margin-top: 32px; } }

.header__social .header__link { display: flex; align-items: center; font-size: 16px; font-weight: 500; line-height: 1.5; color: #9F9FA9; transition: color .25s; }

@media only screen and (max-width: 1279px) { .header__social .header__link { font-size: 14px; } }

@media only screen and (max-width: 1023px) { .header__social .header__link { flex-direction: column; } }

.header__social .header__link .icon { margin-right: 15px; font-size: 16px; fill: #9F9FA9; transition: fill .25s; }

.header__social .header__link:hover { color: #232340; }

.header__social .header__link:hover .icon { fill: #232340; }

.header__social .header__link:not(:last-child) { margin-right: 68px; }

@media only screen and (max-width: 1023px) { .header__social .header__link:not(:last-child) { margin-right: 40px; } }

@media only screen and (max-width: 639px) { .header__social .header__link:not(:last-child) { margin: 0 0 16px; } }

.header__burger { position: relative; z-index: 12; width: 64px; height: 64px; margin-right: -17px; border-radius: 50%; padding: 5px; background: transparent; font-size: 0; transition: all .25s; }

@media only screen and (max-width: 1279px) { .header__burger { width: 48px; height: 48px; margin-right: -10px; } }

@media only screen and (max-width: 767px) { .header__burger { width: 32px; height: 32px; margin-right: -5px; padding: 0; } }

.header__burger:before, .header__burger:after { content: ""; display: inline-block; width: 32px; height: 2px; background: #ffffff; transition: transform .25s; }

@media only screen and (max-width: 1279px) { .header__burger:before, .header__burger:after { width: 24px; } }

@media only screen and (max-width: 767px) { .header__burger:before, .header__burger:after { background: #232340; } }

.header__burger:before { margin-bottom: 4px; }

@media only screen and (max-width: 1279px) { .header__burger:before { margin-bottom: 2.5px; } }

.header__burger:after { margin-top: 4px; }

@media only screen and (max-width: 1279px) { .header__burger:after { margin-top: 2.5px; } }

.header__burger_dark:before, .header__burger_dark:after { background: #F50245; }

.header__burger:hover { opacity: .85; }

.header__burger.active { background: #F50245; }

@media only screen and (max-width: 767px) { .header__burger.active { background: none; } }

.header__burger.active:before, .header__burger.active:after { background: #ffffff; }

@media only screen and (max-width: 767px) { .header__burger.active:before, .header__burger.active:after { background: #00113B; } }

.header__burger.active:before { transform: translateY(5px) rotate(45deg); }

@media only screen and (max-width: 1279px) { .header__burger.active:before { transform: translateY(3.5px) rotate(45deg); } }

.header__burger.active:after { transform: translateY(-5px) rotate(-45deg); }

@media only screen and (max-width: 1279px) { .header__burger.active:after { transform: translateY(-3.5px) rotate(-45deg); } }

.header__wrapper { position: fixed; top: 0; left: 0; right: 0; bottom: 0; display: flex; align-items: center; z-index: 10; background: #ffffff; visibility: hidden; opacity: 0; transition: all .25s; }

@media only screen and (max-width: 767px) { .header__wrapper { display: block; } }

@media only screen and (max-width: 639px) { .header__wrapper { visibility: visible; opacity: 1; transform: translateX(100%); background: #ffffff; } }

.header__wrapper.visible { visibility: visible; opacity: 1; }

@media only screen and (max-width: 639px) { .header__wrapper.visible { transform: translateX(0); } }

.header__preview { position: absolute; top: 0; left: 0; bottom: 0; width: 400px; padding: 64px 0 0 68px; background: linear-gradient(to bottom, #FF6D20, #F50245); overflow: hidden; }

@media only screen and (max-width: 1279px) { .header__preview { width: 300px; padding: 46px 0 0 48px; } }

@media only screen and (max-width: 767px) { .header__preview { display: none; } }

.header__img { position: absolute; top: 50%; left: 0; transform: translateY(-50%); height: 100%; max-height: 100%; font-size: 0; }

@media only screen and (max-width: 1023px) { .header__img { height: 80%; max-height: 80%; transform: translateY(-60%); } }

.header__img .header__pic { height: 100%; width: auto; }

.header__wrapper .header__center { align-items: stretch; width: 100%; max-width: 1204px; padding-left: 418px; }

@media only screen and (max-width: 1279px) { .header__wrapper .header__center { max-width: 100%; padding-left: 380px; } }

@media only screen and (max-width: 1023px) { .header__wrapper .header__center { flex-direction: column-reverse; } }

@media only screen and (max-width: 767px) { .header__wrapper .header__center { display: block; padding: 120px 32px 30px; } }

.header__contacts { display: flex; flex-direction: column; align-items: flex-start; flex-shrink: 0; width: 340px; margin-right: auto; }

@media only screen and (max-width: 1279px) { .header__contacts { width: 280px; } }

@media only screen and (max-width: 1023px) { .header__contacts { margin: 120px 0 0; } }

@media only screen and (max-width: 767px) { .header__contacts { display: none; } }

.header__line { display: flex; color: #232340; }

@media only screen and (max-width: 1279px) { .header__line { font-size: 16px; line-height: 1.75; } }

.header__line:not(:last-child) { margin-bottom: 24px; }

.header__icon { display: flex; justify-content: center; align-items: center; flex-shrink: 0; width: 24px; height: 30px; margin-right: 45px; font-size: 0; }

@media only screen and (max-width: 1279px) { .header__icon { margin-right: 25px; width: 17px; height: 28px; } }

.header__icon .icon { fill: #F50245; }

.header__icon .icon-email { font-size: 20px; }

@media only screen and (max-width: 1279px) { .header__icon .icon-email { font-size: 14px; } }

.header__icon .icon-place { font-size: 23px; }

@media only screen and (max-width: 1279px) { .header__icon .icon-place { font-size: 17px; } }

.header__icon .icon-phone { font-size: 24px; }

@media only screen and (max-width: 1279px) { .header__icon .icon-phone { font-size: 18px; } }

.header__nav { display: flex; flex-direction: column; align-items: flex-start; margin-top: -15px; }

.header__item { overflow: hidden; font-size: 72px; line-height: 1.16667; letter-spacing: -.5px; font-weight: 600; color: #232340; transition: color .25s; }

@media only screen and (max-width: 1279px) { .header__item { font-size: 42px; line-height: 1.33333; } }

@media only screen and (max-width: 767px) { .header__item { font-size: 24px; line-height: 1.5; } }

@media only screen and (max-width: 639px) { .header__item { font-size: 32px; } }

.header__item span { display: block; transform: translateY(110%); transition: transform .25s; transition-delay: .3s; }

.header__item:hover { color: #F50245; }

.header__item:not(:last-child) { margin-bottom: 16px; }

@media only screen and (max-width: 1279px) { .header__item:not(:last-child) { margin-bottom: 8px; } }

@media only screen and (max-width: 767px) { .header__item:not(:last-child) { margin-bottom: 0; } }

.header__wrapper.visible .header__item span { transform: translateX(0); }

.header__wrapper.visible .header__item:first-child span { transition-delay: .2s; }

.header__wrapper.visible .header__item:nth-child(2) span { transition-delay: .4s; }

.header__wrapper.visible .header__item:nth-child(3) span { transition-delay: .6s; }

.header__wrapper.visible .header__item:nth-child(4) span { transition-delay: .8s; }

.owl-nav { position: relative; z-index: 1; display: flex; justify-content: center; align-items: center; margin-top: 67px; height: 40px; }

@media only screen and (max-width: 1279px) { .owl-nav { margin-top: 52px; } }

@media only screen and (max-width: 767px) { .owl-nav { height: 32px; margin-top: 36px; } }

.owl-prev, .owl-next { position: relative; width: 30px; height: 40px; font-size: 0; }

@media only screen and (max-width: 767px) { .owl-prev, .owl-next { height: 32px; } }

.owl-prev span, .owl-next span { display: inline-block; position: absolute; top: 50%; transform: translateY(-50%); width: 30px; height: 2px; background: #9F9FA9; font-size: 0; transition: all .25s; }

.owl-prev span:before, .owl-next span:before { content: ""; position: absolute; top: 50%; transform: translateY(-50%) scale(0); width: 40px; height: 40px; border: 2px solid #4C40F7; border-radius: 50%; transition: transform .25s; }

@media only screen and (max-width: 767px) { .owl-prev span:before, .owl-next span:before { width: 34px; height: 34px; } }

.owl-prev .icon, .owl-next .icon { position: absolute; top: 50%; transform: translateY(-50%); font-size: 14px; fill: #9F9FA9; transition: fill .25s; }

.owl-prev:hover span, .owl-next:hover span { width: 52px; background: #232340; }

.owl-prev:hover span:before, .owl-next:hover span:before { transform: translateY(-50%) scale(1); }

.owl-prev:hover .icon, .owl-next:hover .icon { fill: #232340; }

.owl-prev { margin-right: 27px; }

@media only screen and (max-width: 767px) { .owl-prev { margin-right: 20px; } }

.owl-prev span { right: 0; }

.owl-prev span .icon { left: 0; }

.owl-prev span:before { left: -17px; }

@media only screen and (max-width: 767px) { .owl-prev span:before { left: -14px; } }

.owl-next span { left: 0; }

.owl-next span .icon { right: 0; }

.owl-next span:before { right: -17px; }

@media only screen and (max-width: 767px) { .owl-next span:before { right: -14px; } }

.owl-dots { display: flex; padding: 0 32px; }

.owl-dot { position: relative; flex-shrink: 0; width: 8px; height: 8px; }

.owl-dot:before { content: ""; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 4px; height: 4px; border-radius: 50%; background: #D8D8D8; transition: all .25s; }

.owl-dot.active:before { transform: translate(-50%, -50%) scale(2); background: #4C40F7; }

.owl-dot:not(:last-child) { margin-right: 12px; }

[data-aos="animation-scale"] { transform: translateY(30px) scale(0.5); opacity: 0; transition-property: transform, opacity; }

[data-aos="animation-scale"].aos-animate { transform: translateY(0) scale(1); opacity: 1; }

[data-aos="animation-scale-x-left"] { transform: translateX(-50px) scaleX(1.1); opacity: 0; transition-property: transform, opacity; }

[data-aos="animation-scale-x-left"].aos-animate { transform: translateX(0) scaleX(1); opacity: 1; }

[data-aos="animation-scale-x-right"] { transform: translateX(50px) scaleX(1.1); opacity: 0; transition-property: transform, opacity; }

[data-aos="animation-scale-x-right"].aos-animate { transform: translateX(0) scaleX(1); opacity: 1; }

[data-aos="animation-scale-y"] { transform: translateY(40px) scaleY(1.4); opacity: 0; transition-property: transform, opacity; }

[data-aos="animation-scale-y"].aos-animate { transform: translateY(0) scaleY(1); opacity: 1; }

[data-aos="animation-translate-y"] { transform: translateY(40px); opacity: 0; transition-property: transform, opacity; }

[data-aos="animation-translate-y"].aos-animate { transform: translateY(0); opacity: 1; }

[data-aos="transform"] { transform: rotateX(90deg) skewY(-5deg); opacity: 0; transition-property: transform, opacity; }

[data-aos="transform"].aos-animate { transform: translate(0) rotateX(0deg) skewY(0deg); opacity: 1; }

.linknew { cursor: pointer; font-weight: 600; font-size: 20px; color: #ffffff; }

.linknew_dark { color: #F50245; }

@media only screen and (max-width: 767px) { .linknew_dark { display: none; } }
